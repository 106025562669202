import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import TradeWriteMultyPopup from "@/pages/comp/supply/simple_trade/popup/write_multy";
import SearchArea from "./search/index";

const TradeListArea = forwardRef((props:any, ref) => {
  const myProps={
    list:(inData:any)=>{},
    ...props,
  };
  const xColumnArr=getXcolumnJson();
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [stu_seq_arr,set_stu_seq_arr] = useState([]);
  const [stu_name_arr,set_stu_name_arr] = useState([]);
  let default_trade_tot_info={
    "in_money":0,
    "out_money":0,
    "in_debt":0,
    "out_debt":0,
    "remain_money":0
  };
  const [trade_tot_info,set_trade_tot_info] = useState(default_trade_tot_info);
  
  const listAggridRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_date_type":"a_date",
      "s_start_date":DateFunc.get_date_format(new Date(),"Y-01-01"),//DateFunc.get_date_format(new Date(),"Y-01-01")
      "s_end_date":"",
      "s_cust_par_id":"user",
      "s_cust_par_seq":[],
      "s_addon_cust_name":"1",
      "s_money_sum":"1",

      "trade_tot_info":default_trade_tot_info,
    }
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "stu_seq_arr":[],
      "stu_name_arr":[],
      "trade_tot_info":default_trade_tot_info,
      ...inOptObj
    };
    set_stu_seq_arr(optObj["stu_seq_arr"]);
    set_stu_name_arr(optObj["stu_name_arr"]);
    set_trade_tot_info(optObj["trade_tot_info"]);
    list({
      "now_page":"1",
      "s_cust_par_seq":optObj["stu_seq_arr"],
      "trade_tot_info":optObj["trade_tot_info"]
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(() => {
    
  }, []);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write_multy",//find
    cust_par_seq:"",
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_list_opt={...pageData.listOpt};
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/trade_simple/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        response.data["data"]["info_arr"]=[...response.data["data"]["info_arr"].reverse()];
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        response.data["data"]["count_info"]["row_view_debt_in"]=strFunc.comma(response.data["data"]["count_info"]["row_view_debt_in"]);
        response.data["data"]["count_info"]["row_view_money_in"]=strFunc.comma(response.data["data"]["count_info"]["row_view_money_in"]);
        response.data["data"]["count_info"]["row_view_remain_total"]=strFunc.comma(response.data["data"]["count_info"]["row_view_remain_total"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        listAggridRef.current.setPinnedBottomRowData([{
          ...response.data["data"]["count_info"],
          "a_date":"합계"
        },
        {
          "a_date":"총합",
          "row_view_debt_in":strFunc.comma(tmp_list_opt.trade_tot_info["in_debt"]),
          "row_view_money_in":strFunc.comma(tmp_list_opt.trade_tot_info["in_money"]),
          "row_view_remain_total":strFunc.comma(tmp_list_opt.trade_tot_info["remain_money"]),
        }]);

        //마지막 포커스
        setTimeout(()=>{
          let row_nodes=listAggridRef.current.getNodeRows();
          listAggridRef.current.setFocusedCell(row_nodes.length-1,"chk_box");
        },100);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/trade_simple/delete',{ 
      data_arr:selected_row
    },get_axios_data()).then((response) => {
      if(response.data["result"]==="true"){
        list({});
        myProps.list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openWriteMultyPopup=()=>{
    if(stu_seq_arr.length!=1){
      alert("학생 한명만 선택해주세요.");
      return false;
    }
    setPopupData({
      ...popupData,
      "cust_par_seq":stu_seq_arr[0],
      "title":"등록",
      "sort":"write_multy",
      "isOpen":true,
    });
  };

  const point_init_tot = ()=>{
    let form_json_data={
      "s_year":DateFunc.get_date_format(new Date(),"Y")
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/simple_init/write_data_tot',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const migrate_init_from_pre_year_by_ajax=()=>{
    if(!confirm("전년도 초기데이터를 가져오시겠습니까?")){
      return false;
    }
    let now_year=DateFunc.get_date_format(new Date(),"Y");
    let pre_year=parseInt(now_year)-1;
    let form_json_data={
      'from_trade_year':pre_year,
      'to_trade_year':now_year,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/trade_simple/migrate',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;

  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_money_in"){
      if(params.data.a_inout=="in"&&params.data.a_money_type=="money"){
        render_str=params.data.a_money;
        render_str=strFunc.comma(render_str);
      }
    }else if(key=="row_view_debt_in"&&params.data.a_money_type=="debt"){
      if(params.data.a_inout=="in"){
        render_str=params.data.a_money;
        render_str=strFunc.comma(render_str);
      }
    }
    return render_str;
  }, []);

  return (
    <div>
      <div className="relative" style={{minHeight:55}}>
        <SearchArea
          listOpt={pageData.listOpt}
          list={list}
        ></SearchArea>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={()=>{openWriteMultyPopup();}}>추가</button>
          <button className="btn btn-red" onClick={goDelete}>삭제</button>
          {/* <button className="btn btn-dark" onClick={()=>{point_init_tot();}} >초기화테스트</button>
          <button className="btn btn-dark" onClick={()=>{migrate_init_from_pre_year_by_ajax();}}>초기데이터이관테스트</button>
           */}
        </div>
        <div>
          거래처: {stu_name_arr.length>0&&stu_name_arr[0]}
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:true,
          floatingFilter:false,
          // domLayout:'autoHeight',
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot} 
        onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
      <div>
        ※ 미수금 = 청구-입금
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
        {
          popupData.sort=="write_multy"&&
          <TradeWriteMultyPopup
            cust_par_seq={popupData.cust_par_seq}
            callBackData={()=>{list({});myProps.list({});}} 
            closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
          ></TradeWriteMultyPopup>
        }
      </LayerPopup>
      }
    </div>
  );
});

export default TradeListArea;