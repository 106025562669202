import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const SearchArea = forwardRef((props: any, ref) => {
  const [listOpt, setListOpt] = useState(props.listOpt);
  const [subjectArr, setsubjectArr] = useState<any>([]);
  const [subject_main_arr, set_subject_main_arr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let stu_state_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_state' });
  useEffect(() => {
    list_subject_by_ajax({});
    get_subject_main_by_ajax({});
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    if (name == "s_subject_sort") {
      get_subject_main_by_ajax({ s_sort: value });
      list_subject_by_ajax({ s_sort: value });
    } else if (name == "s_subject_main_seq") {
      list_subject_by_ajax({ s_main_seq: value });
    }

    setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    if (name == "s_search_text") {
      return false;
    }
    props.list({ [name]: value });
  };

  const goSearch = () => {
    props.list({ ...listOpt });
  };

  const get_subject_main_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      s_main_seq: listOpt.s_subject_main_seq,
      ...inData
    };
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_subject_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      ...inData
    };
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <div className="search-box relative">
        <select className="search-input_select" name="s_subject_sort" value={listOpt.s_subject_sort}
          onChange={handleInputChange}
          style={{ width: 70 }} >
          <option value="">과목</option>
          {
            subject_option_arr.map((item: any, idx: number) => {
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
        <select className="search-input_select ml-1" name="s_subject_main_seq" value={listOpt.s_subject_main_seq}
          onChange={handleInputChange}
          style={{ width: 70 }}>
          <option value="">대분류</option>
          {
            subject_main_arr.map((item: any, idx: number) => {
              return (
                <option value={item.a_seq} key={idx}>{item.a_title}</option>
              );
            })
          }
        </select>
        <select className="search-input_select ml-1" name="s_subject_seq" value={listOpt.s_subject_seq}
          onChange={handleInputChange}
          style={{ width: 70 }}>
          <option value="">상세</option>
          {
            subjectArr.map((item: any, idx: number) => {
              return (
                <option value={item.a_seq} key={idx}>{item.a_title}</option>
              );
            })
          }
        </select>

        {/* <span className="ml-1">
          <label>
            <input type="checkbox" name="s_is_progress" value="1"
              checked={listOpt.s_is_progress == "1"}
              onChange={handleInputChange} />
            <span className="ml-1">진도</span>
          </label>
        </span> */}
        {/* <span className="ml-1">
          <label>
            <input type="checkbox" name="s_is_mapping" value="1"
              checked={listOpt.s_is_mapping == "1"}
              onChange={handleInputChange} />
            <span className="ml-1">교과맵핑</span>
          </label>
        </span> */}
        <input type="text" className="search-input ml-1" name="s_title_like" value={listOpt.s_title_like}
          onChange={handleInputChange} style={{ width: 70 }}
          placeholder="제목" autoComplete="off" />
        <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      </div>
      <div className="search-box relative">
        <select className="search-input_select" name="s_addon_map_s_stu_state" value={listOpt.s_addon_map_s_stu_state} onChange={handleInputChange} >
          <option value="">전체</option>
          <option value="now,ready">대기,재원</option>
          {
            stu_state_option_arr.map((item: any, idx: number) => {
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
      </div>
    </div>
  );
});

export default SearchArea;