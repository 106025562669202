import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import StudyTitleListArea from "@/pages/comp/plan/study/study/common/find/area/list";
import strFunc from '@/lib/lyg/string';
import LoadingComponent from "@/pcomponents/common/content/loading";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudyTitlePopup from "@/pages/comp/plan/study/study/popup/find_study_title";
import SearchArea from "./search";

const ManageStudyArea = forwardRef((props: any, ref) => {
  let myProps = {
    stu_seq: "",
    listOpt: {},
    search_user_list: (inData: any) => { },
    ...props
  };

  const [listOpt, setListOpt] = useState<any>({
    "s_title_seq":"",
    "order_id": "a_title",
    "s_subject_seq": "",
    "s_subject_sort": "",
    "s_title_like": "",
    "s_is_progress":"",
    "s_addon_section": "1",
    "s_addon_study": "1",
    "s_addon_use_stu": "",
    "s_addon_use": "",
    "s_addon_use_s_stu_seq": "",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    ...myProps.listOpt
  });
  const [select_stu_seq, set_select_stu_seq] = useState(myProps["stu_seq"]);
  const [study_title_arr, set_study_title_arr] = useState([]);
  const [select_study_title_seq_arr, set_select_study_title_seq_arr] = useState<any>([]);
  const [study_use_arr, set_study_use_arr] = useState([]);
  const [show_detail_title_seq_arr, set_show_detail_title_seq_arr] = useState<any>([]);
  const [student_info, set_student_info] = useState({});
  const [is_show_only_use, set_is_show_only_use] = useState(true);
  const [refresh, set_refresh] = useState(false);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장중..",
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_study_title",//add_attend,write_td

    row_num: "",
    key_str: "",
    par_info: null,
    listOpt:{},

    title: "출석",
    width: "800px",
    height: "100%",
    y: 0,
  });

  useEffect(() => {
    refresh_data();
    get_student_by_ajax({});
  }, []);

  const refresh_data = () => {
    get_study_use_list_by_ajax({});
  };

  const setInitData = (inData: any) => {
    let opt_obj = {
      stu_seq: "",
      ...inData,
    };
    set_select_stu_seq(opt_obj["stu_seq"]);

    if (strFunc.is_empty(opt_obj["stu_seq"])) {
      //set_study_title_arr([]);
      set_study_use_arr([]);
      set_student_info({});
    } else {
      get_student_by_ajax({
        stu_seq: opt_obj["stu_seq"]
      });
      get_study_use_list_by_ajax({
        s_stu_seq: opt_obj["stu_seq"]
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const list = (inOptObj: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          set_study_title_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_study_use_list_by_ajax = (inData: any) => {
    let form_json_data = {
      "s_stu_seq": select_stu_seq,
      "s_addon_detail": "1",
      "order_id": "a_is_main DESC, a_is_sub DESC, a_stu_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inData,
    };
    if (strFunc.is_empty(form_json_data["s_stu_seq"])) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_use_arr(response.data["data"]["info_arr"]);
          let tmp_show_detail_title_seq_arr = [];
          let tmp_title_seq_arr:any = [];
          if (response.data["data"]["info_arr"].length > 0) {
            for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
              tmp_title_seq_arr.push(response.data["data"]["info_arr"][i]["a_study_title_seq"]);
              if (response.data["data"]["info_arr"][i]["a_is_open"] == "1") {
                tmp_show_detail_title_seq_arr.push(response.data["data"]["info_arr"][i]["a_study_title_seq"]);
              }
            }
          }
          
          set_show_detail_title_seq_arr(tmp_show_detail_title_seq_arr);
          if(is_show_only_use){
            if(tmp_title_seq_arr.length>0){
              list({s_title_seq:tmp_title_seq_arr});
            }
          }else{
            list({s_title_seq:[]});
          }
        } else {

        }
      });
  };

  const go_write_study_use_by_ajax = (inData: any) => {
    let opt_obj = {
      is_confirm: true,
      is_refresh: true,
      study_use_arr: study_use_arr,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["study_use_arr"])) {
      if (opt_obj["is_confirm"]) {
        alert("저장할 데이터가 없습니다.");
      }
      return false;
    }
    if (strFunc.is_empty(select_stu_seq)) {
      return false;
    }
    if (opt_obj["is_confirm"]) {
      // if (!confirm("저장 하시겠습니까?")) {
      //   return false;
      // }
    }
    let form_json_data = {
      "data_arr": opt_obj["study_use_arr"],
      "is_write_detail": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display: false,
              text: "저장중..",
            });
          }, 1000);
          if (opt_obj["is_refresh"]) {
            refresh_data();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_student_by_ajax = (inData: any) => {
    let opt_obj = {
      stu_seq: select_stu_seq,
      ...inData,
    };
    let form_json_data = {
      "s_pri_arr": [opt_obj.stu_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(opt_obj.stu_seq)) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_student_info(response.data["data"]["info_arr"][0]);
          }
          if (response.data["data"]["info_arr"].length == 0) {

          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_find_study_title_popup = (inData: any) => {
    let opt_obj = {
      ...inData
    };
    let tmp_stu_info:any=student_info;
    if(strFunc.is_empty(tmp_stu_info["a_seq"])){
      alert("학생정보가 없습니다.");
      return false;
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "find_study_title",
      title: "학습내용 찾기",
      listOpt:{
        s_addon_use_s_stu_seq:tmp_stu_info["a_seq"],
      },
      has_header: true,
      width: "700px",
      height: "100%",
      ...opt_obj
    });
  };

  const callback_study_title = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: "",
      key: "",
      par_info: null,
      ...inData
    };
    if (opt_obj["info_arr"].length == 0) {
      return false;
    }
    let tmp_stu_info:any=student_info;
    if(strFunc.is_empty(tmp_stu_info["a_seq"])){
      alert("학생정보가 없습니다.");
      return false;
    }
    let add_use_data_arr:any=[];
    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let tmp_study_title_info=opt_obj["info_arr"][i];
      add_use_data_arr.push({
        a_stu_seq:tmp_stu_info["a_seq"],
        a_study_title_seq:tmp_study_title_info["a_seq"],
        a_stu_name:tmp_stu_info["a_user_name"],
      });
    }
    save_study_title_use_by_ajax({
      add_use_data_arr:add_use_data_arr,
    });
  };

  const save_study_title_use_by_ajax=(inData:any)=>{
    let opt_obj={
      add_use_data_arr:[],
      ...inData
    };
    let add_use_data_arr:any=[];
    for(let i=0;i<opt_obj.add_use_data_arr.length;i++){
      let row_use_data=opt_obj.add_use_data_arr[i];
      if(!strFunc.is_empty(row_use_data["a_stu_seq"])
        &&!strFunc.is_empty(row_use_data["a_study_title_seq"])){
          add_use_data_arr.push(row_use_data);
      }
    }
    if(add_use_data_arr.length==0){
      return false;
    }
    let tmp_form_json={
      "data_arr":add_use_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/write', tmp_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          refresh_data();
          myProps.search_user_list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        is_show_only_use={is_show_only_use}
        set_is_show_only_use={set_is_show_only_use}
        open_find_study_title_popup={open_find_study_title_popup}
      ></SearchArea>
      <div className="mt-1">
        <StudyTitleListArea
          study_title_arr={study_title_arr}
          study_use_arr={study_use_arr}
          set_study_use_arr={set_study_use_arr}
          show_detail_title_seq_arr={show_detail_title_seq_arr}
          set_show_detail_title_seq_arr={set_show_detail_title_seq_arr}
          select_study_title_seq_arr={select_study_title_seq_arr}
          set_select_study_title_seq_arr={set_select_study_title_seq_arr}
          is_show_check_title={false}
          student_info={student_info}
          select_daily_study_title={(inData: any) => { }}
          go_write_study_use_by_ajax={go_write_study_use_by_ajax}
          refresh_data={refresh_data}
          is_show_only_use={is_show_only_use}
          set_refresh={() => { set_refresh(!refresh); }}
        ></StudyTitleListArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={popupData.y} has_header={popupData.has_header}  >
          {popupData.sort === "find_study_title" &&
            <FindStudyTitlePopup
              listOpt={popupData.listOpt}
              is_remove_select_title_btn={false}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={callback_study_title}
            ></FindStudyTitlePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
});
export default ManageStudyArea;