import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import MoveButtonArea from "@/pages/comp/plan/coolen/common/move_btn_area";

function CoolenMovePage(){
  return (
    <CompLayout isLeftArea={false} isConTitle={false}>
      <MoveButtonArea></MoveButtonArea>
    </CompLayout>
  );
}

export default CoolenMovePage;