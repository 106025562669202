import strFunc from "@/lib/lyg/string";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import { useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';

function MapStuArea(props: any) {
  let myProps = {
    study_title_info: {},
    map_arr: [],
    map_det_arr: [],
    get_study_title_info_by_ajax: (inData: any) => { },
    ...props
  };
  let study_title_info = myProps.study_title_info;
  let map_arr = myProps.map_arr;
  let map_det_arr = myProps.map_det_arr;

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_stu",//find_stu

    listOpt: {
      s_except_user_seq:"",
    },
    row_num: 0,
    key_str: "",

    has_header: true,
    title: "학생찾기",
    width: "800px",
    height: "100%",
  });

  const open_find_stu_popup = (inData: any) => {
    let s_except_user_seq_arr=[];
    for(let i=0;i<map_arr.length;i++){
      s_except_user_seq_arr.push(map_arr[i].a_stu_seq);
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "학생찾기",
      "sort": "find_stu",
      listOpt: {
        s_except_user_seq:s_except_user_seq_arr,
      },

      width: "800px",
      height: "99%",
    });
  };

  const call_back_find_stu_popup=(inData:any)=>{
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key_str: "",
      ...inData
    };

    let info_arr = opt_obj.info_arr;
    if(info_arr.length==0){
      alert("선택된 학생이 없습니다.");
    }
    let add_info_arr=[];
    for(let i=0;i<info_arr.length;i++){
      let is_able_add=true;
      for(let j=0;j<map_arr.length;j++){
        if(info_arr[i].a_seq==map_arr[j].a_stu_seq){
          is_able_add=false;
        }
      }
      if(is_able_add){
        add_info_arr.push(info_arr[i]);
      }
    }

    if(add_info_arr.length==0){
      alert("추가할 학생이 없습니다.");
      return false;
    }

    let add_study_map_arr=[];
    let now_ymdhis=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
    for(let i=0;i<add_info_arr.length;i++){
      let tmp_stu_info=add_info_arr[i];
      let a_total_cnt=0;
      if(study_title_info.study_arr){
        a_total_cnt=study_title_info.study_arr.length;
      }
      let add_study_map_row={
        a_study_title_seq:study_title_info.a_seq,
        a_stu_seq:tmp_stu_info.a_seq,
        a_stu_name:tmp_stu_info.a_user_name,
        a_total_cnt:a_total_cnt,
        a_start_date:now_ymdhis,
        a_update_date:now_ymdhis,
      };
      add_study_map_arr.push(add_study_map_row);
    }
    if(add_study_map_arr.length==0){
      return false;
    }

    if(!confirm("선택된 학생을 추가하시겠습니까?")){
      return false;
    }

    let form_json_data = {
      "data_arr": add_study_map_arr,
      "is_default_val":"1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("등록 되었습니다.");
          myProps.get_study_title_info_by_ajax({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_delete_study_map = (inData: any) => {
    let opt_obj={
      study_map_arr:[],
      ...inData
    };
    if(opt_obj.study_map_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }

    if(!confirm("선택된 학생의 교과맵핑을 삭제 하시겠습니까?")){
      return false;
    }

    let form_json_data = {
      "data_arr": opt_obj.study_map_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.get_study_title_info_by_ajax({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_map_stu_tr_tags = () => {
    let map_stu_tr_tags: any = map_arr.map((item: any, idx: number) => {

      let map_detail_cnt=0;
      for(let i=0;i<map_det_arr.length;i++){
        if(map_det_arr[i].a_stu_seq==item.a_stu_seq
          && map_det_arr[i].a_study_title_seq==item.a_study_title_seq
        ){
          map_detail_cnt++;
        }
      }

      return (
        <tr key={idx}>
          <td className="text-center">{idx + 1}</td>
          <td className="text-center">{item.a_stu_name}</td>
          <td className="text-center">
            {item.a_success_cnt}/{item.a_total_cnt}
            <span className="ml-1">({map_detail_cnt})</span>
          </td>
          <td className="text-center">{item.a_start_date}</td>
          <td className="text-center">{item.a_update_date}</td>
          <td className="text-center">
            {item.a_is_success == "1" &&
              <span>
                {item.a_success_date}
              </span>
            }
          </td>
          <td className="text-center">
            <button className="btn btn-red" 
              onClick={()=>{
                go_delete_study_map({
                  study_map_arr:[item]
                });
              }} >삭제</button>
          </td>
        </tr>
      );
    });

    if (strFunc.is_empty(map_stu_tr_tags)) {
      map_stu_tr_tags = (
        <tr>
          <td colSpan={99}>
            <div className="text-center">
              <span>등록된 학생이 없습니다.</span>
            </div>
          </td>
        </tr>
      );
    }

    return map_stu_tr_tags;
  };

  return (
    <div>
      <div className="text-right">
        {!strFunc.is_empty(study_title_info.a_seq) &&
          <button className="btn btn-dark"
            onClick={() => { open_find_stu_popup({}); }} >학생초대</button>
        }
      </div>
      <div className="list-table-div mt-1">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="20%" />
            <col width="10%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>성공</th>
              <th>시작일</th>
              <th>수정일</th>
              <th>성공일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {get_map_stu_tr_tags()}
          </tbody>
        </table>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "find_stu" &&
            <FindMultyStudentPopup
              listOpt={popupData.listOpt}
              row_num={popupData.row_num}
              key_str={popupData.key_str}
              is_multi_select_by_click={true}
              callBackData={call_back_find_stu_popup}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default MapStuArea;