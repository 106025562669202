import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"study_title",
    list_id:"study_title_list",
    x_column_list_arr:[
      // {"key":"a_subject_title","name":"과목","width":"90","is_show":"1"},
      {"key":"a_title","name":"제목","width":"140","is_show":"1"},
      {"key":"row_view_section","name":"단원","width":"70","is_show":"1"},
      {"key":"row_view_map_stu","name":"학생","width":"70","is_show":"1"},
      // {"key":"row_view_order","name":"순번","width":"100","is_show":"1"},
      // {"key":"row_view_anser","name":"답안지주소","width":"100","is_show":"1"},
      // {"key":"row_view_stu_cnt","name":"이용중","width":"100","is_show":"1"},
      // {"key":"a_open_group","name":"그룹공개","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_title',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_category_seq":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;