import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, forwardRef,useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';

const WriteInputFrame = forwardRef((props:any, ref) => {
  let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    "isUpdate":false,
    "init_data":{
      "a_par_id":"",
      "a_par_seq":"",
      "a_sort1":"",
      "a_sort2":"",
      "a_sort3":"",
    },
    "rowData":{},
    "callback":()=>{},
    ...props
  };
  const user = useSelector((state:any) => state.user);
  let select_mcomp_data=myProps.select_mcomp_data;
  const fileInput = useRef<any>(null);
  const writeForm = useRef<HTMLFormElement>(null);
  let init_row_data:any={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_comp_seq":user.comp_seq,
    "a_par_id":myProps.init_data.a_par_id,
    "a_par_seq":myProps.init_data.a_par_seq,
    "a_sort1":myProps.init_data.a_sort1,
    "a_sort2":myProps.init_data.a_sort2,
    "a_sort3":myProps.init_data.a_sort3,
    "a_writer":user.user_name,
    "a_create_seq":user.user_seq,
  };
  
  if(!strFunc.is_empty(select_mcomp_data.api_key)
    &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
      init_row_data["a_comp_seq"]=select_mcomp_data.mcomp_seq;
      init_row_data["a_writer"]=select_mcomp_data.api_user_seq;
  }
  const [rowData,setRowData]=useState(init_row_data);

  useEffect(()=>{
    if(myProps.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(myProps.rowData[key]!=undefined){
          changeRowData[key]=myProps.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=(inOptObj:any) => {
    if(inOptObj===undefined){inOptObj={};}
    let optObj:any={
      //"a_par_seq":""
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let tmpRowData={
      ...rowData,
      ...optObj
    };
    setRowData(tmpRowData);
    if(tmpRowData.a_par_seq==""||tmpRowData.a_par_id==""){
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if(fileInput.current==null){
      return false;
    }
    if(writeForm.current==null){
      return false;
    }

    if(fileInput.current.value==""){
      alert("파일 선택이 필요합니다.");
      return false;
    }

    var form_json_data = new FormData();
    let file_row_data_arr: any = [];
    for (let i = 0; i < fileInput.current.files.length; i++) {
      let file_info = fileInput.current.files[i];
      form_json_data.append('file_arr[]', file_info);
      file_row_data_arr.push({ ...tmpRowData });
    }
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization",localStorage.intoyou_token);
    
    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.callback(response.data["data"]);
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  const getFileInput=()=>{
    return fileInput;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    goWrite,getFileInput
  }))

  return (
    <div>
      <form method="post" ref={writeForm} >
        <div className="p-2 mt-1" style={{border:"1px solid #ccc"}}>
          <h5>첨부파일</h5>
          <div className="p-1">
            <input type="file" className="row-input" name="input_file[]" ref={fileInput} multiple 
              style={{width:200}}/>
            <input type="text" className="row-input ml-2 p-1" name="a_sort3" value={rowData.a_sort3||""} 
              onChange={handleInputChange} placeholder="파일설명" style={{width:100,height:25}} />
          </div>
        </div>
      </form>
    </div>
  );
});

export default WriteInputFrame;
