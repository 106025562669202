import CompLayout from "@/pcomponents/comp/layout/layout";
import MoveButtonArea from "@/pages/comp/plan/coolen/common/move_btn_area";

function CoolenMovePage() {

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <MoveButtonArea></MoveButtonArea>
      </div>
    </CompLayout>
  );
};
export default CoolenMovePage;