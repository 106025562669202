import WriteDetailArea from "@/pages/comp/plan/study/study_map/common/map_detail/write";

function MapDetailWritePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback_refresh_data:()=>{},
    stu_info:{},
    map_detail_info:{},
    section_study_name:{
      section_name:"",
      unit_name:"",
    },
    is_view_top_info:false,
    ...props
  };

  return (
    <div>
      <WriteDetailArea
        go_back_page={myProps.closePopup}
        callback_refresh_data={myProps.callback_refresh_data}
        stu_info={myProps.stu_info}
        map_detail_info={myProps.map_detail_info}
        section_study_name={myProps.section_study_name}
        is_view_top_info={myProps.is_view_top_info}
      ></WriteDetailArea>
    </div>
  );
};
export default MapDetailWritePopup;