import SearchSubjectArea from "./search_subject";

function SearchArea(props:any){
  let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    setListOpt:(inData:any)=>{},
    ...props
  };
  let listOpt=myProps.listOpt;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_listOpt:any={
      [name]: value
    };

    myProps.setListOpt({
      ...listOpt,
      ...change_listOpt
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list(change_listOpt);
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  return (
    <div>
      <div className="search-box relative">
        <SearchSubjectArea
          listOpt={listOpt}
          list={myProps.list}
          setListOpt={myProps.setListOpt}
        ></SearchSubjectArea>
        <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      </div>
    </div>
  );
};

export default SearchArea;