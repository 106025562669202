import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import MapDetailWritePopup from "@/pages/comp/plan/study/study_map/title_map/popup/detail_write";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./search";

function StudyMapHistoryArea(props: any) {
  let myProps = {
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    's_date_type':'a_date',
    's_start_date':'',
    's_end_date':'',
    s_addon_study_map: "1",
    s_addon_study_title: "1",
    s_addon_file: "1",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "write_map_detail",//write_map_detail
    stu_info: {},
    map_detail_info: null,
    section_study_name: {
      section_name: "",
      unit_name: "",
    },
    has_header: true,
    title: "상세보기",
    width: "800px",
    height: "100%",
  });

  const list = (inOptObj: any) => {
    let change_list_opt: any = {
      ...listOpt,
      ...inOptObj,
    };
    change_list_opt["sc"] = listAggridRef.current.getListSc();
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map_detail/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (listAggridRef.current) {
            listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          }
        } else {

        }
      });
  };

  const open_map_detail_write_popup = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let stu_info = null;
    let map_detail_info = selected_row[0];
    let section_study_name = {
      section_name: map_detail_info.a_section_name,
      unit_name: map_detail_info.a_unit_name,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "상세보기",
      "sort": "write_map_detail",
      stu_info: stu_info,
      map_detail_info: map_detail_info,
      section_study_name: section_study_name,
      width: "800px",
      height: "99%",
    });
  };

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_stu_name") {
      if (params.data.map_info) {
        render_str = params.data.map_info["a_stu_name"];
      }
    } else if (key == "row_view_file_cnt") {
      if (params.data.file_arr) {
        render_str = params.data.file_arr.length;
      }
    }else if(key=="row_view_open_btn"){
      render_str = (
        <button className="btn btn-dark"
          onClick={() => { 
            setTimeout(()=>{open_map_detail_write_popup();},100);
           }}>
          보기
        </button>
      );
    }

    return render_str;
  };

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        xColumnArr={xColumnArr}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: false,
          onGridReady: () => {
            list({});
          },
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "write_map_detail" &&
            <MapDetailWritePopup
              stu_info={popupData.stu_info}
              map_detail_info={popupData.map_detail_info}
              section_study_name={popupData.section_study_name}
              is_view_top_info={true}
              callback_refresh_data={() => {
                list({});
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></MapDetailWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default StudyMapHistoryArea;