import SearchSubjectArea from "@/pages/comp/plan/study/study/list/area/search_area/search_subject";

function SearchArea(props:any){
   let myProps={
    list_opt:{},
    setListOpt:(inData:any)=>{},
    list:(inData:any)=>{},
    ...props
  };

  return (
  <div>
    <SearchSubjectArea
      listOpt={myProps.list_opt}
      setListOpt={myProps.setListOpt}
      list={myProps.list}
    ></SearchSubjectArea>
    <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({});}}>검색</button>
  </div>
  );
};
export default SearchArea;