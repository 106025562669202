import { useState, useRef, useEffect,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import strFunc from '@/lib/lyg/string';

const WriteListArea = forwardRef((props:any, ref) => {
  let myProps={
    info_arr:[],
    write_month_prescribe_by_ajax:()=>{},
    closePopup:()=>{},
    ...props
  };

  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [info_arr,set_info_arr]=useState([]);

  const setInitData=(inData:any)=>{
    let optObj={
      "info_arr":[],
      ...inData
    };
    for(let i=0;i<optObj.info_arr.length;i++){
      optObj.info_arr[i]["row_num"]=i;
    }
    set_info_arr(optObj.info_arr);
    ListComponentRef.current.setInfoArr(optObj.info_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_info_arr
  }));

  const get_info_arr=()=>{
    let all_row=ListComponentRef.current.getRows();
    set_info_arr(all_row);
    return all_row;
  };

  const removeSelect=()=>{
    let all_row=ListComponentRef.current.getRows();
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      return false;
    }
    let remain_row_arr=[];
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_is_exist=false;
      for(let j=0;j<selected_row.length;j++){
        let tmp_sel_row=selected_row[j];
        if(tmp_row.row_num==tmp_sel_row.row_num){
          tmp_is_exist=true;
        }
      }
      if(tmp_is_exist==false){
        remain_row_arr.push(tmp_row);
      }
    }
    ListComponentRef.current.setInfoArr(remain_row_arr);
  };

  const customCellRenderer=(params:any)=>{
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    let key=params.key;
    let val_str=params.value;

    return val_str;
  };
  const customGridCellRenderer=(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "input_tag":input_tag,
        "onChangeInput":onChangeInput,
      }
      */
     let key=params.key;
      return params.input_tag;
  };

  const onChangeInput=(inData:any)=>{
    let opt_obj={
      "rowData":{},
      "row_num":0,
      "key":"",
      "value":"",
      "pre_value":"",
      ...inData,
    };

    return inData;
  };

  return (
    <div>
      <div className="my-1" style={{position:"relative",lineHeight:"30px"}}>
        생성 내용
        <button className="btn-m btn-red" onClick={()=>{removeSelect();}} style={{position:"absolute",right:0,top:2}}>제외</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        infoArr={info_arr}
        gridOpt={{
          isGrid:true,
          is_add_idx_num:false,
          is_add_checkbox:true,
          width:"100%",
          height:"32vh",
          is_auto_max_width:false,
          td_align:"left",//center,right
          table_align:"left",//center,right
          onGridReady:()=>{},
          rowOnClick:(rowData:any,idx:number,e:any)=>{

          },
          customCellRenderer:customCellRenderer,
          customGridCellRenderer:customGridCellRenderer,
          onChangeInput:onChangeInput,
          class_name_json:{
            table_div:"basic_list_div",
            td_div:"row-col-div",
            input_check:"chk_row",
            row_input:"row-input",
            data_row_tr:"data_row_tr",
            active_row:"active",
            empty_con_div:"list_empty_content_div",
          },
        }}
      ></ListComponent>
      <div className="text-center mt-5">
        <button className="btn-m btn-yellowish3" 
          onClick={()=>{myProps.write_month_prescribe_by_ajax();}} >처방스케줄 적용하기</button>
        <button className="btn-m btn-gray ml-2" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
});
export default WriteListArea;