import StuMapWriteArea from "@/pages/comp/plan/study/study_map/common/stu_map/right/stu_map_write";

function StuMapWritePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback_refresh_data: () => { },
    study_map_pri_val: "",
    ...props
  };

  return (
    <div>
      <StuMapWriteArea
        study_map_pri_val={myProps.study_map_pri_val}
        callback_refresh_data={myProps.callback_refresh_data}
        back_func={myProps.closePopup}
        back_text="닫기"
      ></StuMapWriteArea>
    </div>
  );
};
export default StuMapWritePopup;