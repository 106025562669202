function MapListArea(props:any){
   let myProps={
    Style:{},
    study_map_arr:[],
    open_stu_map_write_popup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let study_map_arr=myProps.study_map_arr;
  
  const get_study_map_list_tags=()=>{
    let tag_arr:any=study_map_arr.map((item:any,idx:number)=>{
      let detail_cnt=0;
      if(item.detail_arr){
        detail_cnt=item.detail_arr.length;
      }
      return (
        <div key={idx} className={Style.map_list_row}
          onClick={()=>{
            myProps.open_stu_map_write_popup({
              study_map_info:item
            });
          }}>
          {idx+1}. {item.study_title}
          <span className="ml-2" style={{fontSize:"15px"}}>({item.a_success_cnt}/{item.a_total_cnt})</span>
          <span className="ml-2" style={{fontSize:"15px",color:"gray"}}>
            작성: {detail_cnt}
          </span>
        </div>
      );
    });
    return tag_arr;
  };
  
  return (
  <div>
    {get_study_map_list_tags()}
  </div>
  );
};
export default MapListArea;