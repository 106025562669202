import strFunc from "@/lib/lyg/string";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function WriteArea(props: any) {
  let myProps = {
    daily_report: {},
    set_daily_report: (inData: any) => { },
    ...props
  };
  let daily_report = myProps.daily_report;
  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let tmp_daily_report = { ...daily_report };

    let num_key_arr = [
      "a_todo_success_amount",
      "a_todo_amount",
      "a_todo_sub_success_amount",
      "a_todo_sub_amount",
      "a_grade_score"
    ];
    if (strFunc.str_in_array(name, num_key_arr) != -1) {
      value = strFunc.uncomma(value);
    }

    tmp_daily_report[name] = value;
    myProps.set_daily_report(tmp_daily_report);
  };

  return (
    <div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>본교재(대)</th>
              <th>본교재(소)</th>
              <th>목표/달성</th>
              <th>부교재(대)</th>
              <th>부교재(소)</th>
              <th>목표/달성</th>
              <th>점수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextAreaComponent
                  name={"a_textbook_study"}
                  value={daily_report["a_textbook_study"]}
                  onChange={on_change_input}
                  placeholder={"학습내용"}
                ></TextAreaComponent>
              </td>
              <td>
                <TextAreaComponent
                  name={"a_textbook_study_det"}
                  value={daily_report["a_textbook_study_det"]}
                  onChange={on_change_input}
                  placeholder={"학습내용"}
                ></TextAreaComponent>
              </td>
              <td className="text-center">
                <input type="text" name="a_todo_amount"
                  value={daily_report["a_todo_amount"]}
                  onChange={on_change_input}
                  style={{ width: 30, textAlign: "center" }} />
                /
                <input type="text" name="a_todo_success_amount"
                  value={daily_report["a_todo_success_amount"]}
                  onChange={on_change_input}
                  style={{ width: 30, textAlign: "center" }} />
              </td>
              <td>
                <TextAreaComponent
                  name={"a_subtextbook_study"}
                  value={daily_report["a_subtextbook_study"]}
                  onChange={on_change_input}
                  placeholder={"학습내용"}
                ></TextAreaComponent>
              </td>
              <td>
                <TextAreaComponent
                  name={"a_subtextbook_study_det"}
                  value={daily_report["a_subtextbook_study_det"]}
                  onChange={on_change_input}
                  placeholder={"학습내용"}
                ></TextAreaComponent>
              </td>
              <td className="text-center">
                <input type="text" name="a_todo_sub_amount"
                  value={daily_report["a_todo_sub_amount"]}
                  onChange={on_change_input}
                  style={{ width: 30, textAlign: "center" }} />
                /
                <input type="text" name="a_todo_sub_success_amount"
                  value={daily_report["a_todo_sub_success_amount"]}
                  onChange={on_change_input}
                  style={{ width: 30, textAlign: "center" }} />
              </td>
              <td className="text-center">
                <input type="text" name="a_grade_score"
                  value={daily_report["a_grade_score"]}
                  onChange={on_change_input}
                  style={{ width: 40, textAlign: "center" }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default WriteArea;