import CompLayout from "@/pcomponents/comp/layout/layout";
import StudyMapHistoryArea from "@/pages/comp/plan/study/study_map/common/history/list";

function StudyMapHistoryPage() {

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <StudyMapHistoryArea></StudyMapHistoryArea>
      </div>
    </CompLayout>
  );
};
export default StudyMapHistoryPage;