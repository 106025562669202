import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import { useNavigate } from 'react-router-dom';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";
import StudyTitleArea from "@/pages/comp/plan/study/study_map/common/study_title";

function TitleMapListArea() {
  const navigate = useNavigate();
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      "select_seq": "",
      "s_title_like":"",
      "s_subject_main_seq":"",
      "s_subject_seq": "",
      "s_subject_sort": "",
      "s_is_progress": "",
      "s_is_mapping": "",
      "s_addon_title_img": "1",
      "s_addon_subject_title": "1",
      "s_addon_section": "1",
      "s_addon_study": "1",
      "s_addon_map": "1",
      "s_addon_map_s_stu_state": "now",

      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const StudyTitleAreaRef = useRef<any>(null);
  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      if (listAggridRef.current) {
        inOptObj["sc"] = listAggridRef.current.getListSc();
      }
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', pageData.listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const setWriteState = () => {
    listAggridRef.current.setSelectFalseAll();
    if (StudyTitleAreaRef.current) {
      StudyTitleAreaRef.current.setInitData();
    }
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_section") {
      let section_num = 0;
      let study_num = 0;
      if (params.data.section_arr) {
        section_num = params.data.section_arr.length;
      }
      if (params.data.study_arr) {
        study_num = params.data.study_arr.length;
      }
      render_str = section_num + "/" + study_num;
    } else if (key == "row_view_stu_cnt") {
      if (params.data.use_stu_arr) {
        render_str = params.data.use_stu_arr.length;
      }
    } else if (key == "row_view_request") {
      if (params.data.request_arr) {
        render_str = params.data.request_arr.length;
      }
    }else if(key=="row_view_map_stu"){
      if(params.data.map_arr){
        render_str=params.data.map_arr.length;
      }
    }

    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 1) {
      if (StudyTitleAreaRef.current) {
        StudyTitleAreaRef.current.setInitData({
          row_data: selected_row[0],
        });
      }
    }
  };

  return (
    <div>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col width={"25%"} />
          <col width={"*"} />
        </colgroup>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <div style={{ minWidth: 380 }} >
                <SearchArea
                  listOpt={pageData.listOpt}
                  list={list}
                  setWriteState={setWriteState}
                ></SearchArea>
                <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{ ...xColumnArr }}
                  list={list}
                  gridOpt={{
                    fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num: true,
                    is_add_checkbox: false,
                    floatingFilter: true,
                    onGridReady: () => {
                      list({});
                    },
                    onCellClicked: cellClickedListener,
                    cellRenderer: cellRenderer,
                    onSelectionChanged: onSelectionChanged,
                  }}
                ></ListAggrid>
                <div className="mt-1 text-right">
                  <button className="btn btn-dark" onClick={()=>{navigate("/comp/plan/study/study/list");}} >
                    교재관리로 이동
                  </button>
                </div>
              </div>
            </td>
            <td style={{ verticalAlign: "top", paddingLeft: 10 }}>
              <div style={{ minWidth: 400 }}>
                <StudyTitleArea
                  ref={StudyTitleAreaRef}
                  list={(listOpt: any) => { list(listOpt); }}
                ></StudyTitleArea>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default TitleMapListArea;