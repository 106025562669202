import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {
      "s_lecture_seq": "",
      "s_teacher_like": "",
      "s_name_like": "",
      "s_teacher_seq": "",
      "s_room_seq": "",
      "s_day_num": [],
    },
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    teacher_arr: [],
    room_arr: [],
    time_table_display_opt:{},
    set_time_table_display_opt:(inData:any)=>{},
    ...props
  };

  const [lecture_arr, set_lecture_arr] = useState([]);
  const search_time_out_ref=useRef<any>(null);

  useEffect(() => {
    get_lecture_info_arr_by_ajax();
  }, []);

  const get_lecture_info_arr_by_ajax = () => {
    let list_form_json_data={
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const on_change_search_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    let search_list_opt = {
      [name]: value
    };
    myProps.setListOpt({ ...myProps.listOpt, ...search_list_opt });
    go_search_list({ now_page: 1, ...search_list_opt });
  };

  let select_arr_week_days = [
    { "value": "", "text": "전체" },
    { "value": "0", "text": "일" },
    { "value": "1", "text": "월" },
    { "value": "2", "text": "화" },
    { "value": "3", "text": "수" },
    { "value": "4", "text": "목" },
    { "value": "5", "text": "금" },
    { "value": "6", "text": "토" },
  ];

  return (
    <div>
      <div className="search-box">
        <input type="text" className="search-input" name="s_name_like"
          onChange={on_change_search_input} placeholder="제목" style={{ width: 80 }} />
        <input type="text" className="search-input" name="s_teacher_like"
          onChange={on_change_search_input} placeholder="선생님" style={{ width: 80 }} />
        <select className="search-input" name="s_lecture_seq"  value={myProps.listOpt.s_lecture_seq}
          onChange={on_change_search_input} style={{ width: 80 }}>
          <option value="">수업</option>
          {lecture_arr.map((item:any,idx:number)=>{
            return (
              <option key={idx} value={item.a_seq}>{item.a_name}</option>
            );
          })}
        </select>
        <select className="search-input" name="s_teacher_seq" value={myProps.listOpt.s_teacher_seq}
          onChange={on_change_search_input} style={{ width: 80 }} >
          <option value="">선생님</option>
          {myProps.teacher_arr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>
                {item.a_user_name}
                {item.a_user_nickname &&
                  "(" + item.a_user_nickname + ")"
                }
              </option>
            );
          })}
        </select>
        <select className="search-input" name="s_room_seq"
          onChange={on_change_search_input} style={{ width: 80 }} >
          <option value="">강의실</option>
          {myProps.room_arr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_name}</option>
            );
          })}
        </select>
        <SelectTab
          select_arr={select_arr_week_days}
          value={myProps.listOpt.s_day_num.length == 0 ? "" : myProps.listOpt.s_day_num[0]}
          on_select={(value: any) => {
            if (value == "") {
              myProps.list({
                s_day_num: []
              });
              return false;
            }
            myProps.list({
              s_day_num: [value]
            });
          }}
        ></SelectTab>
        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.time_table_display_opt.is_teacher_nickname} onChange={(e: any) => {
              myProps.set_time_table_display_opt({
                ...myProps.time_table_display_opt,
                is_teacher_nickname: e.target.checked
              });
            }} />
            선생님닉네임
          </label>
        </span>
        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.time_table_display_opt.is_show_th_stu_len} onChange={(e: any) => {
              myProps.set_time_table_display_opt({
                ...myProps.time_table_display_opt,
                is_show_th_stu_len: e.target.checked
              });
              let time_table_is_show_th_stu_len="";
              if(e.target.checked){
                time_table_is_show_th_stu_len="1";
              }
              strFunc.set_storage("time_table_is_show_th_stu_len", time_table_is_show_th_stu_len);
            }} />
            제목학생수
          </label>
        </span>

        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.time_table_display_opt.is_show_attend} onChange={(e: any) => {
              myProps.set_time_table_display_opt({
                ...myProps.time_table_display_opt,
                is_show_attend: e.target.checked
              });
              let time_table_is_show_attend="2";
              if(e.target.checked){
                time_table_is_show_attend="1";
              }
              strFunc.set_storage("time_table_is_show_attend", time_table_is_show_attend);
            }} />
            출석표시
          </label>
        </span>

        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.time_table_display_opt.is_show_one_lecture_stu} onChange={(e: any) => {
              myProps.set_time_table_display_opt({
                ...myProps.time_table_display_opt,
                is_show_one_lecture_stu: e.target.checked
              });
              let is_show_one_lecture_stu="2";
              if(e.target.checked){
                is_show_one_lecture_stu="1";
              }
              strFunc.set_storage("time_table_is_show_one_lecture_stu", is_show_one_lecture_stu);
            }} />
            단과표시
          </label>
        </span>

        <span className="ml-1">
          학년표시:
          <select className="search-input" name="stu_grade_sort"
            value={myProps.time_table_display_opt.stu_grade_sort}
            onChange={(e: any) => {
              myProps.set_time_table_display_opt({
                ...myProps.time_table_display_opt,
                stu_grade_sort: e.target.value
              });
              strFunc.set_storage("time_table_stu_grade_sort", e.target.value);
            }} style={{ width: 55 }}>
            <option value="" >없음</option>
            <option value="a_stu_grade2" >ABC</option>
            <option value="a_stu_grade" >학년</option>
          </select>
        </span>
        <button className="btn btn-dark" onClick={() => { myProps.list({}); }} >검색</button>
      </div>
    </div>
  );
};
export default SearchArea;