import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

function DetailListArea(props: any) {
  let myProps = {
    study_title_info: null,
    section_row_num:"",
    section_info: {},
    study_use: null,
    student_info: {},
    set_study_use: (inData: any) => { },
    select_daily_study_title:(inData: any) => { },
    on_select_detail:(inData: any) => { },
    ...props
  };
  let study_title_info = myProps["study_title_info"];
  let section_info = myProps["section_info"];
  let study_use = myProps["study_use"];
  let detail_arr: any = [];
  if (study_use && study_use.detail_arr) {
    detail_arr = study_use.detail_arr;
  }
  let student_info = myProps.student_info;
  let default_detail_info = {
    a_stu_seq: student_info["a_seq"],
    a_study_title_seq: study_title_info["a_seq"],
    a_seq: 0,
    a_reserve_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_amount: "0",
    a_success_amount: "",
    a_is_success: "",
    a_success_date: "",
    a_text_score: "0",
    a_repeat_cnt: "0",
    a_success_record: "",
    a_memo: "",
  };

  let study_arr = [];
  if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
    for (let i = 0; i < study_title_info["study_arr"].length; i++) {
      if (study_title_info["study_arr"][i]["a_section_seq"] == section_info["a_seq"]) {
        study_arr.push(study_title_info["study_arr"][i]);
      }
    }
  }

  const on_change_detail_input = (e: any, detail_info: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let detail_row_num = -1;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == detail_info["a_seq"]) {
        detail_row_num = i;
      }
    }

    let change_row_data = {
      ...detail_info
    };
    if (name == "a_amount" || name == "a_text_score"||name=="a_repeat_cnt") {
      value = strFunc.uncomma(value);
      if(name=="a_amount"){
        let a_success_amount=parseInt(strFunc.uncomma(change_row_data["a_success_amount"]));
        let a_amount=parseInt(strFunc.uncomma(value));
        if(a_success_amount!=0){
          if(a_amount>=a_success_amount){
            change_row_data["a_is_success"]="1";
            change_row_data["a_success_date"]=DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
          }
        }
      }
    }
    if (name == "a_is_success") {
      value = "";
      if (e.target.checked) {
        value = "1";
        // if(strFunc.is_empty(change_row_data["a_amount"])){
        //   change_row_data["a_amount"]=change_row_data["a_success_amount"];
        // }
        change_row_data["a_success_date"]=DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }

    change_row_data[name] = value;
    let change_detail_arr = [
      ...detail_arr
    ];
    if (detail_row_num == -1) {
      change_detail_arr.push(change_row_data);
    } else {
      change_detail_arr[detail_row_num] = change_row_data;
    }
    study_use.detail_arr = change_detail_arr;
    myProps.set_study_use({ ...study_use });
  };

  const remove_detail_of_detail_info=(detail_info:any)=>{
    let new_detail_arr=[];
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == detail_info["a_seq"]) {
        
      }else{
        new_detail_arr.push(detail_arr[i]);
      }
    }
    study_use.detail_arr = new_detail_arr;
    myProps.set_study_use({ ...study_use });
  };

  const get_use_detail_by_study_info = (study_info: any) => {
    let detail_info = null;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == study_info["a_seq"]) {
        detail_info = detail_arr[i];
      }
    }
    return detail_info;
  };

  const get_study_detail_tags = () => {
    let study_arr = [];
    if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
      for (let i = 0; i < study_title_info["study_arr"].length; i++) {
        let study_info = study_title_info["study_arr"][i];
        if (study_info["a_section_seq"] == section_info["a_seq"]) {
          study_arr.push(study_info);
        }
      }
    }

    let study_detail_tags = study_arr.map((study_info: any, idx: number) => {
      let detail_info = get_use_detail_by_study_info(study_info);
      let is_exist_detail = false;
      if (detail_info == null) {
        detail_info = {
          ...default_detail_info
        };
      } else {
        is_exist_detail = true;
      }
      detail_info["a_success_amount"] = study_info["a_amount_num"];
      detail_info["a_seq"] = study_info["a_seq"];

      //myProps["section_row_num"]+"-"+(idx + 1)+" "+study_info["a_title"];
      let select_study_title_str=study_info["a_title"];

      return (
        <tr key={idx}>
          <td className="text-center">{idx + 1}</td>
          <td style={{cursor:"pointer"}} onClick={()=>{
              myProps.select_daily_study_title({
                is_main:study_use?(study_use["a_is_main"]=="1"):true,
                study_title:select_study_title_str,
                amount:detail_info?detail_info["a_amount"]:"0",
                success_amount:detail_info["a_success_amount"],
                score:detail_info["a_text_score"]
              });
              let study_detail_str=select_study_title_str;
              myProps.on_select_detail({
                study_title_info:study_title_info,
                study_info:study_info,
                study_row_num:(idx + 1),
                section_info:section_info,
                section_row_num:myProps["section_row_num"],
                study_detail_str:study_detail_str,
              });
            }}>
            {study_info["a_title"]}
          </td>
          <td className="text-center">
            {study_info["a_amount_num"]}
            {study_info["a_amount_sort"]}
          </td>
          <td>
            <input type="text" className="row-input ml-1"
              name="a_amount"
              value={detail_info["a_amount"]}
              placeholder="수행"
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              onClick={(e:any)=>{e.target.select();}}
              autoComplete="off"
              style={{ width: "40px", textAlign: "right" }} />
          </td>
          <td>
            <input type="text" className="row-input ml-1"
              name="a_repeat_cnt"
              value={detail_info["a_repeat_cnt"]}
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              onClick={(e:any)=>{e.target.select();}}
              autoComplete="off"
              style={{ width: "40px", textAlign: "center" }} />
          </td>
          <td className="text-center">
            <input type="text" className="row-input ml-1"
              name="a_success_record"
              value={detail_info["a_success_record"]}
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              onClick={(e:any)=>{e.target.select();}}
              autoComplete="off"
              style={{ width: "98%",textAlign:"center" }} />
          </td>
          <td>
            <input type="text" className="row-input" placeholder=""
              name="a_memo"
              value={detail_info["a_memo"]}
              autoComplete="off"
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              style={{ width: "99%" }} />
          </td>
          <td className="text-center">
            <input type="checkbox" 
              name="a_is_success"
              value=""
              checked={detail_info["a_is_success"]=="1"}
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              autoComplete="off"
               />
          </td>
          <td className="text-center">
            {is_exist_detail&&
            <span title={detail_info["a_reserve_date"]}>
              {detail_info["a_reserve_date"].substring(5,10)}
            </span>
            }
          </td>
          <td className="text-center">
            {detail_info["a_is_success"]=="1"&&
              <span title={detail_info["a_success_date"]}>
                {detail_info["a_success_date"].substring(5,10)}
              </span>
            }
          </td>
          {/* <td className="text-center">
            {is_exist_detail&&
              <button className="btn-s btn-red" 
                onClick={(e:any)=>{remove_detail_of_detail_info(detail_info);}}>X</button>
            }
          </td> */}
        </tr>
      );
    });

    return study_detail_tags;
  };

  return (
    <div>
      <div className="write_table_small pl-2">
        <table style={{ width: "98%" }}>
          <colgroup>
            <col width={"1%"}></col>
            <col width={"*"}></col>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            <col width={"6%"}></col>
            <col width={"10%"}></col>
            <col width={"3%"}></col>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
            {/* <col width={"5%"}></col> */}
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>수량</th>
              <th>수행</th>
              <th>반복</th>
              <th>점수기록</th>
              <th>메모</th>
              <th>완료</th>
              <th>시작일</th>
              <th>완료일</th>
              {/* <th>관리</th> */}
            </tr>
          </thead>
          <tbody>
            {get_study_detail_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DetailListArea;