import StudyDetailListArea from "./study_list";

function SectionStudyArea(props: any) {
  let myProps = {
    Style: {},
    study_map_info: {},
    callback_refresh_data: (inData: any) => { },
    open_map_detail_write_popup: (inData: any) => { },
    ...props
  };
  let Style=myProps.Style;
  let section_arr = myProps.study_map_info.section_arr;
  let study_detail_arr = myProps.study_map_info.study_detail_arr;
  let map_detail_arr = myProps.study_map_info.detail_arr;

  const get_section_tags=()=>{
    let section_tags=section_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx} className={Style.section_row}>
          <div className={Style.section_title_wrap}>{idx+1}. {item.a_title}</div>
          <StudyDetailListArea
            Style={Style}
            section_info={item}
            study_detail_arr={study_detail_arr}
            map_detail_arr={map_detail_arr}
            open_map_detail_write_popup={myProps.open_map_detail_write_popup}
          ></StudyDetailListArea>
        </div>
      );
    });

    return section_tags;
  };

  return (
    <div>
      {get_section_tags()}
    </div>
  );
};
export default SectionStudyArea;