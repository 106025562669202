import getXcolumnJson from "./xcolumn/list";
import StudyArea from "./study";
import strFunc from "@/lib/lyg/string";

function SectionArea(props: any) {
  const myProps = {
    section_info: {},
    section_row_num: 0,
    section_arr: [],
    study_arr: [],
    set_section_arr: (section_arr: any) => { },
    set_study_arr: (study_arr: any) => { },
    check_study_seq_arr: [],
    set_check_study_seq_arr: (inData: any) => { },

    onChangeRowData: (inData: any) => { },
    moveOrderNum: (inData: any) => { },
    removeRowData:(inData:any)=>{},
    copySectionRow:(inData: any) => { },
    onChangeKeyUpStudyList: (inData: any) => { },
    ...props
  };

  const studyXColumnArr = getXcolumnJson();
  const default_row_data = {
    "a_title_seq": "",
    "a_seq": "1",
    "a_section_seq": "",
    "a_title": "",
    "a_content": "",
    "a_amount_sort": "",
    "a_amount_num": "",
    "a_order_num": "",
  };
  let study_arr = myProps.study_arr;
  let section_arr = myProps.section_arr;

  const on_section_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_check_study_seq_arr = [...myProps.check_study_seq_arr];
      for (let i = 0; i < myProps.section_info.study_arr.length; i++) {
        let tmp_study_info = myProps.section_info.study_arr[i];
        if (strFunc.str_in_array(tmp_study_info["a_seq"], tmp_check_study_seq_arr) == -1) {
          tmp_check_study_seq_arr.push(tmp_study_info["a_seq"]);
        }
      }
      myProps.set_check_study_seq_arr(tmp_check_study_seq_arr);
    } else {
      let tmp_check_study_seq_arr: any = [];
      let tmp_section_study_seq_arr = [];
      for (let i = 0; i < myProps.section_info.study_arr.length; i++) {
        let tmp_study_info = myProps.section_info.study_arr[i];
        tmp_section_study_seq_arr.push(tmp_study_info["a_seq"]);
      }
      //현제꺼 제외해서 등록
      for (let i = 0; i < myProps.check_study_seq_arr.length; i++) {
        let tmp_checked_seq = myProps.check_study_seq_arr[i];
        if (strFunc.str_in_array(tmp_checked_seq, tmp_section_study_seq_arr) == -1) {
          tmp_check_study_seq_arr.push(tmp_checked_seq);
        }
      }
      myProps.set_check_study_seq_arr(tmp_check_study_seq_arr);
    }
  };

  const addNewRow = () => {
    if (myProps.section_info.a_seq == "empty") {
      return false;
    }
    let max_a_seq = 0;
    let last_section_seq = myProps.section_info.a_seq;
    let last_study_info = null;
    if (study_arr.length > 0) {
      for (let i = 0; i < study_arr.length; i++) {
        let tmp_a_seq = parseInt(study_arr[i].a_seq);
        if (tmp_a_seq > max_a_seq) {
          max_a_seq = tmp_a_seq;
        }

        if (myProps.section_info.a_seq == study_arr[i].a_section_seq) {
          last_study_info = study_arr[i];
        }
      }
    }
    let addRow: any = { ...default_row_data };
    addRow["a_seq"] = max_a_seq + 1;
    addRow["a_order_num"] = addRow["a_seq"];
    addRow["a_section_seq"] = last_section_seq;
    if (last_study_info != null) {
      addRow["a_amount_sort"] = last_study_info["a_amount_sort"];
      addRow["a_amount_num"] = last_study_info["a_amount_num"];
      addRow["a_title"] = last_study_info["a_title"];
      addRow["a_content"] = last_study_info["a_content"];
    }
    let tmp_study_arr: any = [...study_arr];
    tmp_study_arr.push(addRow);
    myProps.set_study_arr(tmp_study_arr);
  };

  const get_study_tags = () => {
    let study_tags: any = "";
    if (myProps.section_info.study_arr) {
      study_tags = myProps.section_info.study_arr.map((item: any, idx: number) => {
        return (
          <StudyArea
            key={idx}
            study_info={item}
            study_idx={idx}
            studyXColumnArr={studyXColumnArr}
            section_arr={myProps.section_arr}
            study_arr={myProps.study_arr}
            set_study_arr={myProps.set_study_arr}
            check_study_seq_arr={myProps.check_study_seq_arr}
            set_check_study_seq_arr={myProps.set_check_study_seq_arr}
            onChangeKeyUp={myProps.onChangeKeyUpStudyList}
          ></StudyArea>
        );
      });
    }

    if (strFunc.is_empty(study_tags)) {
      study_tags = (
        <tr>
          <td colSpan={99}>
            <div className="text-center" style={{ lineHeight: "40px" }}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return study_tags;
  };

  return (
    <div className="mt-5" >
      <div className="text-left">
        <span>
          {myProps.section_row_num + 1}.
        </span>
        <input type="text"
          style={{ width: 200, marginLeft: 3 }}
          value={myProps.section_info.a_title} onChange={(e: any) => {
            myProps.onChangeRowData({
              key: "a_title",
              value: e.target.value,
              row_num: myProps.section_row_num,
            });
          }} />
        <span className="ml-2">
          <button className="btn-s btn-gray" onClick={() => { myProps.moveOrderNum(myProps.section_row_num, "up"); }}>▲</button>
          <button className="btn-s btn-gray ml-2" onClick={() => { myProps.moveOrderNum(myProps.section_row_num, "down"); }}>▼</button>
          <button className="btn-s btn-red ml-2" onClick={() => { myProps.removeRowData(myProps.section_row_num); }}>X</button>
          <button className="btn-s btn-gray ml-2" onClick={() => { addNewRow(); }}>소단원 추가</button>
          <button className="btn-s btn-gray ml-2" onClick={() => { myProps.copySectionRow({section_info:myProps.section_info}); }}>복사</button>
        </span>
      </div>

      <div className="list-table-div mt-1" style={{ width: "99%", marginLeft: "1%" }} >
        <table>
          <thead>
            <tr>
              {
                studyXColumnArr.x_column_list_arr.map((column: any, idx: number) => {
                  let column_str: any = column["name"];
                  if (column["key"] == "check_box") {
                    column_str = (
                      <input type="checkbox" value="1" onChange={on_section_check_all} />
                    );
                  }
                  return (
                    <th key={idx}>
                      <div style={{ width: parseInt(column["width"]) }}>
                        {column_str}
                      </div>
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {get_study_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default SectionArea;