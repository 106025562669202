import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import DailyDataFunc from "@/pages/comp/edu/daily_report/card_list/func/daily_data_func";

function DailyWeekTds(props: any) {
  let myProps = {
    info: {},
    lecture_info: null,
    last_daily_info: {},
    select_date: "",
    pre_date_str: "",
    show_week_arr: [],
    lecture_time_str: "",
    td_sort: "textbook",//textbook,subtextbook
    open_write_td_data_popup: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let lecture_info = myProps.lecture_info;

  const get_week_td_tags = () => {
    let week_td_tags = myProps.show_week_arr.map((xcolumn: any, col_idx: number) => {
      //row_day_num,change_day_diff_num,week_date_str

      let select_date_json = DateFunc.get_date_json(new Date(xcolumn["week_date_str"]));
      let pre_date_change_num = -1;
      if (select_date_json.day == 1) {
        //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
        pre_date_change_num = -3;
      }
      let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(xcolumn["week_date_str"]), 'day', pre_date_change_num), "Y-m-d");

      let daily_info = DailyDataFunc.get_daily_info_of_date({
        date_str: xcolumn["week_date_str"],
        pre_date_str: pre_date_str,
        is_now_date: xcolumn["change_day_diff_num"] == 0 ? true : false,
        last_daily_info: myProps.last_daily_info,
        lecture_time_str: myProps.lecture_time_str,
        info: info,
        lecture_info: lecture_info,
      });

      let td_style: any = {};
      let td_input_style: any = {};
      if (xcolumn["change_day_diff_num"] == 0) {
        td_style["border"] = "2px solid green";
      }

      let daily_percent = DailyDataFunc.get_daily_percent({
        daily_info: daily_info,
        is_sub: myProps.td_sort == "subtextbook"
      });
      let color_json = TodoStateFunc.get_color_of_percent(daily_percent);

      let text_value = "";
      if (myProps.td_sort == "textbook") {
        text_value=daily_info["a_textbook_study"].substring(0,10);
        text_value += " "+daily_info["a_textbook_study_det"];
      } else if (myProps.td_sort == "subtextbook") {
        text_value = daily_info["a_subtextbook_study"].substring(0,10);
        text_value +=" "+daily_info["a_subtextbook_study_det"];
      }
      //text_value=xcolumn["week_date_str"];
      return (
        <td key={col_idx}
          onClick={() => {
            myProps.open_write_td_data_popup({
              daily_report: daily_info,
              td_sort: myProps.td_sort
            });
          }} style={td_style}>
          <input type="text" value={text_value} readOnly
            onChange={(e: any) => { }}
            style={{ width: "99%", border: 0, cursor: "pointer", background: "transparent", ...td_input_style }}
            title={text_value} />
        </td>
      );
    });
    return week_td_tags;
  };
  return (
    <>
      {get_week_td_tags()}
    </>
  );
};
export default DailyWeekTds;