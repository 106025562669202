//import MoveFocusInputByKeyEventFunc from "@/pcomponents/common/crud/write/input_func/move_focus_by_key_event";
import strFunc from '@/lib/lyg/string';

class MoveFocusInputByKeyEventFunc
{
  static onKeyEvent(inData:any){
    let opt_obj={
      e:{},
      row_col_len:3,
      input_class_name:"row_input",
      par_element:null,
      focus_sort:"select",//focus,select
      ...inData
    };
    let e=opt_obj.e;
    if(e.target==undefined){
      return false;
    }
    if(opt_obj.par_element==null){
      return false;
    }
    if(strFunc.is_empty(opt_obj.input_class_name)){
      return false;
    }
    let par_element=opt_obj.par_element;
    let input_class_name=opt_obj.input_class_name;
    let now_input=e.target;
    let row_input_arr=par_element.querySelectorAll("."+input_class_name);
    let select_st_end:any=strFunc.getInputSelection(now_input);
    let value=now_input.value;
    let key_i=Array.from(row_input_arr).indexOf(now_input);
    let next_key_i=key_i;
    let is_able_focus=false;
    if(e.keyCode==37){
      //왼
      if(select_st_end.start==0){
        next_key_i--;
        if(next_key_i<0){
          next_key_i=0;
          return false;
        }
        is_able_focus=true;
      }
    }else if(e.keyCode==39){
      //오
      if(select_st_end.end==value.length){
        next_key_i++;
        if(next_key_i>=row_input_arr.length){
          next_key_i=row_input_arr.length-1;
          return false;
        }
        is_able_focus=true;
      }
    }else if(e.keyCode==38){
      //위
      next_key_i=next_key_i-opt_obj.row_col_len;
      if(next_key_i<0){
        next_key_i=key_i;
        return false;
      }
      is_able_focus=true;
    }else if(e.keyCode==40){
      //아래
      next_key_i=next_key_i+opt_obj.row_col_len;
      if(next_key_i>=row_input_arr.length){
        next_key_i=key_i;
        return false;
      }
      is_able_focus=true;
    }
    if(is_able_focus){
      if(opt_obj.focus_sort=="select"){
        row_input_arr[next_key_i].select();
      }else if(opt_obj.focus_sort=="focus"){
        row_input_arr[next_key_i].focus();
      }
    }
  };
}
export default MoveFocusInputByKeyEventFunc;