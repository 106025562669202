import { useState, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudyTitlePopup from "@/pages/comp/plan/study/study/popup/find_study_title";
import StuMapWritePopup from "@/pages/comp/plan/study/study_map/stu_map/popup/stu_map_write";
import Style from "./css/style.module.css";
import MapListArea from "./area/list";

const StuMapListArea = forwardRef((props: any, ref) => {
  let myProps = {
    list: (inOptObj: any) => { },
    ...props
  };

  let default_stu_info = {
    "a_seq": "",
    "a_user_name": "",
  };
  const [stu_info, set_stu_info] = useState<any>({
    ...default_stu_info
  });
  const [study_map_arr,set_study_map_arr] = useState<any>([]);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_study_title",//find_study_title,stu_map_write

    find_study_title_list_opt:{},
    study_map_pri_val: "",

    has_header: true,
    title: "교재찾기",
    width: "800px",
    height: "100%",
  });

  const setInitData = (inOptObj: any) => {
    let optObj = {
      "stu_seq": "",
      ...inOptObj
    };

    get_stu_info_by_ajax({
      stu_seq: optObj["stu_seq"]
    });
    get_study_map_list_by_ajax({
      stu_seq: optObj["stu_seq"]
    });
  };

  useImperativeHandle(ref, () => ({
    setInitData
  }));

  const get_stu_info_by_ajax=(inData:any)=>{
    let opt_obj={
      "stu_seq":stu_info.a_seq,
      ...inData
    };

    if(strFunc.is_empty(opt_obj["stu_seq"])){
      set_stu_info({
        ...default_stu_info
      });
      return false;
    }

    let form_json_data:any={
      "s_pri_arr": [opt_obj["stu_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_stu_info({
              ...default_stu_info,
              ...response.data["data"]["info_arr"][0]
            });
          }else{
            set_stu_info({
              ...default_stu_info,
            });
          }
        } else {

        }
      });
  };

  const get_study_map_list_by_ajax=(inData:any)=>{
    let opt_obj={
      "stu_seq":stu_info.a_seq,
      ...inData
    };

    if(strFunc.is_empty(opt_obj["stu_seq"])){
      set_stu_info({
        ...default_stu_info
      });
      return false;
    }

    let form_json_data:any={
      "s_stu_seq": opt_obj["stu_seq"],
      "s_addon_study_title":"1",
      "s_addon_study_detail":"1",
      "s_addon_detail":"1",
      "order_id":"a_update_date DESC",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_map_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const open_find_study_title_popup = (inData:any) => {
    let opt_obj={
      ...inData
    };
    let s_except_seq:any=[];
    for(let i=0;i<study_map_arr.length;i++){
      s_except_seq.push(study_map_arr[i]["a_study_title_seq"]);
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "교재찾기",

      find_study_title_list_opt:{
        s_except_seq:s_except_seq,
        s_addon_use:"",
      },

      "sort": "find_study_title",
      width: "800px",
      height: "99%",
    });
  };

  const callback_find_study_title_popup=(inData:any)=>{
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key: "",
      par_info: null,
      ...inData,
    };
    let select_study_title_arr=opt_obj["info_arr"];
    if(select_study_title_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(stu_info.a_seq)){
      alert("학생정보가 없습니다.");
      return false;
    }

    let add_study_map_arr:any=[];
    let now_ymdhis=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
    for(let i=0;i<select_study_title_arr.length;i++){
      let select_study_title=select_study_title_arr[i];
      let tmp_total_cnt=0;
      if(select_study_title["study_arr"]){
        tmp_total_cnt=select_study_title["study_arr"].length;
      }
      add_study_map_arr.push({
        "a_study_title_seq":select_study_title["a_seq"],
        "a_stu_seq":stu_info.a_seq,
        a_stu_name:stu_info.a_user_name,
        a_total_cnt:0,
        map_start_date:now_ymdhis,
        map_create_date:now_ymdhis,
        map_update_date:now_ymdhis,
      });
    }
    if(add_study_map_arr.length==0){
      alert("등록할 데이터 없음.");
      return false;
    }

    if(!confirm(add_study_map_arr.length+"개의 교재를 추가 하시겠습니까?")){
      return false;
    }

    let form_json_data:any={
      "data_arr":add_study_map_arr,
      "is_default_val":"1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("추가되었습니다.");
          get_study_map_list_by_ajax({});
          myProps.list({});
        } else {
          alert("추가에 실패하였습니다."+response.data["msg"]);
        }
      });
  };

  const open_stu_map_write_popup = (inData:any) => {
    let opt_obj={
      study_map_info:{},
      ...inData
    };
    let tmp_study_map_info=opt_obj["study_map_info"];
    if(strFunc.is_empty(tmp_study_map_info["a_study_title_seq"])
      ||strFunc.is_empty(tmp_study_map_info["a_stu_seq"])){
        alert("교재맵핑 데이터가 올바르지 않습니다.");
        return false;
    }
    let study_map_pri_val=tmp_study_map_info["a_study_title_seq"]+","+tmp_study_map_info["a_stu_seq"];
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "교재맵핑",
      "study_map_pri_val":study_map_pri_val,
      "sort": "stu_map_write",
      width: "800px",
      height: "99%",
    });
  };

  return (
    <div>
      <div className={Style.top_info_wrap}>
        <span style={{color:"blue",fontSize:"15px"}}>{stu_info.a_user_name}</span> 학생 교재맵핑
        {!strFunc.is_empty(stu_info.a_seq)&&
          <button className="btn btn-dark"
            onClick={()=>{
              open_find_study_title_popup({});
            }}
            style={{position:"absolute",right:"10px"}}
          >교재추가</button>
        }
      </div>
      <div className="mt-1">
        <MapListArea
          Style={Style}
          study_map_arr={study_map_arr}
          open_stu_map_write_popup={open_stu_map_write_popup}
        ></MapListArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "find_study_title" &&
            <FindStudyTitlePopup
              listOpt={popupData.find_study_title_list_opt}
              is_no_show_column_arr={["row_view_use"]}
              callBackData={callback_find_study_title_popup}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindStudyTitlePopup>
          }
          {popupData.sort == "stu_map_write" &&
            <StuMapWritePopup
              study_map_pri_val={popupData.study_map_pri_val}
              callback_refresh_data={() => { 
                get_study_map_list_by_ajax({});
                myProps.list({});
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></StuMapWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
});
export default StuMapListArea;