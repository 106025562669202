import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import AttendStateFunc from "@/pcomponents/common/content/edu/attend/func/state_func";
import strFunc from '@/lib/lyg/string';

function TdBoxArea(props: any) {
  let myProps = {
    lecture_info: {},
    tr_td_row: {},
    stu_grade_sort: "",
    is_show_attend: false,
    is_show_one_lecture_stu: false,
    Style: {},
    ...props
  };
  let Style = myProps.Style;
  let lecture_info = myProps.lecture_info;
  let tr_td_row = myProps.tr_td_row;
  let td_data = null;
  let stu_union_time_arr: any = [];
  if (tr_td_row.td_data) {
    td_data = tr_td_row.td_data;
    stu_union_time_arr = td_data.stu_union_time_arr;
  }

  let select_option_arr: any = [];
  if (myProps.stu_grade_sort != "") {
    select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': myProps.stu_grade_sort });
  }

  const get_stu_span_tags = () => {
    let stu_span_tags = stu_union_time_arr.map((item: any, idx: number) => {
      let grade_str = item.a_stu_grade;
      
      for (let i = 0; i < select_option_arr.length; i++) {
        if (select_option_arr[i]["value"] == grade_str) {
          grade_str = select_option_arr[i]["text"];
        }
      }
      let stu_span_style:any={};
      if(myProps.is_show_attend){
        if(item.user_info&&item.user_info.attend_in_arr&&item.user_info.attend_in_arr.length>0){
          let attend_info=item.user_info.attend_in_arr[0];
          let tmp_in_attend_state=AttendStateFunc.get_color_by_attend_info({"attend_info":attend_info});
          stu_span_style["background"]=tmp_in_attend_state["background"];
        }
      }
      if(myProps.is_show_one_lecture_stu){
        if(item.user_info){
          if(strFunc.is_empty(item.user_info.lecture_length)){
            
          }else if(item.user_info.lecture_length==1){
            stu_span_style["color"]="blue";
          }else{
            //stu_span_style["color"]="blue";
          }
        }
      }
      return (
        <span key={idx} className={Style.time_table_lecture_stu_row} style={stu_span_style}>
          {item.a_stu_name}
          {myProps.stu_grade_sort != "" &&
            <span style={{ color: "#777" }}>({grade_str})</span>
          }
        </span>
      );
    });
    return stu_span_tags;
  };

  //요일정보
  let day_num_arr = [];
  if (td_data) {
    day_num_arr = td_data.day_num_arr;
  }
  let week_name_arr = ["일", "월", "화", "수", "목", "금", "토"];
  let row_week_name_arr = [];
  day_num_arr.sort();
  for (let i = 0; i < day_num_arr.length; i++) {
    row_week_name_arr.push(
      week_name_arr[day_num_arr[i]]
    );
  }
  let row_week_name_str = row_week_name_arr.join(",");

  let td_title_str = "";
  if (td_data) {
    td_title_str = td_data.name;
    if (lecture_info) {
      if (lecture_info["a_main_teacher"] != td_data.teacher_name) {
        td_title_str += " " + td_data.teacher_name;
      }
      if (lecture_info["a_room_name"] != td_data.room_name) {
        td_title_str += " " + td_data.room_name;
      }
    }

    td_title_str += "(" + row_week_name_str + ")";
    if (stu_union_time_arr.length > 0) {
      td_title_str += " " + stu_union_time_arr.length + "명";
    }
  }

  let td_class_name=Style.time_table_td;
  if(td_title_str!=""){
    td_class_name+=" "+Style.has_time_td;
  }

  let stu_wrap_style:any={};
  if(tr_td_row.col_span>=2){
    stu_wrap_style["maxWidth"]=100+(80*tr_td_row.col_span);
  }

  return (
    <td className={td_class_name} rowSpan={tr_td_row.row_span} colSpan={tr_td_row.col_span} >
      <div className={Style.time_table_td_box}>
        {td_data &&
          <>
            <div className={Style.time_table_lecture_title}>
              {td_title_str}
            </div>
            <div className={Style.time_table_lecture_stu_wrap} style={stu_wrap_style}>
              {get_stu_span_tags()}
            </div>
          </>
        }
      </div>
    </td>
  );
};
export default TdBoxArea;