function HomeWorkStateSelect(props: any) {
  let myProps = {
    ...props
  };

  return (
    <>
      
    </>
  );
};
export default HomeWorkStateSelect;