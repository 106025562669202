function ColorHelpArea(props: any) {
  let myProps = {
    wrap_div_style: {},
    ...props
  };

  let wrap_div_style:any={
    textAlign: "left",
    ...myProps.wrap_div_style,
  };

  return (
    <div>
      <div style={wrap_div_style}>
        ※ 처방:
        <span className="ml-1" style={{ color: "#707070" }}>■</span>예약
        <span className="ml-1" style={{ color: "#eab358" }}>■</span>작성
        <span className="ml-1" style={{ color: "#f26b6f" }}>■</span>질문
        <span className="ml-1" style={{ color: "#a2b854" }}>■</span>처방
      </div>
      <div className="mt-1" style={wrap_div_style}>
        작성자:
        <span className="ml-2">※처방전:</span>
        <span className="ml-1" style={{ color: "#f26b6f" }}>■</span>질문
        <span className="ml-1" style={{ color: "#eab358" }}>■</span>답변
        <div>
          작성자:
          <span className="ml-2">※처방후:</span>
          <span className="ml-1" style={{ color: "#f26b6f" }}>■</span>미확인
          <span className="ml-1" style={{ color: "#a2b854" }}>■</span>확인
        </div>
      </div>
    </div>
  );
};
export default ColorHelpArea;