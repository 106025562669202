import strFunc from "@/lib/lyg/string";

function StudyRowArea(props: any) {
  let myProps = {
    Style: {},
    study_idx: 0,
    study_info: {},
    section_info: {},
    map_arr: [],
    map_det_arr: [],
    open_map_detail_write_popup:(inData:any)=>{},
    ...props
  };
  let Style = myProps.Style;
  let study_idx = myProps.study_idx;
  let study_info = myProps.study_info;
  let section_info = myProps.section_info;
  let map_arr = myProps.map_arr;
  let map_det_arr = myProps.map_det_arr;

  const get_stu_of_study_row=()=>{
    let row_map_det_arr:any=[];
    let stu_seq_arr:any=[];
    let stu_data_key_val:any=[];
    let default_stu_data={a_stu_seq:"",a_stu_name:"",is_success:false,map_det_info:{}};
    for(let i=0;i<map_det_arr.length;i++){
      let row_stu_seq=map_det_arr[i]["a_stu_seq"];
      if(map_det_arr[i].a_section_name==section_info.a_title
        &&map_det_arr[i].a_unit_name==study_info.a_title
      ){
        row_map_det_arr.push(map_det_arr[i]);
        if(strFunc.str_in_array(row_stu_seq,stu_seq_arr)==-1){
          stu_seq_arr.push(row_stu_seq);
          if(stu_data_key_val[row_stu_seq]==undefined){
            stu_data_key_val[row_stu_seq]={...default_stu_data};
            stu_data_key_val[row_stu_seq]["map_det_info"]=map_det_arr[i];
          }
          stu_data_key_val[row_stu_seq]["a_stu_seq"]=row_stu_seq;
          if(map_det_arr[i]["a_is_success"]=="1"){
            stu_data_key_val[row_stu_seq]["is_success"]=true;
          }
        }
      }
    }
    
    for(let i=0;i<map_arr.length;i++){
      for(let key in stu_data_key_val){
        if(key==map_arr[i]["a_stu_seq"]){
          stu_data_key_val[key]["a_stu_name"]=map_arr[i]["a_stu_name"];
        }
      }
    }

    let stu_data_arr=[];
    for(let key in stu_data_key_val){
      stu_data_arr.push(stu_data_key_val[key]);
    }

    return stu_data_arr;
  };

  const get_stu_data_tags=()=>{
    let stu_data_arr=get_stu_of_study_row();
    
    let stu_data_tags=stu_data_arr.map((item:any,idx:number)=>{
      let stu_box_style:any={};
      if(item.is_success){
        stu_box_style={backgroundColor:"#66d544"};
      }
      let row_file_cnt=0;
      if(item.map_det_info&&item.map_det_info.file_arr){
        row_file_cnt=item.map_det_info.file_arr.length;
      }
      return (
        <span key={idx} className={Style.study_list_stu_box} style={stu_box_style}
          onClick={()=>{
            myProps.open_map_detail_write_popup({
              map_detail_info:item.map_det_info,
              section_study_name:{
                section_name:section_info.a_title,
                unit_name:study_info.a_title,
              },
            });
          }}>
          {idx+1}. {item.a_stu_name} 
          {row_file_cnt>0&&
            <span className="ml-1">({row_file_cnt})</span>
          }
        </span>
      );
    });

    return stu_data_tags;
  };

  return (
    <div className={Style.study_list_row}>
      <table style={{width:"100%"}}>
        <colgroup>
          <col width={"50%"}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td style={{borderBottom:"1px solid #eee"}}>
              <div className={Style.study_list_title}>
                {study_idx + 1}. {study_info.a_title}
              </div>
            </td>
            <td style={{borderBottom:"1px solid #eee"}}>
              <div className={Style.study_list_stu_wrap}>
                {get_stu_data_tags()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default StudyRowArea;