import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt:{},
    list:(inData:any)=>{},
    closePopup:()=>{},
    closeText:"닫기",
    ...props
  };
  let listOpt=myProps.listOpt;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const on_change_search_input=(inData:any)=>{
    let name=inData.target.name;
    let value=inData.target.value;
    let change_list_opt:any={};
    change_list_opt[name]=value;
    myProps.list({
      now_page:"1",
      ...change_list_opt
    });
  };

  return (
    <div className="btn-box-center m-2">
      <select className="search-input" name="s_sort" value={listOpt.s_sort} 
        onChange={on_change_search_input} >
          <option value="">전체</option>
        {select_subject_option_arr.map((item: any, idx: number) => {
          return (
            <option key={idx} value={item.value}>{item.text}</option>
          );
        })}
      </select>
      <button className="btn btn-dark" onClick={()=>{myProps.list({});}}>검색</button>
      <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>{myProps.closeText}</button>
    </div>
  );
};
export default SearchArea;