import strFunc from "@/lib/lyg/string";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";

function ManageBtnArea(props: any) {
  let myProps = {
    is_under_width: false,
    countInfo:{"tot":0},
    listOpt: {},
    list: (inData: any) => { },
    goExcelDown: () => { },
    openListOptPopup: () => { },
    openSendSMSPopup: () => { },
    open_send_push_popup: () => { },
    openOnePopup: (sort:string,is_update:false) => { },
    show_write_page: (is_update:boolean,stu_info:any) => { },
    select_show_write_page: () => { },
    add_stu_bookmark: () => { },
    del_stu_bookmark: () => { },
    openStuEndPopup: () => { },
    goDelete: () => { },
    ...props
  };
  let is_under_width = myProps.is_under_width;
  let countInfo = myProps.countInfo;
  let listOpt = myProps.listOpt;

  let stu_end_btn_class_name="btn-m btn-red ml-1";
  let stu_end_btn_text="휴/퇴원";
  if(strFunc.str_in_array(listOpt.s_stu_state,["now,ready","","now"])==-1){
    stu_end_btn_class_name="btn-m btn-blue ml-1";
    stu_end_btn_text="재원";
  }

  return (
    <div className="basic_list_title_bar_wrap">
      {is_under_width == false &&
        <>
          <span>
            총 <span className="text-blue-600">{countInfo.tot}</span> 명
          </span>
          <span className="ml-1">
            <SearchOrderRadio
              valueTextArr={[
                { "text": "최근등록", "value": "a_create_date DESC" },
                { "text": "이름", "value": "a_user_name" },
                { "text": "학년", "value": "a_stu_grade, a_user_name" },
                { "text": "출결번호", "value": "user_stu_num" },
              ]}
              value={listOpt.order_id}
              handleInputChange={(e: any) => {
                myProps.list({
                  "now_page": 1,
                  "order_id": e.target.value
                });
              }}
            ></SearchOrderRadio>
          </span>
        </>
      }
      <div className="basic_title_bar_right_box">
        {is_under_width == false &&
          <>
            <button className="btn-m btn-line-gray" onClick={() => { myProps.goExcelDown(); }}>엑셀</button>
            <button className="btn-m btn-line-gray ml-1" onClick={() => { myProps.openListOptPopup(); }}>화면</button>
          </>
        }
        <button className="btn-m btn-yellowish ml-1" onClick={() => { myProps.openSendSMSPopup(); }}>문자</button>
        <button className="btn-m btn-yellowish ml-1" onClick={() => { myProps.open_send_push_popup(); }}>푸시</button>
        {is_under_width == false &&
          <>
            <button className="btn-m btn-sky ml-1" onClick={() => { myProps.openOnePopup("write_multy", false); }}>전체등록</button>
            {/* <button className="btn-m btn-sky ml-1" onClick={()=>{openOnePopup("write_multy_makedu",false);}}>다량등록(MakeEdu)</button> */}
          </>
        }
        <button className="btn-m btn-sky ml-1" onClick={() => {/*openOnePopup("write",false);*/ myProps.show_write_page(false, {}); }}>등록</button>
        <button className="btn-m btn-gray ml-1" onClick={() => {/*openOnePopup("write",false);*/ myProps.show_write_page(false, { "a_stu_state": "ready" }); }}>등록(대기)</button>
        <button className="btn-m btn-sky ml-1" onClick={() => {/*openOnePopup("write",true);*/ myProps.select_show_write_page(); }}>수정</button>
        <button className="btn-m btn-gray ml-1" onClick={() => { myProps.add_stu_bookmark(); }}>즐찾</button>
        <button className="btn-m btn-gray ml-1" onClick={() => { myProps.del_stu_bookmark(); }}>즐찾X</button>
        <button className={stu_end_btn_class_name} onClick={() => { myProps.openStuEndPopup(); }}>{stu_end_btn_text}</button>
        {/* <button className="btn btn-dark" onClick={()=>{goAddVideoUseByMakedu();}}>동영상강의세팅</button> */}
        <button className="btn-m btn-red ml-1" onClick={myProps.goDelete}>삭제</button>
      </div>
    </div>
  );
};
export default ManageBtnArea;