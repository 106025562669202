//import ListSimpleArea from "@/pcomponents/common/crud/list/list_simple";
import strFunc from "@/lib/lyg/string";

function ListSimpleArea(props: any) {
  let myProps = {
    xColumnArr: {},
    infoArr: [],
    setInfoArr: (inData: any) => { },
    select_row_num_arr: [],
    set_select_row_num_arr: [],
    is_grid: false,
    get_td_render_tag: (inData: any) => {
      return inData.render_str;
    },
    ...props
  };
  let infoArr = myProps.infoArr;
  let select_row_num_arr = myProps.select_row_num_arr;

  const on_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_check_pri_val_arr = [];
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        tmp_check_pri_val_arr.push(i);
      }
      myProps.set_select_row_num_arr(tmp_check_pri_val_arr);
    } else {
      myProps.set_select_row_num_arr([]);
    }
  };
  const go_check_row_toggle = (inData: any) => {
    let opt_obj = {
      row_num: "",
      ...inData
    };
    let row_num = opt_obj.row_num;
    if (strFunc.str_in_array(row_num, select_row_num_arr) == -1) {
      //추가
      let tmp_check_pri_val_arr = [...select_row_num_arr];
      tmp_check_pri_val_arr.push(row_num);
      myProps.set_select_row_num_arr(tmp_check_pri_val_arr);
    } else {
      //제거
      let tmp_check_pri_val_arr = [];
      for (let i = 0; i < select_row_num_arr.length; i++) {
        if (select_row_num_arr[i] != row_num) {
          tmp_check_pri_val_arr.push(select_row_num_arr[i]);
        }
      }
      myProps.set_select_row_num_arr(tmp_check_pri_val_arr);
    }
  };
  const on_change_row_input = (inData: any) => {
    let opt_obj = {
      row_num: 0,
      key: "",
      value: "",
      ...inData
    };
    let key = opt_obj["key"];
    let value = opt_obj["value"];

    let change_infoArr = [...infoArr];
    for (let i = 0; i < change_infoArr.length; i++) {
      let info = change_infoArr[i];
      if (i == opt_obj.row_num) {
        change_infoArr[i][key] = value;
      }
    }
    myProps.setInfoArr(change_infoArr);
  };

  const get_colgroup_col_tags = () => {
    let col_tags = myProps.xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
      if (item.is_show != "1") { return ""; }
      return (
        <col key={idx} width={item.width}></col>
      );
    });
    return col_tags;
  };

  const get_th_tags = () => {
    let th_tags = myProps.xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
      if (item.is_show != "1") { return ""; }
      return (
        <th key={idx}>{item.name}</th>
      );
    });
    return th_tags;
  };

  const get_td_render_tag = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      name: "",
      row_num: 0,
      info: {},
      render_str: "",
      on_change_row_input:on_change_row_input,
      ...inData
    };

    let render_str: any = opt_obj.value;

    opt_obj.render_str = render_str;

    if (myProps.is_grid) {
      if (myProps.xColumnArr.select_arr[opt_obj.key]) {
        let tmp_select_opt_arr = myProps.xColumnArr.select_arr[opt_obj.key];
        let tmp_opt_tags = tmp_select_opt_arr.map((opt_info: any, opt_idx: number) => {
          return (
            <option key={opt_idx} value={opt_info["value"]}>{opt_info["text"]}</option>
          );
        });
        opt_obj.render_str = (
          <select className="row-input" name={opt_obj.key} value={opt_obj.value} title={opt_obj.value}
            onChange={(e: any) => {
              on_change_row_input({
                row_num: opt_obj.row_num,
                key: opt_obj.key,
                value: e.target.value,
              });
            }} 
            style={{width:"100%",maxWidth:"100",border:"1px solid #ddd"}}>
            
            <option value="">선택</option>
            {tmp_opt_tags}
          </select>
        );
      } else {
        opt_obj.render_str = (
          <input type="text" className="row-input" name={opt_obj.key} value={opt_obj.value} title={opt_obj.value}
            onChange={(e: any) => {
              on_change_row_input({
                row_num: opt_obj.row_num,
                key: opt_obj.key,
                value: e.target.value,
              });
            }} />
        );
      }
    }

    render_str = myProps.get_td_render_tag(opt_obj);

    return render_str;
  };

  const get_tr_td_tags = (inData: any) => {
    let opt_obj = {
      info: {},
      row_num: 0,
      ...inData,
    };
    let info: any = opt_obj.info;
    let row_num = opt_obj.row_num;
    let td_tags = myProps.xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
      if (item.is_show != "1") { return ""; }
      let key = item.key;
      let value = "";

      if (info[key] != undefined) {
        value = info[key];
      }
      return (
        <td key={idx} className="text-center">
          {get_td_render_tag({
            key: key,
            value: value,
            name: item.name,
            row_num: row_num,
            info: info,
          })}
        </td>
      );
    });
    return td_tags;
  };

  const get_body_tr_tags = () => {
    let tr_tags = infoArr.map((info: any, info_idx: number) => {
      let row_is_checked = false;
      if (strFunc.str_in_array(info_idx, select_row_num_arr) != -1) {
        row_is_checked = true;
      }

      let tr_style: any = {};
      if (row_is_checked) {
        tr_style["background"] = "#cee9ff";
      }

      let row_pri_val = info["a_seq"];

      return (
        <tr key={info_idx} style={tr_style}
          onClick={() => { go_check_row_toggle({ row_num: info_idx }); }} >
          <td className="text-center">
            <input type="checkbox" checked={row_is_checked} value="" onChange={() => { }} />
          </td>
          {get_tr_td_tags({
            info: info,
            row_num: info_idx
          })}
        </tr>
      );
    });

    if (strFunc.is_empty(tr_tags)) {
      return (
        <tr>
          <td colSpan={myProps.xColumnArr.x_column_list_arr.length + 1}>
            <div style={{ textAlign: "center", lineHeight: "50px", color: "gray" }}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return tr_tags;
  };

  return (
    <div className="basic_list_div">
      <table>
        <colgroup>
          <col width={20}></col>
          {get_colgroup_col_tags()}
        </colgroup>
        <thead>
          <tr>
            <th>
              <input type="checkbox" onClick={on_check_all} />
            </th>
            {get_th_tags()}
          </tr>
        </thead>
        <tbody>
          {get_body_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default ListSimpleArea;