import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchReportArea(props: any) {
  let myProps = {
    list: (inData: any) => { },
    listOpt: {
      s_addon_daily_report_start_date: "",
      s_addon_daily_report_end_date: "",
    },
    is_show_etc_group: false,
    set_is_show_etc_group: (inData: any) => { },
    show_group_time: {},
    set_group_time: (inData: any) => { },
    able_time_arr_ref: null,
    list_tab: "",
    ...props
  };
  let listOpt = myProps.listOpt;
  let user = useSelector((state: any) => state.user);

  useEffect(() => {
    get_teacher_arr_by_ajax();
  }, []);

  const [teacher_info_arr, set_teacher_info_arr] = useState<any>([]);

  const get_teacher_arr_by_ajax = () => {
    let formJsonData = {
      "s_grade": ["teacher", "master", "staff"],
      "s_is_login": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_info_arr(response.data["data"]["info_arr"]);
        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value: any = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    myProps.list({ now_page: 1, [name]: value });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt = {
      now_page: 1,
      [key]: date_str
    };
    if (key == "s_addon_daily_report_start_date") {
      change_list_opt["s_addon_daily_report_end_date"] = date_str;
    }
    myProps.list(change_list_opt);
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_addon_daily_report_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      if(myProps.list_tab=="week"){
        change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -7);
      }else{
        change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
      }
    } else if (sort == "next") {
      if(myProps.list_tab=="week"){
        change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +7);
      }else{
        change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
      }
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_addon_daily_report_start_date: start_date,
      s_addon_daily_report_end_date: end_date,
    });
  };

  let select_able_time_arr = [];
  if (myProps.able_time_arr_ref) {
    for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
      let item = myProps.able_time_arr_ref.current[i];
      if (!strFunc.is_empty(item)) {
        select_able_time_arr.push({ value: item, text: item });
      }
    }
  }

  return (
    <div style={{ marginTop: 10, textAlign: "center" }}>
      <div className="text-center mt-4">
        작성일
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_addon_daily_report_start_date != "" ? new Date(listOpt.s_addon_daily_report_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_daily_report_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_addon_daily_report_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
            s_addon_daily_report_end_date: DateFunc.get_date_format(new Date(), "Y-m-d")
          });
        }}>오늘</button>

        <label className="ml-2">
          <input type="checkbox" checked={myProps.is_show_etc_group ? true : false} value="1"
            onChange={(e: any) => {
              strFunc.set_storage("daily_report_is_show_etc_group", e.target.checked ? "1" : "");
              myProps.set_is_show_etc_group(e.target.checked);
            }} />
          기타보기
        </label>

        <select className="search-input ml-1" name="s_addon_month_prescribe_writer_seq"
          value={listOpt.s_addon_month_prescribe_writer_seq}
          onChange={handleInputChange}>
          <option value="">학습처방 작성자</option>
          {teacher_info_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item["a_seq"]}>{item["a_user_name"]}</option>
            );
          })}
        </select>
        <label>
          <input type="checkbox" className="search-input ml-1" value={user.user_seq} name="s_addon_month_prescribe_writer_seq"
            checked={listOpt.s_addon_month_prescribe_writer_seq == user.user_seq}
            onChange={handleInputChange} />
          <span className="ml-1">나</span>
        </label>
      </div>
      <div className="mt-1 text-center">
        <SelectBoxBar
          valueTextArr={[
            { value: "", text: "전체" },
            { value: "now", text: "현재" },
            { value: "select", text: "선택" },
          ]}
          value={myProps.show_group_time.sort}
          on_change={(inData: any) => {
            let change_group_time = {
              ...myProps.show_group_time,
              sort: inData.value,
            };
            if(inData.value!="select"){
              strFunc.set_storage("change_group_time_sort", inData.value);
            }
            if (inData.value == "") {
              change_group_time.select_time_arr = [];
            } else if (inData.value == "now") {
              let now_date_h = parseInt(DateFunc.get_date_json(new Date()).h);
              let select_time_str = "";
              if(myProps.able_time_arr_ref){
                for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
                  let item = myProps.able_time_arr_ref.current[i];
                  if (select_time_str == "" && item) {
                    let item_h = parseInt(item.substring(0, 2));
                    if (item_h >= now_date_h) {
                      select_time_str = item;
                    }
                  }
                }
              }
              change_group_time.select_time_arr = [select_time_str];
            }
            myProps.set_group_time(change_group_time);
          }}
        ></SelectBoxBar>
        <span className="ml-2">
          {myProps.show_group_time.sort == "select" &&
            <SelectBoxBar
              valueTextArr={select_able_time_arr}
              value={myProps.show_group_time.select_time_arr.length > 0 ? myProps.show_group_time.select_time_arr[0] : ""}
              on_change={(inData: any) => {
                myProps.set_group_time({
                  ...myProps.show_group_time,
                  select_time_arr: [inData.value],
                });
              }}
            ></SelectBoxBar>
          }
        </span>
      </div>
    </div>
  );
};
export default SearchReportArea;