import { useState, useEffect,useRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  const [classSelectArr, setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr, setSchoolNameSelectArr] = useState<any>([]);
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [is_period_search, set_is_period_search] = useState(true);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const search_time_out_ref=useRef<any>(null);

  useEffect(() => {
    getClassFrontArr();
    get_teacher_by_ajax();
  }, []);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt: any = {
      now_page: 1,
      [key]: date_str
    };
    if (key == "s_start_date") {
      change_list_opt["s_end_date"] = date_str;
    }
    myProps.setListOpt({ ...myProps.listOpt, ...change_list_opt });
    myProps.list(change_list_opt);
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };

  const getClassFrontArr = () => {
    let class_list_form = {
      "s_add_school_arr": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/front_class_arr', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setClassSelectArr(response.data["data"]["class_arr"]);
          setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
        } else {

        }
      });
  };
  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const change_start_end_date_by_sort = (sort: "today" | "this_month" | "pre_month") => {
    let now_month_init_date = DateFunc.get_date_format(new Date(), "Y-m-01");
    let s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    let s_end_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    if (sort == "this_month") {
      s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      s_start_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-t");
    }
    myProps.list({
      now_page: 1,
      s_start_date: s_start_date,
      s_end_date: s_end_date,
    });
  };

  let oder_id_select_arr = [
    { "text": "등록순", "value": "a_create_date" },
    { "text": "등록역순", "value": "a_create_date DESC" },
  ];

  return (
    <div className="search_round_box_wrap">
      <div className="search_round_title_bar">
        검색
      </div>
      <div className="search_round_box">
        <div className="search_item">
          <div className="search_item_title" >이름</div>
          <div className="search_item_input_wrap" >
            <input type="text" className="search_item_input" name="s_stu_name_like"
              value={listOpt.s_stu_name_like} onChange={handleInputChange} placeholder="이름"
              autoComplete="off" />
            <span className="basic_search_item_book_mark"
              onClick={() => {
                let change_s_stu_bookmark = listOpt.s_stu_bookmark;
                if (change_s_stu_bookmark == "1") {
                  change_s_stu_bookmark = "";
                } else {
                  change_s_stu_bookmark = "1";
                }
                strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
                myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
              }}>
              {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
            </span>
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >작성자</div>
          <div className="search_item_input_wrap" >
            <select className="search_item_input_select" name="s_writer_seq" value={listOpt.s_writer_seq}
              onChange={handleInputChange}>
              <option value="">전체</option>
              {teacher_arr.map((item: any, idx: number) => {
                return (
                  <option key={idx} value={item["a_seq"]}>
                    {item["a_user_name"]}
                    {item["a_user_nickname"] &&
                      "(" + item["a_user_nickname"] + ")"
                    }
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >처방</div>
          <div className="search_item_input_wrap" >
            <select className="search_item_input_select" name="s_is_success" value={listOpt.s_is_success}
              onChange={handleInputChange}>
              <option value="">전체</option>
              <option value="1">완료</option>
              <option value="empty">미완료</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div style={{ textAlign: "center" }}>
          작성일:
          <div style={{ display: "inline-block", width: 85 }}>
            <DatePicker
              wrapperClassName="pl-1"
              selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
              onChange={(date: Date) => {
                onChangeDatePicker("s_start_date", date);
              }}
              locale={ko}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputSearch />}
              renderCustomHeader={customHeaderFunc}
            />
          </div>
          {is_period_search &&
            <span>~
              <div style={{ display: "inline-block", width: 85 }}>
                <DatePicker
                  wrapperClassName="pl-1"
                  selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("s_end_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputSearch />}
                  renderCustomHeader={customHeaderFunc}
                />
              </div>
            </span>
          }
          <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
          <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
          <button className="btn-s btn-dark ml-1"
            style={{ "background": "#0097BA" }}
            onClick={() => { change_start_end_date_by_sort("today"); }} >오늘</button>
          <button className="btn-s btn-dark ml-1"
            style={{ "background": "#0097BA" }}
            onClick={() => { change_start_end_date_by_sort("this_month"); }} >이번달</button>
          <button className="btn-s btn-dark ml-1"
            style={{ "background": "#0097BA" }}
            onClick={() => { change_start_end_date_by_sort("pre_month"); }} >지난달</button>
          <span className="ml-1">
            <label>
              <input type="checkbox" value="1" checked={is_period_search}
                onChange={(e: any) => { set_is_period_search(e.target.checked); }} />
              기간검색
            </label>
          </span>
          <select className="search-input ml-1" name="s_subject" value={listOpt.s_subject}
            onChange={handleInputChange}
            style={{ width: "70px" }}>
            <option value="">과목</option>
            {select_subject_option_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["value"]}>{item["text"]}</option>
              );
            })}
          </select>
          <select className="search-input ml-1" name="s_is_request_counsel" value={listOpt.s_is_request_counsel}
            onChange={handleInputChange}
            style={{ width: "80px" }}>
            <option value="">상담요청</option>
            <option value="1">예</option>
            <option value="empty">아니오</option>
          </select>
          <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({"now_page":"1"});}}>검색</button>
        </div>
      </div>
    </div>
  );
};

export default SearchArea;