import SubjectMainManageList from "@/pages/comp/plan/study/subject_main/common/manage";

function SubjectMainManagePopup(props:any){
   let myProps={
    listOpt:{},
    closePopup:()=>{},
    callbackData:(inData:any)=>{},
    refreshData:(inData:any)=>{},
    ...props
  };
  
  return (
  <div>
    <SubjectMainManageList
      listOpt={myProps.listOpt}
      closePopup={myProps.closePopup}
      refreshData={myProps.refreshData}
      callbackData={myProps.callbackData}
    ></SubjectMainManageList>
  </div>
  );
};
export default SubjectMainManagePopup;