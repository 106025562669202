function ManageButtonArea(props:any){
   let myProps={
    back_func:()=>{},
    back_text:"닫기",
    go_delete_study_map:()=>{},
    ...props
  };
  
  return (
  <div className="text-center mt-1">
    <button className="btn btn-red" onClick={()=>{myProps.go_delete_study_map();}}>삭제</button>
    <button className="btn btn-dark ml-1" onClick={()=>{myProps.back_func();}}>
      {myProps.back_text}
    </button>
  </div>
  );
};
export default ManageButtonArea;