import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';
import SearchSupplyArea from "./area/search_supply";
import { useSelector, useDispatch } from 'react-redux';

function SupplyMultyAdd() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_addon_class_supply: "1",
    s_supply_date_type: 'a_date',
    s_supply_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    s_supply_end_date: DateFunc.get_date_format(new Date(), "Y-m-t"),
    s_supply_state: "",
    s_supply_sort: "",
    s_grade: ["student"],
    s_addon_class_user: "1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
    // s_addon_link_user:"1",
    // s_addon_profile:"1",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    tmp_list_opt["sc"] = listAggridRef.current.getListSc();
    setListOpt(tmp_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goWriteSupply = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    //체크해보기


    let confirm_msg = selected_row.length + "명의 수납등록을 하시겠습니까?";

    if (!confirm(confirm_msg)) {
      return false;
    }
    let stu_seq_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      stu_seq_arr.push(selected_row[i]["a_seq"]);
    }
    let add_supply_data = {
      "stu_seq_arr": stu_seq_arr,
      "supply_year": listOpt.s_supply_start_date.substring(0, 4),
      "supply_month": listOpt.s_supply_start_date.substring(5, 7),
      "supply_sort": "class",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/trade/class_supply/add_by_student', add_supply_data, get_axios_data())
      .then((response) => {
        alert(response.data["msg"]);
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {

    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_class_money") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          if (params.data.class_arr[i]["class_info"]) {
            let class_info = params.data.class_arr[i]["class_info"];
            if (class_info["a_supply_money"]) {
              class_name_arr.push(strFunc.comma(class_info["a_supply_money"]));
            }
          }
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_manage") {
      render_str = (
        <div>
          <button className="btn-s btn-dark" onClick={() => { setTimeout(() => { goWriteSupply(); }, 100); }} >수납생성</button>
        </div>
      );
    } else if (key == "row_view_supply_cnt") {
      if (params.data.supply_arr) {
        render_str = params.data.supply_arr.length + " 건";
      }
    } else if (key == "row_view_supply_money") {
      if (params.data.supply_arr) {
        let supply_sum = 0;
        for (let i = 0; i < params.data.supply_arr.length; i++) {
          supply_sum += parseInt(strFunc.uncomma(params.data.supply_arr[i].a_money_sum));
        }
        render_str = strFunc.comma(supply_sum);
      }
    } else if (key == "a_stu_supply_discount") {
      if (params.data.class_arr) {
        let discount_money = 0;
        for (let i = 0; i < params.data.class_arr.length; i++) {
          if (params.data.class_arr[i]["class_info"]) {
            let classu_info = params.data.class_arr[i];
            if (classu_info["a_supply_discount"]) {
              discount_money += parseInt(strFunc.uncomma(classu_info["a_supply_discount"]));
            }
          }
        }
        if (discount_money > 0) {
          render_str = strFunc.comma(discount_money);
        }
      }
    }

    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div>
          <SearchArea
            listOpt={listOpt}
            setListOpt={setListOpt}
            xColumnArr={{ ...xColumnArr }}
            list={list}
          ></SearchArea>
          <SearchSupplyArea
            listOpt={listOpt}
            goWrite={goWriteSupply}
            list={list}
          ></SearchSupplyArea>
        </div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            // domLayout:'autoHeight',
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      </div>
    </CompLayout>
  );
}

export default SupplyMultyAdd;