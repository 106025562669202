import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import getXcolumnJson from "../../../xcolumn/list";
import strFunc from '@/lib/lyg/string';

const WriteArea = forwardRef((props: any, ref) => {
  const myProps = {
    rowData: {},
    setRowData: (inOptObj: any) => { },
    subject_main_info_arr: [],
    subject_info_arr: [],
    get_subject_main_info_by_ajax: (inData: any) => { },
    getSubjectInfoArrByAjax: (inData: any) => { },
    openManageMainSubject: () => { },
    openOneManageSubject: () => { },
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const [isUpdate, setIsUpdate] = useState(myProps.isUpdate);
  let rowData = myProps.rowData;


  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      "row_data": {},
      "isUpdate": false,
      ...inOptObj
    };
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setIsUpdate, setInitData
  }));

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    if (name == "a_subject_sort") {
      myProps.get_subject_main_info_by_ajax({
        a_subject_sort: value,
      });
      myProps.getSubjectInfoArrByAjax({
        a_subject_sort: value,
      });
    } else if (name == "a_subject_main_seq") {
      myProps.getSubjectInfoArrByAjax({
        a_subject_main_seq: value,
      });
    }

    let chage_data_json: any = {
      [name]: value
    };

    myProps.setRowData({
      ...rowData,
      ...chage_data_json
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  return (
    <div className="write_table_small">
      <table>
        <colgroup>
          <col width="20%"></col>
          <col width="30%"></col>
          <col width="20%"></col>
          <col width="30%"></col>
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={4} className="text-center">
              <select className="row-input ml-1" name="a_subject_sort" value={rowData.a_subject_sort}
                onChange={handleInputChange} style={{ width: 60 }}>
                <option value="">선택</option>
                {
                  xColumnArr.select_arr.a_subject_sort?.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
              <span className="ml-2">
                <select className="row-input" name="a_subject_main_seq" value={rowData.a_subject_main_seq} onChange={handleInputChange}
                  style={{ width: 100 }}>
                  <option value="">선택</option>
                  {
                    myProps.subject_main_info_arr.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.a_seq}>{item.a_title}</option>
                      );
                    })
                  }
                </select>
                {/* <button className="btn-m2 btn-dark ml-1" onClick={() => { myProps.openManageMainSubject(); }}>관리</button> */}
              </span>
              <span className="ml-2">
                <select className="row-input" name="a_subject_seq" value={rowData.a_subject_seq} onChange={handleInputChange}
                  style={{ width: 100 }}>
                  <option value="">선택</option>
                  {
                    myProps.subject_info_arr.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.a_seq}>{item.a_title}</option>
                      );
                    })
                  }
                </select>
                {/* <button className="btn-m2 btn-dark ml-1" onClick={() => { myProps.openOneManageSubject(); }}>관리</button> */}
              </span>
              {/* <span className="ml-2">
                <label>
                  <input type="checkbox" name="a_is_progress" value="1"
                    checked={rowData.a_is_progress == "1"} onChange={handleInputChange} />
                  <span className="ml-1">
                    진도
                  </span>
                </label>
              </span> */}
              {/* <span className="ml-2">
                <label>
                  <input type="checkbox" name="a_is_mapping" value="1"
                    checked={rowData.a_is_mapping == "1"} onChange={handleInputChange} />
                  <span className="ml-1">
                    교과맵핑
                  </span>
                </label>
              </span> */}
              <span className="ml-2">
                <select className="row-input" name="a_open_group" value={rowData.a_open_group}
                  onChange={handleInputChange} style={{ width: 80 }}>
                  <option value="">비공개</option>
                  <option value="all">그룹공개</option>
                </select>
              </span>
            </td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan={3}>
              <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
            </td>
          </tr>
          {!strFunc.is_empty(rowData.a_answer_link_url) &&
            <tr>
              <th>답안지주소</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_answer_link_url" value={rowData.a_answer_link_url}
                  onChange={handleInputChange} placeholder="답안지주소" style={{ width: "70%" }} />
                {rowData.a_answer_link_url != "" &&
                  <span>
                    <button className="btn btn-dark ml-1 mt-1" onClick={() => { window.open(rowData.a_answer_link_url); }} >바로가기</button>
                  </span>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
});

export default WriteArea;
