import strFunc from "@/lib/lyg/string";
import Style from "../../css/style.module.css";
import { useRef } from 'react';
import TodoArrHoverArea from "./todo_arr_hover";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";

function TdTodoSumArea(props:any){
  let myProps={
    todo_info_arr:[],
    openTodoDaySort:(inData:any)=>{},
    select_stu_name:"",
    select_stu_seq:"",
    select_date:"",
    ...props
  };

  const hover_div_ref=useRef<any>(null);

  const get_td_sot_data_arr=()=>{
    let td_sort_data_json:any={
      "study":{
        "sort":"study",
        "title":"학습",
        "percent":0,
        "count":0,
        "per_arr":[],
        "todo_info_arr":[],
      },
      "homework":{
        "sort":"homework",
        "title":"숙제",
        "percent":0,
        "count":0,
        "per_arr":[],
        "todo_info_arr":[],
      },
    };

    let todo_info_arr_len=myProps.todo_info_arr.length;
    for(let i=0;i<todo_info_arr_len;i++){
      let todo_info=myProps.todo_info_arr[i];
      for(let key in td_sort_data_json){
        if(todo_info["a_sort"]==key){
          td_sort_data_json[key]["count"]++;
          let row_per:any=TodoStateFunc.get_percent_by_todo_info(todo_info);
          if(todo_info["a_is_success"]=="1"){
            row_per=100;
          }
          td_sort_data_json[key]["per_arr"].push(row_per);
          todo_info["percent"]=row_per;
          td_sort_data_json[key]["todo_info_arr"].push(todo_info);
        }
      }
    }
    //평균얻기
    for(let key in td_sort_data_json){
      let row_sum_per:any=0;
      let per_arr_len:any=td_sort_data_json[key]["per_arr"].length;
      for(let i=0;i<per_arr_len;i++){
        row_sum_per+=td_sort_data_json[key]["per_arr"][i];
      }
      if(row_sum_per!=0&&per_arr_len!=0){
        td_sort_data_json[key]["percent"]=Math.floor(row_sum_per/per_arr_len);
      }
    }

    return td_sort_data_json;
  };

  let td_sort_data_json=get_td_sot_data_arr();
  let td_sort_data_arr:any=[];
  for(let key in td_sort_data_json){
    if(td_sort_data_json[key]["count"]>0){
      td_sort_data_arr.push(td_sort_data_json[key]);
    }
  }

  const get_color_style_of_sum_tag=(percent:number)=>{
    let con_wrap_style:any={};
    let con_bar_style:any={};
    let per_span_style:any={};
    if(percent==0){
      con_wrap_style["background"]="#FAD5CC";
      con_bar_style["background"]="#F48282";
      per_span_style["color"]="#E18685";
    }else if(percent>0){
      con_wrap_style["background"]="#FAF1E0";
      con_bar_style["background"]="#EBBD70";
      per_span_style["color"]="#E0BA7B";
    }
    if(percent>=100){
      con_wrap_style["background"]="#EFF3D0";
      con_bar_style["background"]="#B7C528";
      per_span_style["color"]="#B0BA3F";
    }
    return {
      wrap:con_wrap_style,
      bar:con_bar_style,
      per:per_span_style
    };
  };

  const get_todo_detail_tags=(row_todo_info_arr:[])=>{
    let todo_detail_tags=row_todo_info_arr.map((todo_info:any,idx:number)=>{
      let color_style_json=get_color_style_of_sum_tag(todo_info.percent);
      return (
        <div key={idx} className={Style.todo_sum_con_wrap} style={{background:"#fff"}}>
          <span>
            <input type="text" value={todo_info.a_title} style={{border:0,width:"75%"}}/>
          </span>
          <span className={Style.todo_sum_per_span} style={color_style_json.per}>
            {todo_info.percent}%
          </span>
        </div>
      );
    });
    return todo_detail_tags;
  };

  const get_todo_sort_sum_tags=(is_detail:boolean)=>{
    let todo_sort_sum_tags=td_sort_data_arr.map((item:any,idx:number)=>{
      let color_style_json=get_color_style_of_sum_tag(item.percent);

      let first_title=item.todo_info_arr[0].a_title;

      //detail
      let todo_detail_tags:any="";
      if(is_detail){
        first_title=item.title;
        todo_detail_tags=get_todo_detail_tags(item.todo_info_arr);
      }

      return (
        <div key={idx}>
          {is_detail==false&&
          <div className={Style.todo_sum_title}>
            {item.title}
          </div>
          }
          <div className={Style.todo_sum_con_wrap} style={color_style_json.wrap}>
            <div className={Style.todo_sum_con_bar} style={color_style_json.bar}></div>
            <span className={Style.todo_sum_con_title} >
              <input type="text" value={first_title} style={{background:"transparent",width:"65%",border:0}} />
            </span>
            <span className={Style.todo_sum_per_span} style={color_style_json.per}>
              <span style={{color:"#777"}}>{" ("+item.count+")"}</span>
              <span className="ml-1">{item.percent}%</span>
            </span>
          </div>
          {is_detail&&
          <>
            {todo_detail_tags}
          </>
          }
        </div>
      );
    });

    return todo_sort_sum_tags;
  };

  return (
    <div className={Style.todo_sum_area} 
      onClick={(e:any)=>{
        myProps.openTodoDaySort({
          select_stu_name:myProps.select_stu_name,
          select_stu_seq:myProps.select_stu_seq,
          select_date:myProps.select_date,
        });
      }}
      onMouseEnter={(e:any)=>{
        if(hover_div_ref.current){
          hover_div_ref.current.style.display="block";
        }
      }}
      onMouseMove={(e:any)=>{
        var win_w=(window.innerWidth || document.documentElement.clientWidth);
        var win_h=(window.innerHeight || document.documentElement.clientHeight);
        let plus_x=15;
        let plus_y=0;
        if(e.pageX>win_w-200){
          plus_x=-150-5;
        }
        if(e.pageY>win_h-250){
          plus_y=-200;
        }
        if(hover_div_ref.current){
          // hover_div_ref.current.style.left=(e.pageX+plus_x)+"px";
          // hover_div_ref.current.style.top=(e.pageY+plus_y)+"px";
          hover_div_ref.current.style.left=(e.clientX+plus_x)+"px";
          hover_div_ref.current.style.top=(e.clientY+plus_y)+"px";
        }
      }}
      onMouseLeave={(e:any)=>{
        if(hover_div_ref.current){
          hover_div_ref.current.style.display="none";
        }
      }}
    >
      {get_todo_sort_sum_tags(false)}
      <div className={Style.todo_sum_area_hover} ref={hover_div_ref}>
        <TodoArrHoverArea 
          td_sort_data_arr={td_sort_data_arr}
        >
          {get_todo_sort_sum_tags(true)}
        </TodoArrHoverArea>
      </div>
    </div>
  );
};
export default TdTodoSumArea;