import StudyTitleMapListPage from "@/pages/comp/plan/study/study_map/title_map";
import StuStudyMapListPage from "@/pages/comp/plan/study/study_map/stu_map";
import StudyMapHistoryPage from "@/pages/comp/plan/study/study_map/history";

const routerArr:object[] = [
  {
    path: "/comp/study/study_map/title_map",
    element: <StudyTitleMapListPage></StudyTitleMapListPage>,
  },
  {
    path: "/comp/study/study_map/stu_map",
    element: <StuStudyMapListPage></StuStudyMapListPage>,
  },
  {
    path: "/comp/study/study_map/history",
    element: <StudyMapHistoryPage></StudyMapHistoryPage>,
  },
];

export default routerArr;