import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import CalendarTable from "./area/calendar_table";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewStuLayerPopup from "@/pages/comp/basic/student/popup/view_layer";
import WritePopupContent from "@/pages/comp/edu/month_prescribe/popup/write";
import WriteMultyReserveMonthPrescribePopup from "@/pages/comp/edu/month_prescribe/popup/write_multy_reserve";

const CalendarArea = forwardRef((props: any, ref) => {
  const myProps = {
    onSearchDateChange: (inData: any) => { },
    go_search_user_list: (inData: any) => { },
    s_lecture_seq: [],
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const CalendarTableRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "order_id": "a_reserve_date, a_stu_name",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_date_type": "a_reserve_date",
    "s_date_str": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_week_start_date": "",
    "s_week_end_date": "",
    "s_stu_seq": "",
    "s_stu_name_like": "",
    "s_writer_seq": "",
    "s_is_success": "",
    "s_subject": "",
    "s_is_request_counsel":"",
    "s_addon_user": "1",
    "s_addon_comment": "1",
    "s_addon_counsel": "1",
  });

  const [mprescribe_arr, set_mprescribe_arr] = useState([]);
  const [teacher_info_arr, set_teacher_info_arr] = useState<any>([]);
  const [lecture_user_time_arr, set_lecture_user_time_arr] = useState<any>([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "stu_view",//view,month_prescribe_reserve
    isUpdate: false,
    rowData: {},
    select_date: "",
    stu_seq: "",
    stu_name: "",
    has_header: true,
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
      tab_id: "monthly_prescribe",
      is_first_tab_scroll: true,
      counsel_s_select_date: "",
      month_prescribe_s_subject:"",
    },
    title: "팝업",
    width: "800px",
    height: "100%",
  });

  const setInitData = (inOptObj: any) => {
    let optObj = {
      ...inOptObj
    };
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, list
  }));

  useEffect(() => {
    list({});
    get_teacher_arr_by_ajax();
  }, []);
  useEffect(() => {
    get_lecture_user_time_arr();
  }, [myProps.s_lecture_seq]);

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    if (!strFunc.is_empty(list_form_json.s_date_str)) {
      let pre_month_obj = DateFunc.get_change_date(new Date(list_form_json.s_date_str), 'month', -1);
      let after_month_obj = DateFunc.get_change_date(new Date(list_form_json.s_date_str), 'month', +1);
      list_form_json.s_start_date = DateFunc.get_date_format(pre_month_obj, "Y-m-01");
      list_form_json.s_end_date = DateFunc.get_date_format(after_month_obj, "Y-m-t");
      if (list_form_json.s_week_start_date != "") {
        list_form_json.s_start_date = list_form_json.s_week_start_date;
        list_form_json.s_end_date = list_form_json.s_week_end_date;
      }
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_mprescribe_arr(response.data["data"]["info_arr"]);
          if(CalendarTableRef.current){
            CalendarTableRef.current.set_mprescribe_arr(response.data["data"]["info_arr"]);
            CalendarTableRef.current.set_stu_info_arr(list_form_json.s_stu_info_arr);
          }
        } else {

        }
      });
  };

  const get_teacher_arr_by_ajax = () => {
    let formJsonData = {
      "s_grade": ["teacher", "master", "staff"],
      "s_is_login": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_info_arr(response.data["data"]["info_arr"]);
        }
      });
  };

  const get_lecture_user_time_arr=()=>{
    let formJsonData = {
      "s_stu_seq":listOpt.s_stu_seq,
      "s_lecture_seq": myProps.s_lecture_seq,
      "order_id":"a_start_h, a_start_m",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_user_time/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_user_time_arr(response.data["data"]["info_arr"]);
        }
      });
  };

  const open_write_prescribe_popup = (inData: any) => {
    let opt_obj = {
      select_date: "",
      isUpdate: false,
      rowData: {},
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "write",
      isUpdate: opt_obj["isUpdate"],
      select_date: opt_obj["select_date"],
      rowData: opt_obj["rowData"],
    });
  };

  const open_reserve_prescribe_popup = (inData: any) => {
    let opt_obj = {
      select_date: "",
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "처방예약",
      "sort": "month_prescribe_reserve",
      select_date: opt_obj["select_date"],
    });
  };

  const open_stu_layer_popup = (inData: any) => {
    let opt_obj = {
      mprescribe_info: null,
      ...inData
    };
    let mprescribe_info: any = opt_obj["mprescribe_info"];
    if (mprescribe_info == null) {
      alert("선택이 없습니다.");
      return false;
    }
    if (strFunc.is_empty(mprescribe_info["a_seq"])) {
      alert("키값이 없습니다.");
      return false;
    }
    if (strFunc.is_empty(mprescribe_info["stu_info"])) {
      alert("학생정보가 없습니다.");
      return false;
    }
    let select_idx = 0;
    let stu_info_arr: any = [mprescribe_info["stu_info"]];
    let s_row_select_date = mprescribe_info["a_reserve_date"];
    let month_prescribe_s_subject=mprescribe_info["a_subject_name"];

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: select_idx,
        info_arr: stu_info_arr,
        default_write_info: {},
        tab_id: "monthly_prescribe",
        is_first_tab_scroll: true,
        counsel_s_select_date: s_row_select_date,
        month_prescribe_s_subject:month_prescribe_s_subject,
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });
  };

  return (
    <div>
      <CalendarTable
        ref={CalendarTableRef}
        listOpt={listOpt}
        select_date={listOpt.s_date_str}
        list={list}
        teacher_info_arr={teacher_info_arr}
        lecture_user_time_arr={lecture_user_time_arr}
        onSearchDateChange={(inData: any) => {
          list({ s_date_str: inData, s_week_start_date: "" });
          myProps.onSearchDateChange(inData);
        }}
        go_search_user_list={myProps.go_search_user_list}
        open_stu_layer_popup={open_stu_layer_popup}
        open_write_prescribe_popup={open_write_prescribe_popup}
        open_reserve_prescribe_popup={open_reserve_prescribe_popup}
      ></CalendarTable>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={(inData: any) => { list({}); myProps.go_search_user_list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
          {popupData.sort == "write" &&
            <WritePopupContent
              stu_seq={popupData.stu_seq}
              stu_name={popupData.stu_name}
              s_stu_seq_arr={listOpt.s_stu_seq}
              isUpdate={popupData.isUpdate}
              rowData={popupData.rowData}
              select_date={popupData.select_date}
              callback={(inData: any) => { list(inData); myProps.go_search_user_list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WritePopupContent>
          }
          {popupData.sort == "month_prescribe_reserve" &&
            <WriteMultyReserveMonthPrescribePopup
              start_date={popupData.select_date}
              end_date={popupData.select_date}
              callback={(inData: any) => { list(inData); myProps.go_search_user_list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteMultyReserveMonthPrescribePopup>
          }
        </LayerPopup>
      }
    </div>
  );
});

export default CalendarArea;
