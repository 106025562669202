import strFunc from "@/lib/lyg/string";
import StuNameTd from "./area/stu/stu_name_td";
import AttendTd from "./area/stu/attend_td";
import TodoHomeWorkTd from "./area/stu/todo_home_work";
import TodoStudyAmountTd from "./area/stu/todo_study_amount";
import DailyLastTds from "./area/daily/daily_last/daily_last_tds";
import DailyWeekTds from "./area/daily/daily_week";
import DailyAmountTd from "./area/daily/daily_amount";

function CardBoxArea(props: any) {
  let myProps = {
    row_num: 0,
    first_td_tag: "",
    lecture_info: null,
    info: {},
    set_info: (info: any) => { },
    select_date: "",
    pre_date_str: "",
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    is_print_mode: false,
    Style: {},
    show_column_cnt: 8,
    show_week_arr: [],
    lecture_time_str: "",
    select_stu_grade_arr: [],
    select_homework_state_arr:[],
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    openTodoDaySort: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let daily_report_arr = [];
  if (info.daily_report_arr) {
    daily_report_arr = info.daily_report_arr;
  }

  let lecture_info = null;
  let lecture_seq = "";
  if (!strFunc.is_empty(myProps.lecture_info)) {
    lecture_info = myProps.lecture_info;
    lecture_seq = lecture_info["a_seq"];
  }

  let last_daily_info = {
    a_stu_seq: "",
    a_writer_seq: "",
    a_homework_state: "",
    a_lecture_seq: "",
    a_textbook: "",
    a_subtextbook: "",
    a_homework: "",
  };
  if (info.last_daily_report_arr && info.last_daily_report_arr.length > 0 && lecture_info != null) {
    for (let i = 0; i < info.last_daily_report_arr.length; i++) {
      let row_daily_info = info.last_daily_report_arr[i];
      if (row_daily_info["a_stu_seq"] == info["a_seq"]
        //&& row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
        && row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]) {
        last_daily_info = row_daily_info;
      }
    }
  }

  let is_checked_row = false;
  if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) != -1) {
    is_checked_row = true;
  }

  const on_checkbox_change = (e: any) => {
    if (e.target.checked) {
      let change_check_row_val_arr = myProps.check_row_val_arr;
      change_check_row_val_arr.push(info["a_seq"]);
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    } else {
      let change_check_row_val_arr = [];
      for (let i = 0; i < myProps.check_row_val_arr.length; i++) {
        if (myProps.check_row_val_arr[i] != info["a_seq"]) {
          change_check_row_val_arr.push(myProps.check_row_val_arr[i]);
        }
      }
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    }
  };

  return (
    <>
      <tr className={Style.card_box_first_tr}>
        {myProps.row_num == 0 && myProps.first_td_tag}
        {myProps.is_print_mode==false &&
          <td rowSpan={2}>
            <input
              type="checkbox"
              onChange={on_checkbox_change}
              value={""}
              checked={is_checked_row}
            />
          </td>
        }
        <StuNameTd info={info}
          select_stu_grade_arr={myProps.select_stu_grade_arr}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
        ></StuNameTd>
        <TodoHomeWorkTd info={info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          sort="study"></TodoHomeWorkTd>
        {/* 3tds */}
        <DailyLastTds
          info={info}
          lecture_info={lecture_info}
          last_daily_info={last_daily_info}
        ></DailyLastTds>
        {/* 5tds */}
        <DailyWeekTds
          info={info}
          lecture_info={lecture_info}
          select_date={myProps.select_date}
          show_week_arr={myProps.show_week_arr}
          lecture_time_str={myProps.lecture_time_str}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
        ></DailyWeekTds>
        <DailyAmountTd
          info={info}
          lecture_info={lecture_info}
          last_daily_info={last_daily_info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          lecture_time_str={myProps.lecture_time_str}
          is_sub={false}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
        ></DailyAmountTd>
      </tr>
      <tr>
        <AttendTd
          info={info} openAttendPopup={myProps.openAttendPopup}
          deleteAttend={myProps.deleteAttend}></AttendTd>
        <TodoHomeWorkTd info={info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          sort="pre_homework"></TodoHomeWorkTd>
        {/* 5tds */}
        <DailyWeekTds
          info={info}
          lecture_info={lecture_info}
          last_daily_info={last_daily_info}
          select_date={myProps.select_date}
          show_week_arr={myProps.show_week_arr}
          lecture_time_str={myProps.lecture_time_str}
          td_sort={"subtextbook"}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
        ></DailyWeekTds>
        <DailyAmountTd
          info={info}
          lecture_info={lecture_info}
          last_daily_info={last_daily_info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          lecture_time_str={myProps.lecture_time_str}
          is_sub={true}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
        ></DailyAmountTd>
      </tr>
      <tr>
        {/* <td colSpan={myProps.show_column_cnt-1}>추가메모</td> */}
      </tr>
    </>
  );
};
export default CardBoxArea;