import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import MapDetailWritePopup from "@/pages/comp/plan/study/study_map/title_map/popup/detail_write";
import strFunc from '@/lib/lyg/string';
import { useState } from 'react';
import StudyRowArea from "./study";

function SectionArea(props: any) {
  let myProps = {
    Style: {},
    section_idx: 0,
    section_info: {},
    study_arr: [],
    map_arr: [],
    map_det_arr: [],
    get_study_title_info_by_ajax: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let section_idx = myProps.section_idx;
  let section_info = myProps.section_info;
  let study_arr = myProps.study_arr;
  let map_arr = myProps.map_arr;
  let map_det_arr = myProps.map_det_arr;

  let row_study_arr: any = [];
  for (let i = 0; i < study_arr.length; i++) {
    if (study_arr[i].a_section_seq == section_info.a_seq) {
      row_study_arr.push(study_arr[i]);
    }
  }

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "write_map_detail",//write_map_detail
    stu_info: {},
    map_detail_info: null,
    section_study_name: {
      section_name: "",
      unit_name: "",
    },
    has_header: true,
    title: "상세보기",
    width: "800px",
    height: "100%",
  });

  const open_map_detail_write_popup = (inData:any) => {
    let opt_obj={
      map_detail_info:{},
      section_study_name:{},
      ...inData
    };
    let map_detail_info=opt_obj.map_detail_info;
    let section_study_name=opt_obj.section_study_name;
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "상세보기",
      "sort": "write_map_detail",
      map_detail_info:map_detail_info,
      section_study_name:section_study_name,
      width: "800px",
      height: "99%",
    });
  };

  const get_study_row_tags = () => {
    let study_row_tags = row_study_arr.map((item: any, idx: number) => {
      return (
        <StudyRowArea
          key={idx}
          Style={Style}
          study_idx={idx}
          study_info={item}
          section_info={section_info}
          map_arr={map_arr}
          map_det_arr={map_det_arr}
          open_map_detail_write_popup={open_map_detail_write_popup}
        ></StudyRowArea>
      );
    });

    return study_row_tags;
  };

  return (
    <div>
      <div className={Style.section_title_wrap}>
        <span className={Style.section_title_span}>
          {section_idx + 1}. {section_info.a_title}
        </span>
      </div>
      <div className={Style.study_list_wrap} >
        {get_study_row_tags()}
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "write_map_detail" &&
            <MapDetailWritePopup
              stu_info={popupData.stu_info}
              map_detail_info={popupData.map_detail_info}
              section_study_name={popupData.section_study_name}
              callback_refresh_data={() => { myProps.get_study_title_info_by_ajax({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></MapDetailWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default SectionArea;