import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import noimg_ico from "@/img/ico/noimg.png";
import popupFunc from "@/lib/lyg/popup";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DateFunc from '@/lib/lyg/date_func';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";

function CounselListArea(props: any) {
  let myProps = {
    Style: {},
    counsel_todo_arr: [],
    now_date_time: "",
    select_date_time: "",
    check_idx_arr: [],
    update_content_by_ajax: (inData: any) => { },
    change_state_by_ajax: (inData: any) => { },
    go_del_counsel_row: (inData: any) => { },
    set_counsel_todo_arr: (inData: any) => { },
    list: (inData: any) => { },
    set_check_idx_arr: (inData: any) => { },
    callback: (inData: any) => { },
    is_show_content_arr: [],
    set_is_show_content_arr: (inData: any) => { },
    show_write_diary_page: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let counsel_todo_arr = myProps.counsel_todo_arr;
  let now_date_time = myProps.now_date_time;
  let select_date_time = myProps.select_date_time;
  let check_idx_arr = myProps.check_idx_arr;
  let is_show_content_arr = myProps.is_show_content_arr;
  let stu_grade_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let stu_grade2_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  
  let select_arr_a_kind: any = XcolumnFunc.getSortByTableKey({ 'table': 'counsel_todo', 'key': 'a_kind' });
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  const downloadFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    
  }, []);

  const all_check = (is_check: boolean) => {
    let change_check_idx_arr: any = [];
    if (is_check) {
      for (let i = 0; i < counsel_todo_arr.length; i++) {
        change_check_idx_arr.push(i);
      }
    }
    myProps.set_check_idx_arr(change_check_idx_arr);
  };

  const on_change_row_check = (row_num: number, is_check: boolean) => {
    if (is_check) {
      //없으면 더하기
      if (strFunc.str_in_array(row_num, check_idx_arr) == -1) {
        myProps.set_check_idx_arr([
          ...check_idx_arr,
          ...[row_num]
        ]);
      }
    } else {
      //빼기
      let new_check_idx_arr: any = [];
      for (let i = 0; i < check_idx_arr.length; i++) {
        if (check_idx_arr[i] != row_num) {
          new_check_idx_arr.push(check_idx_arr[i]);
        }
      }
      myProps.set_check_idx_arr(new_check_idx_arr);
    }
  };

  const get_check_row_info_arr = () => {
    let check_info_arr: any = [];
    for (let i = 0; i < counsel_todo_arr.length; i++) {
      let info = counsel_todo_arr[i];
      if (strFunc.str_in_array(i, check_idx_arr) != -1) {
        check_info_arr.push(info);
      }
    }
    return check_info_arr;
  };

  const all_display = (is_check: boolean) => {
    let change_idx_arr: any = [];
    if (is_check) {
      for (let i = 0; i < counsel_todo_arr.length; i++) {
        change_idx_arr.push(i);
      }
    }
    myProps.set_is_show_content_arr(change_idx_arr);
  };

  const on_change_row_display = (row_num: number, is_check: boolean) => {
    if (is_check) {
      //없으면 더하기
      if (strFunc.str_in_array(row_num, is_show_content_arr) == -1) {
        myProps.set_is_show_content_arr([
          ...is_show_content_arr,
          ...[row_num]
        ]);
      }
    } else {
      //빼기
      let new_idx_arr: any = [];
      for (let i = 0; i < is_show_content_arr.length; i++) {
        if (is_show_content_arr[i] != row_num) {
          new_idx_arr.push(is_show_content_arr[i]);
        }
      }
      myProps.set_is_show_content_arr(new_idx_arr);
    }
  };

  const on_change_row_input = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      key: "",
      value: "",
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);
    let key = opt_obj["key"];
    let value = opt_obj["value"];

    let change_counsel_todo_arr: any = [...counsel_todo_arr];
    change_counsel_todo_arr[row_num][key] = value;
    if(key=="a_reserve_date"){
      change_counsel_todo_arr[row_num]["a_counsel_date"] = value;
    }

    myProps.set_counsel_todo_arr(change_counsel_todo_arr);
  };
  const on_change_row_date_picker = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      key: "",
      value: "",
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let date_str = DateFunc.get_date_format(value, "Y-m-d");

    let change_counsel_todo_arr: any = [...counsel_todo_arr];
    change_counsel_todo_arr[row_num][key] = date_str;
    if(key=="a_reserve_date"){
      change_counsel_todo_arr[row_num]["a_counsel_date"] = date_str;
    }

    myProps.set_counsel_todo_arr(change_counsel_todo_arr);
  };

  const goDownLoad = (file_info: any) => {
    let pri_val = file_info["a_ymd"] + "," + file_info["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = file_info["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };
  const goDeleteFile = (file_info_arr: any) => {
    let selected_row = file_info_arr;
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let del_row_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let file_info = selected_row[i];
      del_row_arr.push({
        "a_ymd": file_info["a_ymd"],
        "a_seq": file_info["a_seq"],
      });
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: del_row_arr,
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제 되었습니다.");
        myProps.list({});
        myProps.set_check_idx_arr([]);
        myProps.callback();
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const get_file_tags_by_file_arr = (file_arr: []) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let icon_img = noimg_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} className={Style.row_content_file_row}>
          {file_info.is_image == "1" &&
            <a style={{ cursor: "pointer" }} onClick={() => { popupFunc.openPopup({ url: file_info.img_url }); }} >
              <img src={icon_img} className={Style.row_content_file_row_thum} />
            </a>
          }
          <p>
            {file_info.a_oriname}
            <button className="btn-s btn-gray ml-1" onClick={() => { goDownLoad(file_info); }}>다운</button>
            <button className="btn-s btn-red ml-1" onClick={() => { goDeleteFile([file_info]); }} >X</button>
          </p>
        </div>
      );
    });

    return file_tags;
  };

  const get_counsel_todo_tags = () => {
    let counsel_todo_tags: any = counsel_todo_arr.map((item: any, idx: number) => {
      let row_counsel_pri_val = item["a_ymd"] + "," + item["a_seq"];
      //학년
      let stu_name_str=item.a_stu_name;
      let orin_stu_grade="";
      let stu_grade_str = "";
      let stu_grade_str2="";
      let stu_school_str = "";
      let stu_school_str2 = "";
      if (item.stu_info) {
        stu_name_str=item.stu_info["a_user_name"];
        if(item.stu_info["a_stu_total_code"]){
          stu_name_str+=" "+item.stu_info["a_stu_total_code"];
        }
        stu_grade_str = item.stu_info.a_stu_grade;
        orin_stu_grade= item.stu_info.a_stu_grade;
        stu_school_str = item.stu_info.a_school_name;
        stu_school_str2=stu_school_str.substring(0,2);
      }
      let select_stu_grade_opt_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", stu_grade_str, stu_grade_select_option_arr);
      if (select_stu_grade_opt_arr.length > 0) {
        stu_grade_str = select_stu_grade_opt_arr[0]["text"];
      }
      let select_stu_grade_opt_arr2 = strFunc.get_obj_by_key_val_at_obj_arr("value", orin_stu_grade, stu_grade2_select_option_arr);
      if (select_stu_grade_opt_arr2.length > 0) {
        stu_grade_str2 = select_stu_grade_opt_arr2[0]["text"];
      }

      let state_text = "준비";
      let state_back_color = "#eab358";
      if (select_date_time < now_date_time) {
        state_text = "미완료";
        state_back_color = "#db6c58";
      }
      if (item.a_state == "complete") {
        state_text = "완료";
        state_back_color = "#A2B854";
      }

      let is_row_checked = false;
      if (strFunc.str_in_array(idx, check_idx_arr) != -1) {
        is_row_checked = true;
      }

      let input_file_names = [];
      if (item.file_obj) {
        for (let i = 0; i < item.file_obj.files.length; i++) {
          if (item.file_obj.files[i].name) {
            input_file_names.push(item.file_obj.files[i].name);
          }
        }
      }

      let file_tags: any = "";
      if (item.file_arr) {
        file_tags = get_file_tags_by_file_arr(item.file_arr);
      }

      let is_row_display_detail = false;
      if (strFunc.str_in_array(idx, is_show_content_arr) != -1) {
        is_row_display_detail = true;
      }

      return (
        <div className={Style.row} key={idx}>
          <div className={Style.row_stu_name}>
            <div className={Style.row_stu_name_left_box}>
              <input type="checkbox" className={Style.row_chk_box} checked={is_row_checked}
                onChange={(e: any) => { on_change_row_check(idx, e.target.checked); }} />
              <span className={Style.row_state_span} style={{ background: state_back_color }}
                onClick={() => { myProps.change_state_by_ajax(item.a_state == "complete" ? "ready" : "complete", [item]); }} >
                {state_text}
              </span>
              <span onClick={() => { on_change_row_display(idx, !is_row_display_detail); }} style={{ cursor: "pointer" }}>
                {stu_name_str} {stu_school_str2} {stu_grade_str2}
              </span>
            </div>
            <span className={Style.row_manage_btn_span}
              onClick={() => { myProps.show_write_diary_page(row_counsel_pri_val); }}>
              학습일지
              {(item["a_par_id"] == "study_diary" && item["a_par_seq"] != "") &&
                <span>(1)</span>
              }
            </span>
            <span style={{color:"#00968b"}}>{item.a_kind}</span>
            <div style={{ flexGrow: 1 }}>
              <input type="text" style={{width:"100%",border:0}} value={item.a_content} readOnly />
            </div>
            <span style={{ whiteSpace: "nowrap", color: "#777" }}>
              {strFunc.cut_str(item.a_counseler, 3, "")}
            </span>
            <span className={Style.row_manage_btn_span + " ml-1"}
              onClick={() => { myProps.go_del_counsel_row([item]); }}
              style={{ background: "red" }} >삭제</span>
          </div>
          {is_row_display_detail &&
            <div className={Style.row_content_wrap}>
              <p>
                <span className={Style.row_grade_span}>
                  ({stu_grade_str})
                </span>
                <span className={Style.row_grade_span + " ml-1"}>
                  {stu_school_str ? "(" + stu_school_str + ")" : ""}
                </span>
                <span className={Style.row_a_counseler_span}>
                  상담자: {item.a_counseler}
                </span>
              </p>
              <div className={Style.row_content_box} >
                <div>
                  예정일:
                  <span>
                    <div className="ml-1" style={{ display: "inline-block", width: 90, border: "1px solid #ddd" }}>
                      <DatePicker
                        selected={item.a_reserve_date != "" ? new Date(item.a_reserve_date) : null}
                        onChange={(date: Date) => {
                          on_change_row_date_picker({
                            row_num: idx,
                            key: "a_reserve_date",
                            value: date
                          });
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  </span>
                  <span className="ml-1">
                    예정시간:
                    <TimeInputComponent
                      name={"a_reserve_time"}
                      value={item.a_reserve_time}
                      on_change={(inData:any)=>{
                        on_change_row_input({
                          row_num: idx,
                          key: inData.name,
                          value: inData.value
                        });
                      }}
                    ></TimeInputComponent>
                  </span>
                </div>
                <div className="mt-1">
                  <span>
                    상담일:
                    <div className="ml-1" style={{ display: "inline-block", width: 90, border: "1px solid #ddd" }}>
                      <DatePicker
                        selected={item.a_counsel_date != "" ? new Date(item.a_counsel_date) : null}
                        onChange={(date: Date) => {
                          on_change_row_date_picker({
                            row_num: idx,
                            key: "a_counsel_date",
                            value: date
                          });
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  </span>
                </div>
                <div className="mt-2">
                  <table style={{ width: "100%" }}>
                    <colgroup>
                      <col width={"55px"}></col>
                      <col width={"*"}></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>구분</th>
                        <td>
                          <SelectBoxBar
                            valueTextArr={select_arr_a_kind}
                            value={item.a_kind}
                            name={"a_kind"}
                            on_change={(inData: any) => {
                              on_change_row_input({
                                row_num: idx,
                                key: inData.name,
                                value: inData.value
                              });
                            }}
                          ></SelectBoxBar>
                        </td>
                      </tr>
                      <tr>
                        <th>상담내용</th>
                        <td>
                          <TextAreaComponent
                            class_name={Style.row_content_text_area}
                            name={"a_content"}
                            value={item.a_content}
                            onChange={(e: any) => {
                              on_change_row_input({
                                row_num: idx,
                                key: e.target.name,
                                value: e.target.value
                              });
                            }}
                            placeholder={"상담내용"}
                          ></TextAreaComponent>
                        </td>
                      </tr>
                      <tr>
                        <th>피드백</th>
                        <td>
                          <TextAreaComponent
                            class_name={Style.row_content_text_area}
                            name={"a_feedback"}
                            value={item.a_feedback}
                            onChange={(e: any) => {
                              on_change_row_input({
                                row_num: idx,
                                key: e.target.name,
                                value: e.target.value
                              });
                            }}
                            placeholder={"피드백"}
                          ></TextAreaComponent>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          평가
                        </th>
                        <td>
                          <SelectBoxBar
                            valueTextArr={select_arr_eval_score}
                            value={item.a_eval_score}
                            name={"a_eval_score"}
                            on_change={(inData: any) => {
                              on_change_row_input({
                                row_num: idx,
                                key: inData.name,
                                value: inData.value
                              });
                            }}
                          ></SelectBoxBar>
                        </td>
                      </tr>
                      <tr>
                        <th>참고내용</th>
                        <td>
                          <TextAreaComponent
                            class_name={Style.row_content_text_area + " mt-1"}
                            name={"a_reference"}
                            value={item.a_reference}
                            onChange={(e: any) => {
                              on_change_row_input({
                                row_num: idx,
                                key: e.target.name,
                                value: e.target.value
                              });
                            }}
                            placeholder={"참고내용"}
                          ></TextAreaComponent>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {file_tags}
              <div className={Style.row_content_btn_wrap}>
                <form method="post" >
                  <span className={Style.row_manage_btn_span} onClick={(e: any) => {
                    e.target.parentElement.querySelector(".input_file")?.click();
                  }} title={input_file_names.join(",")} >
                    사진
                    {input_file_names.length > 0 && <span>({input_file_names.length})</span>}
                  </span>
                  <input type="file" className="input_file" name="input_file[]" hidden multiple
                    onChange={(e: any) => {
                      let tmp_counsel_todo_arr: any = [...counsel_todo_arr];
                      tmp_counsel_todo_arr[idx]["file_obj"] = e.target;
                      myProps.set_counsel_todo_arr(tmp_counsel_todo_arr);
                    }} accept="image/*" />
                  <span className={Style.row_manage_btn_span + " ml-1"}
                    onClick={() => { myProps.update_content_by_ajax([item]); }}
                    style={{ background: "#0097BA" }} >저장</span>
                </form>
              </div>
            </div>
          }
        </div>
      );
    });

    if (strFunc.is_empty(counsel_todo_tags)) {
      counsel_todo_tags = (
        <div style={{ lineHeight: "30px", textAlign: "center" }}>
          내용이 없습니다.
        </div>
      );
    }

    return counsel_todo_tags;
  };

  return (
    <div>
      <div>
        <button className="btn-m2 btn-gray" onClick={() => { all_display(is_show_content_arr.length == 0 ? true : false); }} >상세보기</button>
      </div>
      <div style={{ lineHeight: "40px", position: "relative", height: 40 }}>
        <label style={{ cursor: "pointer", position: "absolute", top: 5 }} onClick={(e: any) => { all_check(e.target.checked); }}>
          <input type="checkbox" /> 모두체크
        </label>
        <div className={Style.row_manage_btn_wrap}>
          <span className={Style.row_manage_btn_span_top} onClick={() => { myProps.update_content_by_ajax(get_check_row_info_arr()); }}
            style={{ marginLeft: 5 }}>
            저장
          </span>
          <span className={Style.row_manage_btn_span_top} onClick={() => { myProps.change_state_by_ajax("complete", get_check_row_info_arr()); }}
            style={{ marginLeft: 5 }} >
            완료
          </span>
          <span className={Style.row_manage_btn_span_top} onClick={() => { myProps.change_state_by_ajax("ready", get_check_row_info_arr()); }}
            style={{ marginLeft: 5 }}>
            완료취소
          </span>
          <span className={Style.row_manage_btn_span_top} onClick={() => { myProps.go_del_counsel_row(get_check_row_info_arr()); }}
            style={{ background: "#ff3333", marginLeft: 5 }}>
            삭제
          </span>
        </div>
      </div>
      <div className={Style.list_wrap}>
        {get_counsel_todo_tags()}
      </div>
      <form id="download_form" ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
    </div>
  );
};
export default CounselListArea;