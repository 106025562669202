function StudyDetailListArea(props:any){
   let myProps={
    Style:{},
    section_info:{},
    study_detail_arr:[],
    map_detail_arr:[],
    open_map_detail_write_popup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let section_info=myProps.section_info;
  let map_detail_arr=myProps.map_detail_arr;
  let row_study_detail_arr:any=[];
  for(let i=0;i<myProps.study_detail_arr.length;i++){
    let tmp_study_detail=myProps.study_detail_arr[i];
    if(section_info["a_seq"]==tmp_study_detail["a_section_seq"]){
      row_study_detail_arr.push(tmp_study_detail);
    }
  }

  const get_study_detail_tags=()=>{
    let study_detail_tags=row_study_detail_arr.map((item:any,idx:number)=>{
      let row_map_detail:any=null;
      for(let i=0;i<map_detail_arr.length;i++){
        let tmp_map_detail=map_detail_arr[i];
        if(tmp_map_detail["a_section_name"]==section_info["a_title"]
          &&tmp_map_detail["a_unit_name"]==item.a_title){
          
            row_map_detail=tmp_map_detail;
        }
      }

      let row_map_detail_text="없음";
      let row_map_detail_style:any={
        color:"gray",
      };
      if(row_map_detail!=null){
        row_map_detail_text="미완료";
        row_map_detail_style["color"]="orange";
        if(row_map_detail["a_is_success"]=="1"){
          row_map_detail_text="완료";
          row_map_detail_style["color"]="blue";
        }
      }

      return (
        <div key={idx} className={Style.study_detail_row}>
          <div className={Style.study_detail_con}
            onClick={()=>{
              myProps.open_map_detail_write_popup({
                map_detail_info:row_map_detail,
                section_study_name:{
                  section_name:section_info["a_title"],
                  unit_name:item.a_title,
                },
              });
            }}>
            <span className={Style.study_detail_idx} >{idx+1}.</span>
            <span className={Style.study_detail_title_wrap}>{item.a_title}</span>
            <span className={Style.study_detail_right}>
              <span style={row_map_detail_style}>{row_map_detail_text}</span>
            </span>
          </div>
        </div>
      );
    });
    return study_detail_tags;
  };
  
  return (
  <div>
    {get_study_detail_tags()}
  </div>
  );
};
export default StudyDetailListArea;