import CompLayout from "@/pcomponents/comp/layout/layout";
import TitleMapListArea from "@/pages/comp/plan/study/study_map/common/title_map/list";

function StudyTitleMapListPage() {

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <TitleMapListArea></TitleMapListArea>
      </div>
    </CompLayout>
  );
};
export default StudyTitleMapListPage;