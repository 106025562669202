import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

class TdDataFunc {
  static setting_data_at_td_data_arr = (inData: any) => {
    let opt_obj = {
      td_data_arr: [],
      mprescribe_arr: [],
      lecture_user_time_arr: [],
      Style: {},
      is_show_counseler: false,
      open_stu_layer_popup: (inData: any) => { },
      open_write_prescribe_popup: (inData: any) => { },
      open_reserve_prescribe_popup: (inData: any) => { },
      ...inData,
    };
    let td_data_arr = opt_obj["td_data_arr"];
    let td_data_arr_len = td_data_arr.length;
    let mprescribe_arr = opt_obj["mprescribe_arr"];
    let Style = opt_obj["Style"];
    let is_show_counseler = opt_obj["is_show_counseler"];
    
    let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
    let this_obj = this;
    for (let td_i = 0; td_i < td_data_arr_len; td_i++) {
      let td_data = td_data_arr[td_i];
      let td_mprescribe_arr = this_obj.get_td_mprescribe_arr({
        mprescribe_arr: mprescribe_arr,
        lecture_user_time_arr: opt_obj["lecture_user_time_arr"],
        td_data: td_data,
      });
      td_data_arr[td_i]["mprescribe_arr"] = td_mprescribe_arr;
      td_data_arr[td_i]["content"] = "";
      let row_success_cnt = 0;
      for (let i = 0; i < td_mprescribe_arr.length; i++) {
        if (td_mprescribe_arr[i]["a_is_success"] == "1") {
          row_success_cnt++;
        }
      }
      let td_title_str = "";
      let content_td_list: any = "";
      if (td_mprescribe_arr.length > 0) {
        td_title_str = row_success_cnt + "/" + td_mprescribe_arr.length;
        let td_data_row_tags = td_mprescribe_arr.map((item: any, idx: number) => {
          let comment_cnt = 0;
          if (item.comment_arr && item.comment_arr.length > 0) {
            comment_cnt = item.comment_arr.length;
          }
          let content_row_style: any = {};

          let content_row_sub_text: any = "";
          let state_box_style: any = {};

          if(item["a_is_reserve"]=="1"){
            content_row_style["background"] = "#707070";
          }else{
            content_row_style["background"] = "#eab358";
          }
          if (comment_cnt > 0) {
            content_row_style["background"] = "#f26b6f";
            state_box_style["background"] = "#f26b6f";
            let comment_last_idx = item.comment_arr.length - 1;
            if (item.comment_arr[comment_last_idx]["a_qna_sort"] == "a") {
              state_box_style["background"] = "#eab358";
            }
          }
          if (item["a_is_success"] == "1") {
            content_row_style["background"] = "#a2b854";
            state_box_style["background"] = "#f26b6f";
            if(item.a_is_success_check=="1"){
              state_box_style["background"] = "#a2b854";
            }
          }
          
          if (is_show_counseler) {
            content_row_sub_text = strFunc.cut_str(item.a_writer_name, 3, "");
          }
          
          let stu_name_str = item.a_stu_name;
          let stu_grade_str = "";
          let stu_school_name = "";
          if (item.stu_info) {
            stu_name_str = item.stu_info["a_user_name"];
            stu_grade_str = item.stu_info["a_stu_grade"];
            stu_school_name = item.stu_info["a_school_name"];
            if (select_stu_grade_option_arr) {
              for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
                if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
                  stu_grade_str = select_stu_grade_option_arr[i]["text"];
                }
              }
            }
          }

          let subject_span_style: any = {};
          let row_subject_str="";
          if (item.a_subject_name == "영어") {
            row_subject_str="E";
          }else if (item.a_subject_name == "수학") {
            row_subject_str="M";
            subject_span_style["color"] = "#a4d8ff";
          }

          let content_title_str = stu_name_str + " " + stu_school_name + " " + stu_grade_str + "\n";
          content_title_str += "1.진도전략:\n";
          content_title_str += item.a_progress_reason + "\n\n";
          content_title_str += "2.현재진도:\n";
          content_title_str += item.a_progress + "\n\n";
          content_title_str += "3.성장한부분:\n";
          content_title_str += item.a_grow + "\n\n";
          content_title_str += "4.발견:\n";
          content_title_str += item.a_lack + "\n\n";
          content_title_str += "5.학습처방:\n";
          content_title_str += item.a_content + "\n\n";
          content_title_str += "작성일:" + item.a_create_date + " " + item.a_writer_name + "\n";
          if (item.a_is_success == "1") {
            content_title_str += "처방일:" + item.a_success_date + " " + item.a_success_writer_name + "\n";
          }
          if (item.a_is_request_counsel == "1") {
            content_title_str += "상담요청:" + item.a_request_counsel_date;
            content_title_str+=" "+item.a_request_counselor+"\n";
          }

          if (comment_cnt > 0) {
            content_title_str += "질문\n";
            for (let i = 0; i < comment_cnt; i++) {
              let comment_idx = i + 1;
              let comment_info = item.comment_arr[i];
              content_title_str += comment_idx + "." + comment_info["a_responser"] + " " + comment_info["a_create_date"] + "\n";
              content_title_str += "  " + comment_info["a_message"] + "\n";
            }
          }

          let counsel_badge_style:any={};
          if(item.a_is_request_counsel=="1"&&!strFunc.is_empty(item.a_request_counsel_seq)){
            counsel_badge_style["background"]="#00ca49";
          }

          return (
            <div key={idx} className={Style.content_td_row}
              style={content_row_style}
              onClick={() => {
                opt_obj.open_stu_layer_popup({
                  mprescribe_info: item
                });
              }}
              title={content_title_str} >
              <span>{strFunc.cut_str(stu_name_str, 4, "")}</span>
              <span className={Style.content_td_row_sub_text} style={subject_span_style}>
                {row_subject_str}
              </span>
              {item["a_is_request_counsel"] == "1" &&
                <span className={Style.content_td_row_counsel_request} style={counsel_badge_style}>
                  상
                </span>
              }
              <div className={Style.content_td_row_state_box} style={state_box_style}>
                {content_row_sub_text}
              </div>
            </div>
          );
        });
        content_td_list = (
          <div className={Style.content_td_list}>
            {td_data_row_tags}
          </div>
        );
      }
      td_data_arr[td_i]["content"] = (
        <div className={Style.content_td_wrap}>
          <button className={Style.content_td_center_plus_btn}
            onClick={(e: any) => {
              opt_obj.open_reserve_prescribe_popup({
                select_date: td_data["date_str"],
              });
            }}>+</button>
          <div className={Style.content_td_title_wrap}>
            {td_title_str}
          </div>
          {content_td_list}
        </div>
      );
    }

    return td_data_arr;
  };

  static get_td_mprescribe_arr(inData: any) {
    let opt_obj = {
      mprescribe_arr: [],
      lecture_user_time_arr: [],
      td_data: {},
      ...inData
    };
    let td_mprescribe_arr: any = [];
    let mprescribe_arr = opt_obj["mprescribe_arr"];
    let td_data = opt_obj["td_data"];
    let lecture_user_time_arr=opt_obj["lecture_user_time_arr"];

    for (let i = 0; i < mprescribe_arr.length; i++) {
      let mprescribe_info = mprescribe_arr[i];
      if (mprescribe_info["a_reserve_date"] == td_data["date_str"]) {
        td_mprescribe_arr.push(mprescribe_info);
      }
    }
    
    let ordered_td_mprescribe_seq_arr = [];
    let ordered_td_mprescribe_arr = [];
    for (let i = 0; i < lecture_user_time_arr.length; i++) {
      let lecture_user_time_info = lecture_user_time_arr[i];
      if(strFunc.uncomma(lecture_user_time_info["a_day_num"])==strFunc.uncomma(td_data["day_of_week"])){
        for (let j = 0; j < td_mprescribe_arr.length; j++) {
          let mprescribe_info = td_mprescribe_arr[j];
          if (lecture_user_time_info["a_stu_seq"] == mprescribe_info["a_stu_seq"]) {
            let row_pri_val=mprescribe_info["a_ymd"]+","+mprescribe_info["a_seq"];
            if(strFunc.str_in_array(row_pri_val,ordered_td_mprescribe_seq_arr)==-1){
              ordered_td_mprescribe_seq_arr.push(row_pri_val);
              ordered_td_mprescribe_arr.push(mprescribe_info);
            }
          }
        }
      }
    }
    //나머지
    let remain_td_mprescribe_arr=[];
    for (let j = 0; j < td_mprescribe_arr.length; j++) {
      let mprescribe_info = td_mprescribe_arr[j];
      let row_pri_val=mprescribe_info["a_ymd"]+","+mprescribe_info["a_seq"];
      if(strFunc.str_in_array(row_pri_val,ordered_td_mprescribe_seq_arr)==-1){
        remain_td_mprescribe_arr.push(mprescribe_info);
      }
    }
    //재정렬
    td_mprescribe_arr=[
      ...ordered_td_mprescribe_arr,
      ...remain_td_mprescribe_arr
    ];

    return td_mprescribe_arr;
  };
}
export default TdDataFunc;