import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";
import strFunc from '@/lib/lyg/string';

function FindStudySubjectPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    listOpt: {},
    row_num: 0,
    key_str: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_title_like":"",
    "s_subject_sort": "",
    "s_subject_seq": "",
    "s_subject_main_seq":"",
    "s_addon_subject_main":"1",

    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    ...myProps.listOpt
  });
  const [infoArr, setInfoArr] = useState([]);
  const [subject_main_info_arr, set_subject_main_info_arr] = useState([]);
  useEffect(() => {
    get_subject_main_info_by_ajax({});
  }, []);
  const list = (inOptObj: any) => {
    let change_list_opt:any={
      ...listOpt,
      ...inOptObj
    };
    if (listAggridRef.current) {
      change_list_opt["sc"] = listAggridRef.current.getListSc();
    }
    setListOpt(change_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_subject_main_info_by_ajax = (inData: any) => {
    let opt_obj = {
      a_subject_sort: listOpt.s_sort,
      ...inData
    };
    let list_form_json_data: any = {
      "order_id": "a_order_num, a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (!strFunc.is_empty(opt_obj.a_subject_sort)) {
      //list_form_json_data["s_sort"]=opt_obj.a_subject_sort;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_select_click = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key_str,
      par_info: myProps.par_info,
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;


    return render_str;
  }, []);
  return (
    <div>
      <div className="text-center">
        <button className="btn btn-darkgreen" onClick={on_select_click}>선택</button>
        <button className="btn btn-dark ml-1" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        subject_main_info_arr={subject_main_info_arr}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: false,
          floatingFilter: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
    </div>
  );
};
export default FindStudySubjectPopup;