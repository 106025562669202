import { useRef } from 'react';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    xColumnArr: {},
    ...props
  };
  let listOpt = myProps.listOpt;
  let xColumnArr = myProps.xColumnArr;

  const search_time_out_ref = useRef<any>(null);

  const go_search_list = (inData: any) => {
    if (search_time_out_ref.current) {
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current = setTimeout(() => {
      myProps.list(inData);
    }, 200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_listOpt: any = {
      ...listOpt,
    };
    change_listOpt[name] = value;
    myProps.setListOpt(change_listOpt);
    go_search_list({ now_page: 1, [name]: value });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt = {
      now_page: 1,
      [key]: date_str
    };
    if (key == "s_start_date") {
      change_list_opt["s_end_date"] = date_str;
    }
    myProps.list(change_list_opt);
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };

  return (
    <div>
      <div style={{ display: "inline-block", width: 90 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_start_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>~
      <div style={{ display: "inline-block", width: 90 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_end_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => {
        myProps.list({
          "now_page": 1,
          s_start_date: "",
          s_end_date: ""
        });
      }}>x</button>
      <button className="btn-s2 btn-sky ml-1" onClick={() => {
        myProps.list({
          "now_page": 1,
          s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
          s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d")
        });
      }}>오늘</button>
      <button className="btn-s2 btn-sky ml-1" onClick={() => {
        myProps.list({
          "now_page": 1,
          s_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
          s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t")
        });
      }}>이번달</button>
      <button className="btn btn-dark ml-2" onClick={() => { myProps.list({ "now_page": "1" }); }}>검색</button>
    </div>
  );
};
export default SearchArea;