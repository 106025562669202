import { useRef } from 'react';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";
import strFunc from '@/lib/lyg/string';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    xColumnArr: {},
    ...props
  };
  let listOpt = myProps.listOpt;
  let xColumnArr = myProps.xColumnArr;

  const search_time_out_ref = useRef<any>(null);

  const go_search_list = (inData: any) => {
    if (search_time_out_ref.current) {
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current = setTimeout(() => {
      myProps.list(inData);
    }, 200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_listOpt: any = {
      ...listOpt,
    };
    change_listOpt[name] = value;
    myProps.setListOpt(change_listOpt);
    go_search_list({ now_page: 1, [name]: value });
  };

  return (
    <div>
      <div>
        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={listOpt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 90, display: "inline-table" }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <select className="search-input ml-1" name="s_stu_grade" value={listOpt.s_stu_grade}
          onChange={handleInputChange} style={{ width: 56, height: 29 }} >
          <option value="">학년</option>
          {xColumnArr.select_arr["a_stu_grade"].map((item: any, idx: number) => {
            return (
              <option key={idx} value={item.value} >{item.text}</option>
            );
          })}
        </select>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <input type="text" className="search-input ml-1" name="s_search_text"
          style={{ width: 40, padding: 4 }} placeholder="이름" value={listOpt.s_search_text}
          onChange={handleInputChange} onKeyUp={(e: any) => { myProps.list({ "now_page": 1 }); }} />
        <button className="btn btn-dark ml-1" onClick={() => { myProps.list({ "now_page": "1" }); }}>검색</button>
      </div>
      <div className="mt-1">
        <label>
          <input type="checkbox" className="search-input" name="s_addon_study_map_s_order_recent"
            checked={listOpt.s_addon_study_map_s_order_recent=="1"} value={"1"}
            onChange={handleInputChange} />
          맵핑 최근순 정렬
        </label>
      </div>
    </div>
  );
};
export default SearchArea;