import Style from "./css/style.module.css";
import SectionArea from "./section";

function SectionTreeArea(props: any) {
  let myProps = {
    section_arr: [],
    study_arr: [],
    map_arr: [],
    map_det_arr: [],
    get_study_title_info_by_ajax: (inData: any) => { },
    ...props
  };
  let section_arr = myProps.section_arr;
  let study_arr = myProps.study_arr;
  let map_arr = myProps.map_arr;
  let map_det_arr = myProps.map_det_arr;

  const get_section_tags = () => {
    let section_tags = section_arr.map((item: any, idx: number) => {
      return (
        <SectionArea
          key={idx}
          Style={Style}
          section_idx={idx}
          section_info={item}
          study_arr={study_arr}
          map_arr={map_arr}
          map_det_arr={map_det_arr}
          get_study_title_info_by_ajax={myProps.get_study_title_info_by_ajax}
        ></SectionArea>
      );
    });

    return section_tags;
  };

  return (
    <div className={Style.section_wrap}>
      {get_section_tags()}
    </div>
  );
};
export default SectionTreeArea;