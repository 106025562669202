import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import MapDetailWritePopup from "@/pages/comp/plan/study/study_map/title_map/popup/detail_write";
import Style from "./css/style.module.css";
import ManageButtonArea from "./area/manage_btns";
import SectionStudyArea from "./area/section_study";

function StuMapWriteArea(props: any) {
  let myProps = {
    study_map_pri_val: "",
    callback_refresh_data: (inData: any) => { },
    back_func: () => { },
    back_text: "닫기",
    ...props
  };
  let default_study_map_info = {
    a_study_title_seq: "",
    a_stu_seq: "",
    a_stu_name: "",
    a_success_cnt: "",
    a_total_cnt: "",
    a_start_date: "",
    a_is_success: "",
    a_success_date: "",
    detail_arr: [],
    section_arr: [],
    study_detail_arr: [],
    study_title_info: null,
    study_title: "",
    user_info: null,
  };
  const [study_map_info, set_study_map_info] = useState<any>({ ...default_study_map_info });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "write_map_detail",//write_map_detail
    stu_info: {},
    map_detail_info: null,
    section_study_name: {
      section_name: "",
      unit_name: "",
    },
    has_header: true,
    title: "상세보기",
    width: "800px",
    height: "100%",
  });

  useEffect(() => {
    get_study_map_info_by_ajax();
  }, []);

  const get_study_map_info_by_ajax = () => {
    if (strFunc.is_empty(myProps.study_map_pri_val)) {
      alert("필수값이 없습니다.");
      myProps.back_func();
      return false;
    }
    let form_json_data: any = {
      "s_pri_arr": [myProps.study_map_pri_val],
      "s_addon_study_title": "1",
      "s_addon_study_detail": "1",
      "s_addon_detail": "1",
      "s_addon_user_info": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_study_map_info(response.data["data"]["info_arr"][0]);
          } else {
            alert("조회된 데이터가 없습니다.");
            myProps.back_func();
          }
        } else {
          alert("조회 중 오류." + response.data["msg"]);
          myProps.back_func();
        }
      });
  };

  const go_delete_study_map = () => {
    if (strFunc.is_empty(study_map_info.a_study_title_seq)
      || strFunc.is_empty(study_map_info.a_stu_seq)) {
      alert("필수값이 없습니다.");
      return false;
    }

    if (!confirm("해당 교과맵핑을 삭제 하시겠습니까?")) {
      return false;
    }
    let del_study_map_row={
      a_study_title_seq:study_map_info.a_study_title_seq,
      a_stu_seq:study_map_info.a_stu_seq,
    };
    let form_json_data: any = {
      "data_arr": [del_study_map_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.callback_refresh_data({});
          myProps.back_func();
        } else {
          alert("조회 중 오류." + response.data["msg"]);
        }
      });
  };

  const open_map_detail_write_popup = (inData:any) => {
    let opt_obj={
      map_detail_info:{},
      section_study_name:{
        section_name:"",
        unit_name:"",
      },
      ...inData
    };
    let map_detail_info:any=opt_obj.map_detail_info;
    if(map_detail_info==null){
      map_detail_info={
        a_study_title_seq:study_map_info.a_study_title_seq,
        a_stu_seq:study_map_info.a_stu_seq,
        a_seq:"",
      };
    }
    let section_study_name=opt_obj.section_study_name;
    let stu_info=null;
    if(study_map_info.user_info){
      stu_info=study_map_info.user_info;
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "상세보기",
      "sort": "write_map_detail",
      stu_info:stu_info,
      map_detail_info:map_detail_info,
      section_study_name:section_study_name,
      width: "800px",
      height: "99%",
    });
  };

  return (
    <div>
      <ManageButtonArea
        back_func={myProps.back_func}
        back_text={myProps.back_text}
        go_delete_study_map={go_delete_study_map}
      ></ManageButtonArea>
      <div className={Style.section_study_area}>
        <SectionStudyArea
          Style={Style}
          study_map_info={study_map_info}
          callback_refresh_data={myProps.callback_refresh_data}
          open_map_detail_write_popup={open_map_detail_write_popup}
        ></SectionStudyArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "write_map_detail" &&
            <MapDetailWritePopup
              stu_info={popupData.stu_info}
              map_detail_info={popupData.map_detail_info}
              section_study_name={popupData.section_study_name}
              callback_refresh_data={() => { 
                get_study_map_info_by_ajax();
                myProps.callback_refresh_data({}); 
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></MapDetailWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default StuMapWriteArea;