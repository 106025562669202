import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"study_subject",
    list_id:"manage_pop_subject_list",
    x_column_list_arr:[
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_sort","name":"과목","width":"80","is_show":"1"},
      // {"key":"row_view_main","name":"구분","width":"80","is_show":"1"},
      {"key":"a_main_seq","name":"대분류","width":"80","is_show":"1"},
      {"key":"a_title","name":"제목","width":"150","is_show":"1"},
      // {"key":"a_is_use","name":"사용여부","width":"100","is_show":"1"},
      // {"key":"row_view_order_num","name":"순번","width":"90","is_show":"1"},
      {"key":"a_order_num","name":"순번","width":"90","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_order_num, a_title',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.select_arr.a_is_use=[
    {"value":"1","text":"예"},
    {"value":"","text":"아니오"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;