import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import popupFunc from "@/lib/lyg/popup";
import noimg from "@/img/ico/noimg.png";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import TabArea from "./area/tab_area";
import WriteArea from "./area/writer_area";
import UseStuList from "./area/use_stu_list";
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import SubjectMainManagePopup from "@/pages/comp/plan/study/subject_main/popup/manage";
import SubjectManagePopup from "@/pages/comp/plan/study/subject/popup/manage";
import LoadingComponent from "@/pcomponents/common/content/loading";
import SectionStudyArea from "./area/section_study";
import strFunc from '@/lib/lyg/string';

const WriteFrameContent = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => { },
    isUpdate: false,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const WriteAreaRef = useRef<any>(null);
  const UseStuListRef = useRef<any>(null);
  const FileListWriteFrameRef = useRef<any>(null);

  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  const [isUpdate, setIsUpdate] = useState(myProps.isUpdate);
  const [active_tab, set_active_tab] = useState("all");
  const [refresh, set_refresh] = useState(true);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장중..",
  });
  if (isUpdate) {
    pageData["write_btn_text"] = "저장";
    pageData["confirm_msg"] = "저장 하시겠습니까?";
  }
  const init_data = {
    "a_seq": "",
    "a_subject_sort": "",
    "a_subject_main_seq": "",
    "a_subject_seq": "",
    "a_title": "",
    "a_answer_link_url": "",
    "a_open_group": "",
    "a_is_progress": "",
    "a_is_mapping": "",
    "title_img_url": noimg,
    "title_img_arr": [],
    "section_arr": [],
    "study_arr": [],
    "use_stu_arr": [],
  };
  const [rowData, setRowData] = useState<any>({
    ...init_data
  });
  const [subject_main_info_arr, set_subject_main_info_arr] = useState<any>([]);
  const [subject_info_arr, set_subject_info_arr] = useState<any>([]);

  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      "row_data": {},
      "isUpdate": false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
    get_subject_main_info_by_ajax(optObj.row_data);
    getSubjectInfoArrByAjax(optObj.row_data);
    if (WriteAreaRef.current) {
      WriteAreaRef.current.setInitData({
        "row_data": optObj["row_data"],
        "isUpdate": optObj.isUpdate
      });
    }
    if (UseStuListRef.current) {
      let use_stu_arr = optObj["row_data"]["use_stu_arr"] ? optObj["row_data"]["use_stu_arr"] : [];
      UseStuListRef.current.setInitData({
        "title_info": optObj["row_data"],
        "infoArr": use_stu_arr
      });
    }
    if (FileListWriteFrameRef.current && optObj.isUpdate) {
      FileListWriteFrameRef.current.list({
        "now_page": "1",
        "s_start_date": optObj["row_data"].a_create_date.substring(0, 10),
        's_par_id': "study_title",
        's_par_seq': optObj["row_data"].a_seq,
        's_sort1': "file",
        's_sort2': "",
        's_sort3': "",
      });
    }
  };

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "manage_subject",//view
    listOpt: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData, setIsUpdate, setInitData
  }));

  useEffect(() => {
    get_subject_main_info_by_ajax({});
    getSubjectInfoArrByAjax({});
  }, []);


  const goWrite = useCallback((e: any) => {
    if (rowData.a_title == "") {
      alert("제목 입력이 필요합니다.");
      return false;
    }
    let wRowData = { ...rowData };

    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    set_loading_data({
      is_display: true,
      text: "저장중..",
    });
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (fileInput.current && (fileInput.current as HTMLInputElement).value != "") {
            goWriteProfileImg(response.data["data"][0]);
          } else {
            goWriteSectionList(response.data["data"][0]);
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  const goWriteProfileImg = (row_data: any) => {
    if (writeFileForm.current == null) {
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data = new FormData(form);
    let file_data_row = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "study_title",
      "a_par_seq": row_data.a_seq,
      "a_sort1": "title",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": user.user_seq,
    };
    form_json_data.append("data_arr", JSON.stringify([file_data_row]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (fileInput.current) {
            (fileInput.current as HTMLInputElement).value = "";
          }
          goWriteSectionList(row_data);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDeleteProFile = (fileInfo: any) => {
    if (!confirm("사진을 삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "data_arr": [fileInfo],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.list({ select_seq: rowData.a_seq });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteSectionList = (row_data: any) => {
    let section_video_form_json = {
      "title_info": row_data,
      "section_arr": rowData.section_arr,
      "study_arr": rowData.study_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/add_study_detail',
      section_video_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display: false,
              text: "저장중..",
            });
          }, 1000);
          myProps.list({ select_seq: row_data["a_seq"] });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_subject_main_info_by_ajax = (inData: any) => {
    let opt_obj = {
      a_subject_sort: rowData.a_subject_sort,
      ...inData
    };
    let list_form_json_data: any = {
      "order_id": "a_order_num, a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (!strFunc.is_empty(opt_obj.a_subject_sort)) {
      list_form_json_data["s_sort"] = opt_obj.a_subject_sort;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const getSubjectInfoArrByAjax = (inData: any) => {
    let opt_obj = {
      a_subject_sort: rowData.a_subject_sort,
      a_subject_main_seq: rowData.a_subject_main_seq,
      ...inData
    };
    let list_form_json_data: any = {
      "order_id": "a_order_num, a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (!strFunc.is_empty(opt_obj.a_subject_sort)) {
      list_form_json_data["s_sort"] = opt_obj.a_subject_sort;
    }
    if (!strFunc.is_empty(opt_obj.a_subject_main_seq)) {
      list_form_json_data["s_main_seq"] = opt_obj.a_subject_main_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const openManageMainSubject = () => {
    let pop_title = "관리";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "manage_main_subject",
      listOpt: {
        s_sort: rowData.a_subject_sort,
      },
    });
  };
  const openOneManageSubject = () => {
    let pop_title = "등록";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "manage_subject",
      listOpt: {
        s_sort: rowData.a_subject_sort,
        s_main_seq:rowData.a_subject_main_seq,
      },
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <button className="btn btn-line-gray" onClick={goWrite}
        style={{ position: "absolute", right: 5, top: 5 }}>
        {pageData["write_btn_text"]}
      </button>
      <TabArea
        isUpdate={isUpdate}
        active_tab={active_tab}
        onChangeTab={(tab_id: string) => {
          set_active_tab(tab_id);
          setInitData({
            "row_data": rowData,
            "isUpdate": isUpdate,
          });
        }}
      ></TabArea>
      <div className="mt-3">
        <div className={(active_tab == "all" ? "" : "hidden")}>
          <WriteArea
            ref={WriteAreaRef}
            rowData={rowData}
            setRowData={setRowData}
            subject_main_info_arr={subject_main_info_arr}
            subject_info_arr={subject_info_arr}
            get_subject_main_info_by_ajax={get_subject_main_info_by_ajax}
            getSubjectInfoArrByAjax={getSubjectInfoArrByAjax}
            openManageMainSubject={openManageMainSubject}
            openOneManageSubject={openOneManageSubject}
          ></WriteArea>
          <div className="write-table-div">
            <table>
              <colgroup>
                <col width="20%"></col>
                <col width="30%"></col>
                <col width="20%"></col>
                <col width="30%"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>썸네일</th>
                  <td colSpan={3}>
                    <div className="text-center">
                      <form id="write_form" method="post" ref={writeFileForm} >
                        <a onClick={() => {
                          popupFunc.openPopup({ 'url': rowData.title_img_url, 'width': 700, 'height': 420, 'pop_name': 'name', 'auto_size': false });
                        }}>
                          <img src={rowData["title_img_url"] ? rowData["title_img_url"] : noimg} alt="사람" style={{ width: 70 }} />
                        </a>
                        {
                          rowData.title_img_arr.length > 0 &&
                          <button className="btn-s btn-gray" onClick={() => { goDeleteProFile(rowData.title_img_arr[0]); }}>X</button>
                        }
                        <input type="file" id="files" className="row-input mt-1 ml-2" name="input_file[]" ref={fileInput} style={{ width: 200 }} />
                      </form>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={(active_tab == "section_study" || active_tab == "all" ? "" : "hidden")}>
          <SectionStudyArea
            section_arr={(rowData.section_arr) ? rowData.section_arr : []}
            study_arr={(rowData.study_arr) ? rowData.study_arr : []}
            set_section_arr={(inData: any) => {
              setRowData({
                ...rowData,
                ...{
                  section_arr: [...inData]
                }
              });
              //set_refresh(!refresh);
            }}
            set_study_arr={(study_arr: any) => {
              setRowData({
                ...rowData,
                ...{
                  study_arr: [...study_arr]
                }
              });
              //set_refresh(!refresh);
            }}
            rowData={rowData}
            setRowData={setRowData}
          ></SectionStudyArea>
        </div>
        <div className={((active_tab == "student" || active_tab == "all") && isUpdate ? "" : "hidden")}>
          <UseStuList
            ref={UseStuListRef}
            list={(inOptObj: any) => { myProps.list(inOptObj); }}
          ></UseStuList>
        </div>
        <div className="btn-box-center mt-2">
          <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        </div>
      </div>

      {isUpdate &&
        <div className={(active_tab == "all" ? "" : "hidden")}>
          <FileListWriteFrame
            ref={FileListWriteFrameRef}
            listOpt={{
              "s_start_date": rowData.a_create_date.substring(0, 10),
              's_par_id': "study_title",
              's_par_seq': rowData.a_seq,
              's_sort1': "file",
              's_sort2': "",
              's_sort3': "",
            }}
          ></FileListWriteFrame>
        </div>
      }

      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "manage_main_subject" &&
            <SubjectMainManagePopup
              listOpt={popupData.listOpt}
              callbackData={(inData: any) => {  }}
              refreshData={(inData: any) => { get_subject_main_info_by_ajax({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></SubjectMainManagePopup>
          }
          {popupData.sort === "manage_subject" &&
            <SubjectManagePopup
              listOpt={popupData.listOpt}
              callback={(inData: any) => { getSubjectInfoArrByAjax({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></SubjectManagePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
});

export default WriteFrameContent;
