function ManageBtnArea(props: any) {
  let myProps = {
    setWriteState:()=>{},
    goWriteCopyByAjax:()=>{},
    goDelete:()=>{},
    openFindSubjectPopup:()=>{},
    ...props
  };

  return (
    <div style={{position:"relative"}}>
      {" "}
      <button className="btn btn-dark" onClick={()=>{myProps.openFindSubjectPopup();}} >선택분류변경</button>
      <span style={{position:"absolute",right:0}}>
        <button className="btn btn-sky ml-1" onClick={() => { myProps.setWriteState(); }}>신규</button>
        <button className="btn btn-dark ml-1" onClick={() => { myProps.goWriteCopyByAjax(); }}>복사</button>
        <button className="btn btn-red ml-1" onClick={myProps.goDelete}>삭제</button>
      </span>
    </div>
  );
};
export default ManageBtnArea;