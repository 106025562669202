import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import DatePicker from "react-datepicker";

function SearchArea(props: any) {
  let myProps: any = {
    listOpt: {
      s_date_type: "a_date",
      s_start_date: "",
      s_end_date: "",
    },
    list: (listOpt: any) => { },
    ...props
  };

  const [listOpt, setListOpt] = useState(myProps.listOpt);

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setListOpt({
      ...listOpt,
      ...{ [key]: date_str }
    });
    myProps.list({
      now_page: "1",
      ...{ [key]: date_str }
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    myProps.list({
      now_page: "1",
      ...{ [name]: value }
    });
  };

  const goDateMonthChange = (sort: any) => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'month', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'month', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-01");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-t");
    setListOpt({
      ...listOpt,
      ...{
        s_start_date: start_date,
        s_end_date: end_date,
      }
    });
    myProps.list({
      now_page: "1",
      ...{
        s_start_date: start_date,
        s_end_date: end_date,
      }
    });
  };

  const change_start_end_date_by_sort = (sort: "this_year" | "this_month" | "pre_month") => {
    let now_month_init_date = DateFunc.get_date_format(new Date(), "Y-m-01");
    let s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    let s_end_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    if (sort == "this_month") {
      s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      s_start_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-t");
    } else if (sort == "this_year") {
      s_start_date = DateFunc.get_date_format(new Date(), "Y-01-01");
      s_end_date = "";
    }
    myProps.list({
      now_page: 1,
      s_start_date: s_start_date,
      s_end_date: s_end_date,
    });
  };

  return (
    <div>
      <div className="btn-box-left relative">
        <select className="search-input_select" name="s_date_type" value={listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_date">거래일</option>
        </select>
        <span style={{ width: 90 }}>
          <DatePicker
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </span>~
        <span style={{ width: 90 }}>
          <DatePicker
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </span>
        <button className="btn-s btn-dark" onClick={() => { goDateMonthChange("pre"); }} >◀</button>
        <button className="btn-s btn-dark" onClick={() => { goDateMonthChange("next"); }} >▶</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("this_month"); }} >이번달</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("pre_month"); }} >지난달</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("this_year"); }} >올해</button>
      </div>
      <div className="btn-box-left relative mt-1">

      </div>
    </div>
  );
}
export default SearchArea;