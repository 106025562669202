import { useState, useRef, useEffect, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import SearchArea from "./area/search";
import ListSimpleArea from "@/pcomponents/common/crud/list/list_simple";

function SubjectManagePopup(props: any) {
  let myProps = {
    listOpt:{},
    closePopup: () => { },
    closeText: "닫기",
    callback: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_sort: "",
    s_main_seq: "",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    ...myProps.listOpt,
  });
  const [infoArr, setInfoArr] = useState([]);
  const [select_row_num_arr, set_select_row_num_arr] = useState([]);
  const [subject_main_info_arr, set_subject_main_info_arr] = useState([]);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  const [is_show_list, set_is_show_list] = useState(false);
  xColumnArr.select_arr.a_main_seq = [];
  xColumnArr.select_arr.a_sort = select_subject_option_arr;
  for (let i = 0; i < subject_main_info_arr.length; i++) {
    let tmp_subject_main = subject_main_info_arr[i];
    xColumnArr.select_arr.a_main_seq.push({
      "value": tmp_subject_main["a_seq"],
      "text": tmp_subject_main["a_title"],
      "a_subject_sort":tmp_subject_main["a_subject_sort"],
    });
  }
  useEffect(() => {
    get_subject_main_info_by_ajax({});
  }, []);

  const list = (inData: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inData
    };
    setListOpt(change_list_opt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_row_num_arr([]);
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_subject_main_info_by_ajax = (inData: any) => {
    let opt_obj = {
      a_subject_sort: listOpt.s_sort,
      ...inData
    };
    let list_form_json_data: any = {
      "order_id": "a_order_num, a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (!strFunc.is_empty(opt_obj.a_subject_sort)) {
      //list_form_json_data["s_sort"]=opt_obj.a_subject_sort;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_info_arr(response.data["data"]["info_arr"]);
          list({});
          set_is_show_list(true);
        } else {

        }
      });
  };

  const getLastSubjectOrderNum = () => {
    let last_order_num = 1;
    let selected_row = infoArr;
    let selected_row_len = selected_row.length;
    for (let i = 0; i < selected_row_len; i++) {
      let row_data = selected_row[i];
      let row_order_num = parseInt(strFunc.uncomma(row_data["a_order_num"]));
      if (row_order_num > last_order_num) {
        last_order_num = row_order_num;
      }
    }
    return last_order_num;
  };

  const goAddRow = () => {
    let last_order_num = getLastSubjectOrderNum();
    let selected_row:any = [...infoArr];
    let add_row_data = {
      "a_seq": "",
      "a_title": "",
      "a_sort": listOpt.s_sort,
      "a_main_seq": listOpt.s_main_seq,
      "a_is_use": "1",
      "a_order_num": last_order_num,
    };
    selected_row.push(add_row_data);
    setInfoArr(selected_row);
  };

  const getSelectInfoArr=()=>{
    let select_info_arr:any=[];
    for(let i=0;i<infoArr.length;i++){
      if(strFunc.str_in_array(i,select_row_num_arr)!=-1){
        select_info_arr.push(infoArr[i]);
      }
    }
    return select_info_arr;
  };

  const goWrite = () => {
    let selected_row = getSelectInfoArr();
    selected_row = getReSetOrderNum(selected_row);
    if (selected_row.length === 0) {
      alert("데이터없음.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let write_form_json = {
      "data_arr": selected_row
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          myProps.callback({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDelete = useCallback(() => {
    let selected_row = getSelectInfoArr();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let del_form_json = {
      "data_arr": selected_row,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/delete', del_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          myProps.callback({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const getReSetOrderNum = (selected_row: any) => {
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i].a_order_num = i + 1;
    }
    return selected_row;
  };

  const get_td_render_tag = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      name: "",
      row_num: 0,
      info: {},
      render_str: "",
      on_change_row_input: (inData:any)=>{},
      ...inData
    };

    if(opt_obj.key=="a_main_seq"){
      let tmp_select_opt_arr = xColumnArr.select_arr[opt_obj.key];
        let tmp_opt_tags = tmp_select_opt_arr.map((opt_info: any, opt_idx: number) => {
          if(opt_obj.info["a_sort"]!=opt_info["a_subject_sort"]){
            return "";
          }
          return (
            <option key={opt_idx} value={opt_info["value"]}>{opt_info["text"]}</option>
          );
        });
        
        opt_obj.render_str = (
          <select className="row-input" name={opt_obj.key} value={opt_obj.value} title={opt_obj.value}
            onChange={(e: any) => {
              opt_obj.on_change_row_input({
                row_num: opt_obj.row_num,
                key: opt_obj.key,
                value: e.target.value,
              });
            }} 
            style={{width:"100%",maxWidth:"100",border:"1px solid #ddd"}}>
            
            <option value="">선택</option>
            {tmp_opt_tags}
          </select>
        );
    }

    return opt_obj.render_str;
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        list={list}
        closePopup={myProps.closePopup}
        closeText={myProps.closeText}
        subject_main_info_arr={subject_main_info_arr}
      ></SearchArea>
      <div className="btn-box-center m-2">
        <button className="btn btn-line-gray" onClick={() => { goWrite(); }}>저장</button>
        <button className="btn btn-line-gray" onClick={() => { goAddRow(); }}>추가</button>
        <button className="btn btn-red" onClick={() => { goDelete(); }}>삭제</button>
      </div>
      <div className="list_round_box">
        {is_show_list &&
          <ListSimpleArea
            xColumnArr={xColumnArr}
            infoArr={infoArr}
            setInfoArr={setInfoArr}
            select_row_num_arr={select_row_num_arr}
            set_select_row_num_arr={set_select_row_num_arr}
            is_grid={true}
            get_td_render_tag={get_td_render_tag}
          ></ListSimpleArea>
        }
      </div>
    </div>
  );
}

export default SubjectManagePopup;
