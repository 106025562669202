import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import LectureTimeTableArea from "./area/lecture_time_table";
import { useReactToPrint } from 'react-to-print';
import go_down_excel from "./area/excel/down_time_table";

function LectureListOneDay2() {
  let today_date_json = DateFunc.get_date_json(new Date());
  const [listOpt, setListOpt] = useState({
    "order_id": "a_order_num",
    "s_lecture_seq": "",
    "s_state": "ing",
    "s_teacher_like": "",
    "s_teacher_seq": "",
    "s_room_seq": "",
    "s_name_like": "",
    "s_day_num": [today_date_json["day"]],//today_date_json["day"]
    "s_addon_time": "1",
    "s_addon_user": "1",
    "s_addon_user_time": "1",
    "s_addon_user_s_state": "ing",
    "s_addon_user_s_attend":"1",
    "s_addon_time_union": "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [stu_arr,set_stu_arr]=useState([]);
  const [room_arr, set_room_arr] = useState([]);
  let default_is_show_th_stu_len:any=strFunc.get_storage("time_table_is_show_th_stu_len", "");
  default_is_show_th_stu_len=default_is_show_th_stu_len=="1"?true:false;
  const [time_table_display_opt, set_time_table_display_opt] = useState({
    is_teacher_nickname: true,
    is_show_th_stu_len:default_is_show_th_stu_len,
    stu_grade_sort:strFunc.get_storage("time_table_stu_grade_sort", "a_stu_grade2"),
    is_show_attend:strFunc.get_storage("time_table_is_show_attend", "1") == "1" ? true : false,
    is_show_one_lecture_stu:strFunc.get_storage("time_table_is_show_one_lecture_stu", "1") == "1" ? true : false,
    is_print_land:strFunc.get_storage("time_table_one_day_is_print_land", "1") == "1" ? true : false
  });

  const time_table_wrap_ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => time_table_wrap_ref.current,
  });

  useEffect(() => {
    list({});
    get_teacher_by_ajax();
    get_stu_by_ajax();
    get_room_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);

    tmp_list_opt["s_addon_user_s_attend"]="1";
    if(tmp_list_opt.s_day_num.length!=1){
      tmp_list_opt["s_addon_user_s_attend"]="";
    }else{
      if(tmp_list_opt.s_day_num[0]!=today_date_json["day"]){
        tmp_list_opt["s_addon_user_s_attend"]="";
      }
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_stu_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["student"],
      "s_stu_state":"now",
      "order_id": "a_user_name",
      "s_addon_lecture_time":"1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_room_by_ajax = () => {
    let list_form_json_data = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onClickPrint = () => {
    if (time_table_wrap_ref.current) {
      time_table_wrap_ref.current.style.transform = "scale(1)";
      handlePrint();
    }
  };

  const go_excel_down_action = () => {
    if (!confirm("엑셀로 받겠습니까?")) {
      return false;
    }
    go_down_excel({
      lecture_arr: infoArr,
      room_arr: room_arr,
      teacher_arr: teacher_arr,
      stu_arr:stu_arr,
      is_teacher_nickname: time_table_display_opt.is_teacher_nickname,
      is_show_th_stu_len:time_table_display_opt.is_show_th_stu_len,
      is_show_one_lecture_stu:time_table_display_opt.is_show_one_lecture_stu,
      stu_grade_sort: time_table_display_opt.stu_grade_sort,
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        teacher_arr={teacher_arr}
        room_arr={room_arr}
        time_table_display_opt={time_table_display_opt}
        set_time_table_display_opt={set_time_table_display_opt}
      ></SearchArea>
      <div className="my-1 text-center">
        <select value={time_table_display_opt.is_print_land ? "1" : "2"}
          onChange={(e: any) => {
            let change_is_print_land = false;
            if (e.target.value == "1") {
              change_is_print_land=true;
            }
            set_time_table_display_opt({
              ...time_table_display_opt,
              is_print_land: change_is_print_land
            });
            strFunc.set_storage("time_table_one_day_is_print_land", e.target.value);
          }}
          style={{ border: "1px solid #ddd" }}>
          <option value="1">가로</option>
          <option value="2">세로</option>
        </select>
        <button className="btn btn-sky ml-1" onClick={() => { onClickPrint(); }}>프린트</button>
        <button className="btn btn-sky ml-2" onClick={() => { go_excel_down_action(); }}>엑셀다운</button>
      </div>
      <div ref={time_table_wrap_ref} style={{ padding: 5, margin: 2 }}>
        <style type="text/css" media="print">
          {time_table_display_opt.is_print_land ? " @page { size: landscape; }@media print {html,body {width: 290mm;max-height:201mm; }} " : ""}
        </style>
        <LectureTimeTableArea
          lecture_arr={infoArr}
          teacher_arr={teacher_arr}
          stu_arr={stu_arr}
          room_arr={room_arr}
          is_teacher_nickname={time_table_display_opt.is_teacher_nickname}
          is_show_th_stu_len={time_table_display_opt.is_show_th_stu_len}
          stu_grade_sort={time_table_display_opt.stu_grade_sort}
          is_show_attend={time_table_display_opt.is_show_attend}
          is_show_one_lecture_stu={time_table_display_opt.is_show_one_lecture_stu}
        ></LectureTimeTableArea>
      </div>
    </CompLayout>
  );
}

export default LectureListOneDay2;