import SearchSubjectArea from "@/pages/comp/plan/study/study/list/area/search_area/search_subject";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    is_show_only_use: false,
    set_is_show_only_use: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value });
  };

  return (
    <div className="mt-1 text-center">
      <SearchSubjectArea
        listOpt={listOpt}
        setListOpt={myProps.setListOpt}
        list={myProps.list}
      ></SearchSubjectArea>
      {/* <span className="ml-2">
        <label>
          <input type="checkbox" checked={myProps.is_show_only_use} value=""
            onChange={(e: any) => {
              myProps.set_is_show_only_use(e.target.checked);
            }} />
          <span className="ml-1">사용중인것만 보기</span>
        </label>
      </span> */}
      <button className="btn btn-dark ml-2" onClick={() => { myProps.open_find_study_title_popup({}); }}>교재불러오기</button>
    </div>
  );
};
export default SearchArea;