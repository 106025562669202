import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import HomeWorkStateSelect from "./homework_state";

function TodoHomeWorkTd(props: any) {
  let myProps = {
    info: {},
    select_date: "",
    pre_date_str: "",
    openTodoDaySort: (inData: any) => { },
    sort: "study",//pre_homework

    daily_info: {},
    onChange: (inData: any) => { },
    select_homework_state_arr: [],
    ...props
  };
  let info = myProps.info;
  let select_date = myProps.select_date;
  let pre_date_str = myProps.pre_date_str;
  let todo_study_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
    info: info,
    date_sort: "now",//pre,now
    select_date: select_date,
    pre_date_str: pre_date_str,
    file_sort: "study"
  });
  let home_work_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
    info: info,
    date_sort: "pre",//pre,now
    select_date: select_date,
    pre_date_str: pre_date_str,
    file_sort: "homework"
  });

  let todo_per_average = 0;
  let per_average = 0;
  let todo_study_count = 0;
  let todo_homework_count = 0;

  if (info.td_sort_data_json && info.td_sort_data_json["study"]) {
    todo_per_average = info.td_sort_data_json["study"]["percent"];
    todo_study_count = info.td_sort_data_json["study"]["count"];
  }
  if (info.td_sort_data_json && info.td_sort_data_json["pre_homework"]) {
    per_average = info.td_sort_data_json["pre_homework"]["percent"];
    todo_homework_count = info.td_sort_data_json["pre_homework"]["count"];
  }
  let study_color_json = TodoStateFunc.get_color_of_percent(todo_per_average);
  let color_json = TodoStateFunc.get_color_of_percent(per_average);

  return (
    <td>
      {myProps.sort == "study" &&
        <div className="text-center" >
          {todo_study_count > 0 &&
            <span style={{ color: study_color_json.per, cursor: "pointer" }}
              onClick={() => {
                myProps.openTodoDaySort({
                  select_stu_name: info["a_user_name"],
                  select_stu_seq: info["a_seq"],
                  select_date: select_date
                });
              }}>
              <span style={{ fontSize: "12px" }} title={todo_study_count + ""}>
                {todo_per_average}%
              </span>
              {todo_study_file_cnt > 0 &&
                <span className="ml-1" style={{ fontSize: "12px" }}>
                  ({todo_study_file_cnt})
                </span>
              }
            </span>
          }
          {todo_study_file_cnt == 0 &&
            <span className="ml-1">
              <HomeWorkStateSelect
                daily_info={myProps.daily_info}
                daily_key_name="a_study_state"
                onChange={myProps.onChange}
                select_homework_state_arr={myProps.select_homework_state_arr}
                select_style={{ width: todo_study_count > 0 ? "16px" : "90%" }}
              ></HomeWorkStateSelect>
            </span>
          }
        </div>
      }
      {myProps.sort == "pre_homework" &&
        <div className="text-center" >
          {todo_homework_count > 0 &&
            <span style={{ color: color_json.per, cursor: "pointer" }}
              onClick={() => {
                myProps.openTodoDaySort({
                  select_stu_name: info["a_user_name"],
                  select_stu_seq: info["a_seq"],
                  select_date: pre_date_str
                });
              }} title={todo_homework_count + ""} >
              <span style={{ fontSize: "12px" }}>
                {per_average}%
              </span>
              {home_work_file_cnt > 0 &&
                <span className="ml-1" style={{ fontSize: "12px" }}>
                  ({home_work_file_cnt})
                </span>
              }
            </span>
          }
          {home_work_file_cnt == 0 &&
            <span className="ml-1">
              <HomeWorkStateSelect
                daily_info={myProps.daily_info}
                daily_key_name="a_homework_state"
                onChange={myProps.onChange}
                select_homework_state_arr={myProps.select_homework_state_arr}
                select_style={{ width: todo_homework_count > 0 ? "16px" : "90%" }}
              ></HomeWorkStateSelect>
            </span>
          }
        </div>
      }
    </td>
  );
};
export default TodoHomeWorkTd;