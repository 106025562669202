import { useState, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import noimg from "@/img/ico/noimg.png";
import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import SectionTreeArea from "./section_tree";
import MapStuArea from "./map_stu";
import strFunc from '@/lib/lyg/string';

const StudyTitleArea = forwardRef((props: any, ref) => {
  let myProps = {
    list: (inOptObj: any) => { },
    ...props
  };

  const init_data = {
    "a_seq": "",
    "a_subject_sort": "",
    "a_subject_seq": "",
    "a_title": "",
    "a_answer_link_url": "",
    "a_open_group": "",
    "a_is_progress": "",
    "a_is_mapping": "",
    "title_img_url": noimg,
    "title_img_arr": [],
    "section_arr": [],
    "study_arr": [],
    "map_arr": [],
    "map_det_arr": [],
  };
  const [rowData, setRowData] = useState<any>({
    ...init_data
  });
  let tab_select_arr = [
    { text: "섹션", value: "section" },
    { text: "학생", value: "map_stu" },
  ];
  const [tab_id, set_tab_id] = useState("section");//section,map_stu

  const setInitData = (inOptObj: any) => {
    let optObj = {
      "row_data": {},
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    get_study_title_info_by_ajax({
      "study_title_seq": optObj.row_data.a_seq,
    });
  };

  useImperativeHandle(ref, () => ({
    setInitData
  }));

  const get_study_title_info_by_ajax = (inData: any) => {
    let opt_obj = {
      "study_title_seq": rowData.a_seq,
      ...inData,
    };
    if (strFunc.is_empty(opt_obj.study_title_seq)) {
      setRowData({
        ...init_data
      });
      return false;
    }
    let from_json_data = {
      "s_pri_arr": [opt_obj.study_title_seq],
      "s_addon_title_img": "1",
      "s_addon_subject_title": "1",
      "s_addon_section": "1",
      "s_addon_study": "1",
      "s_addon_map": "1",
      "s_addon_map_det": "1",
      "s_addon_map_det_s_file": "1",

      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            setRowData({
              ...init_data,
              ...response.data["data"]["info_arr"][0]
            });
          } else {
            setRowData({
              ...init_data
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="text-center">
        <img src={rowData.title_img_url} style={{ height: "100px" }} />
        <div>
          {rowData.a_subject_sort &&
            <span style={{ marginRight: 5 }}>[{rowData.a_subject_sort}]</span>
          }
          {rowData.a_subject_title}
        </div>
        <div>{rowData.a_title}</div>
      </div>
      <div className="mt-1">
        <SelectTab
          select_arr={tab_select_arr}
          value={tab_id}
          on_select={(value: string) => {
            set_tab_id(value);
          }}
          is_same_value_empty={false}
        ></SelectTab>
      </div>
      <div className="mt-1">
        {tab_id == "section" &&
          <SectionTreeArea
            study_title_info={rowData}
            section_arr={rowData.section_arr}
            study_arr={rowData.study_arr}
            map_arr={rowData.map_arr}
            map_det_arr={rowData.map_det_arr}
            get_study_title_info_by_ajax={get_study_title_info_by_ajax}
          ></SectionTreeArea>
        }
        {tab_id == "map_stu" &&
          <MapStuArea
            study_title_info={rowData}
            map_arr={rowData.map_arr}
            map_det_arr={rowData.map_det_arr}
            get_study_title_info_by_ajax={get_study_title_info_by_ajax}
          ></MapStuArea>
        }
      </div>
    </div>
  );
});
export default StudyTitleArea;