import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

class MakeMonthPrescribeTodoFunc{
  static default_mprescribe={
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_is_reserve: "1",
    a_reserve_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_reserve_time: "09:00:00",
    a_is_write:"",
    a_write_date:DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_stu_seq:"",
    a_stu_name:"",
    a_writer_seq: "",
    a_writer_name: "",
    a_subject_name:"",
    a_is_success: "",
  }
  static make_month_prescribe(inData:any){
    let this_obj=this;
    let opt_obj={
      stu_info_arr:[],
      start_date:"",
      end_date:"",
      apply_day:"",
      one_day_amount:5,
      default_mprescribe:{},
      ...inData
    };
    let month_prescribe_data_arr:any=[];
    let row_default_mprescribe={
      ...this_obj.default_mprescribe,
      ...opt_obj.default_mprescribe
    };
    
    let start_date=opt_obj.start_date;
    if(strFunc.is_empty(start_date)){
      start_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let select_date_str=start_date;

    for(let i=0;i<opt_obj.stu_info_arr.length;i++){
      let stu_info=opt_obj.stu_info_arr[i];
      let row_month_prescribe=this_obj.get_month_prescribe_by_stu_info({
        ...opt_obj,
        stu_info:stu_info,
        row_default_mprescribe:row_default_mprescribe,
        select_date_str:select_date_str,
      });

      month_prescribe_data_arr.push(row_month_prescribe);
      //하루분량 넘으면 다음날짜로 이동
      if((i+1)%opt_obj.one_day_amount==0){
        select_date_str=this_obj.get_next_select_date_str({
          apply_day:opt_obj.apply_day,
          select_date_str:select_date_str,
        });
      }
    }

    return month_prescribe_data_arr;
  }

  static get_month_prescribe_by_stu_info=(inData:any)=>{
    let this_obj=this;
    let opt_obj={
      stu_info_arr:[],
      start_date:"",
      end_date:"",
      apply_day:"",
      one_day_amount:5,
      a_writer_seq:"",
      a_writer_name:"",
      default_mprescribe:{},
      stu_info:{},
      row_default_mprescribe:{},
      select_date_str:"",
      ...inData
    };
    let stu_info=opt_obj.stu_info;
    let row_month_prescribe={
      ...opt_obj.row_default_mprescribe
    };
    let select_date_str=opt_obj.select_date_str;

    row_month_prescribe["a_ymd"]=strFunc.str_replace("-","",strFunc.uncomma(select_date_str));
    row_month_prescribe["a_reserve_date"]=select_date_str;
    row_month_prescribe["a_write_date"]=select_date_str;
    row_month_prescribe["a_stu_seq"]=stu_info["a_seq"];
    row_month_prescribe["a_stu_name"]=stu_info["a_user_name"];

    return row_month_prescribe;
  };

  static get_next_select_date_str=(inData:any)=>{
    let this_obj=this;
    let opt_obj={
      apply_day:"",
      select_date_str:"",
      ...inData
    };

    let select_date_str=opt_obj.select_date_str;
    let apply_day_arr=opt_obj.apply_day.split(",");
    let is_able_day_opt={
      "date_str":select_date_str,
      "apply_day_arr":apply_day_arr,
    };
    let max_loop_cnt=30;
    let loop_cnt=0;
    do{
      let change_select_date_obj=DateFunc.get_change_date(new Date(select_date_str),'day',+1);
      select_date_str=DateFunc.get_date_format(new Date(change_select_date_obj),"Y-m-d");
      loop_cnt++;
      is_able_day_opt={
        "date_str":select_date_str,
        "apply_day_arr":apply_day_arr,
      };
    }while((this_obj.is_able_day_of_week(is_able_day_opt)==false&&loop_cnt<max_loop_cnt));

    return select_date_str;
  }

  static is_able_day_of_week(inData:any){
    let opt_obj={
      "date_str":"",
      "apply_day_arr":[],
      ...inData
    };
    let next_day_json=DateFunc.get_date_json(new Date(opt_obj["date_str"]));
    if(strFunc.str_in_array(next_day_json.day,opt_obj["apply_day_arr"])!=-1){
      return true;
    }
    return false;
  }
}
export default MakeMonthPrescribeTodoFunc;