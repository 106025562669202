import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"en_class",
    list_id:"en_class_list_move_page",
    x_column_list_arr:[
      {"key":"a_name","name":"클래스명","width":"210","is_show":"1"},
      // {"key":"row_view_main","name":"메인","width":"70","is_show":"1"},
      {"key":"row_view_coolen","name":"페어링","width":"80","is_show":"1"},
      {"key":"row_view_coolenb","name":"뱅크","width":"70","is_show":"1"},
      {"key":"row_view_order","name":"순번","width":"80","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'a_order_num',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_name_like":"",
      "s_stu_name_like":"",
      "s_stu_phone_like":"",
      "s_stu_school_name_like":"",
      "s_stu_stu_grade":"",
      "s_teacher_like":"",
      "s_is_supply_money":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;