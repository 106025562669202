import SearchSubjectArea from "@/pages/comp/plan/study/study/list/area/search_area/search_subject";

function SearchArea(props:any){
  let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    setListOpt:(inData:any)=>{},
    ...props
  };
  let listOpt=myProps.listOpt;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  return (
    <div>
      <div className="search-box relative">
        <SearchSubjectArea
          listOpt={listOpt}
          setListOpt={myProps.setListOpt}
          list={myProps.list}
        ></SearchSubjectArea>
        <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      </div>
    </div>
  );
};

export default SearchArea;