function HomeWorkStateSelect(props: any) {
  let myProps = {
    daily_info:{},
    onChange: (inData: any) => { },
    select_homework_state_arr:[],
    daily_key_name:"a_homework_state",//a_study_state
    select_style:{},
    ...props
  };
  let select_homework_state_arr=myProps.select_homework_state_arr;
  let daily_key_name=myProps.daily_key_name;

  let select_tag_of_num_style:any={
    width: "16px", textAlign: "center",
    fontWeight: "bold", fontSize: "14px",
    ...myProps.select_style
  };
  if(myProps.daily_info[daily_key_name]=="1"){
    select_tag_of_num_style["color"]="#fff";
    select_tag_of_num_style["background"]="#a2b854";
  }else if(myProps.daily_info[daily_key_name]=="2"){
    select_tag_of_num_style["color"]="#fff";
    select_tag_of_num_style["background"]="#db6c58";
  }else if(myProps.daily_info[daily_key_name]=="3"){
    select_tag_of_num_style["color"]="#fff";
    select_tag_of_num_style["background"]="#eab358";
  }

  let select_input_render = (
    <select
      className="select_none_arrow"
      value={myProps.daily_info[daily_key_name]}
      onChange={(e: any) => {
        let change_row_data: any = {};

        myProps.onChange({
          name: daily_key_name,
          value: e.target.value,
          change_row_data: change_row_data
        });
      }}
      style={select_tag_of_num_style} >
      <option value=""></option>
      {select_homework_state_arr &&
        select_homework_state_arr.map((item: any, idx: number) => {
          return (<option key={idx} value={item.value}>{item.text}</option>)
        })
      }
    </select>
  );

  return (
    <>
      {select_input_render}
    </>
  );
};
export default HomeWorkStateSelect;