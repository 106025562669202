import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import ClassUserManageArea from "@/pages/comp/plan/coolen/common/class_user_manage";

function ClassUserManagePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [is_show_manage, set_is_show_manage] = useState(false);

  useEffect(() => {
    user_my_info_by_ajax();
  }, [user]);

  const user_my_info_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_pri_arr": [user.user_seq],//[]
      "s_addon_data": "1",
      "is_need_count": "1",
      "is_need_info_arr": "1",
      "is_no_limit": "",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let my_info = response.data["data"]["info_arr"][0];
            if (my_info.addon_data_info) {
              if (!strFunc.is_empty(my_info.addon_data_info["a_coolen_user_seq"])
                && !strFunc.is_empty(my_info.addon_data_info["a_coolenb_user_seq"])) {
                set_is_show_manage(true);
              }
            }
          }
        } else {

        }
      });
  };

  return (
    <div>
      <div className="mt-5">
        {is_show_manage ?
          <ClassUserManageArea></ClassUserManageArea>
          :
          <div className="mt-2 text-center" style={{ color: "#555" }}>
            ※ 페어링잉글리쉬, 문제은행 <span style={{ color: "blue" }}>최초 로그인</span> 필요.
          </div>
        }
      </div>
    </div>
  );
};
export default ClassUserManagePopup;